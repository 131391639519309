import {JetView} from "webix-jet";
import Box from "jet-views/obt/Box";

export default class Obt4 extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar", elements: [
						{view: "button", label: "test"}
					]
				},
				{
					template: "<div id='obtContainer' style='width: 100%; height: 100%' ></div>",
					// template: "<svg id=\"obtSVG\" width='100%' height='100%' viewBox=\"0 0 800 600\" style='background: #f4f5f9;'>" +
					// 	// "  <rect x=\"0\" y=\"0\" width=\"800\" height=\"600\" fill=\"none\" pointer-events=\"all\" />" +
					// 	"  <circle class=\"draggable\" id=\"mycirc\" cx=\"60\" cy=\"60\" r=\"22\" fill=\"blue\" />" +
					// 	"  <g transform=\"rotate(45,175,75)\">" +
					// 	"    <rect class=\"draggable\" id=\"mycirc\" x=\"160\" y=\"60\" width=\"30\" height=\"30\" fill=\"green\" />" +
					// 	"  </g>" +
					// 	"  <g class='draggable' transform=\"translate(200 200) scale(2 2)\">" +
					// 	// "    <g class='draggable'>" +
					// 	// "      <circle cx=\"0\" cy=\"0\" r=\"30\" fill=\"yellow\"/>" +
					// 	"	<rect x=\"0\" y=\"0\" width=\"50\" height=\"50\" fill='brown' />" +
					// 	"	<text text-anchor='middle' dominant-baseline='middle' x='0' y='0' fill=\"white\">" +
					// 	"		<tspan x='25' dy='12' >A: 10</tspan>" +
					// 	"		<tspan x='25' dy='12'>B: 2</tspan>" +
					// 	"		<tspan x='25' dy='12'>C: 8</tspan>" +
					// 	"		<tspan x='25' dy='12'>D: 3</tspan>" +
					// 	"	</text>" +
					// 	// "    </g>" +
					// 	"  </g>" +
					// 	"</svg>"
				}
			]
		};
	}

	ready() {

		let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		// svg.addEventListener("click", evt => this.svgClick(evt));
		const obtContainer = document.getElementById("obtContainer");
		let viewBoxParams = {x:0, y:0, width: 0, height:0};
		viewBoxParams.width = obtContainer.clientWidth;
		viewBoxParams.height = obtContainer.clientHeight;
		svg.setAttribute("viewBox", Object.values(viewBoxParams));
		obtContainer.appendChild(svg);

		for(let i=0; i<5; i++){
			let box = new Box({id: "box-" + webix.uid(), x: 60 * i});
			box.classList.add("draggable");
			box.addEventListener("click", evt => this.svgClick(evt));
			svg.appendChild(box);
		}

		var dre = document.querySelectorAll(".draggable");
		for (var i = 0; i < dre.length; i++) {
			var o = new this.Draggable(dre[i]);
			// dre[i].addEventListener("click", evt => this.svgClick(evt));
		}

		// var obtSVG = document.getElementById("obtSVG");
		// let p; //punto inicial
		// let d; // distancia inicial entre los dos puntos
		// let viewBoxParams;
		// let viewBoxParams2;
		//
		// obtSVG.addEventListener("touchstart", evt => {
		// 	console.log("SVG raíz", evt.type);
		// 	evt.preventDefault();
		// 	evt.stopPropagation();
		// 	if (evt.touches.length === 2) {
		// 		let p1 = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
		// 		let p2 = {x: evt.touches[1].clientX, y: evt.touches[1].clientY};
		// 		d = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
		// 	} else {
		// 		p = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
		// 		viewBoxParams = obtSVG.getAttribute("viewBox").split(" ");
		// 		viewBoxParams2 = viewBoxParams.slice();
		// 	}
		// });

		//TODO: mover viewBox con mouse
		//TODO: Zoom viewBox con rueda del mouse

		// obtSVG.addEventListener("touchmove", evt => {
		// 	// TODO: verificar si el target tiene la clase .draggable
		// 	// Para poder mover el viewBox aunque se esté sobre un elemento no movible
		// 	console.log(evt.target.classList);
		// 	if (evt.target !== obtSVG) return;
		// 	// console.log("SVG raíz", evt.type, evt);
		// 	evt.preventDefault();
		// 	evt.stopPropagation();
		// 	if (evt.touches.length === 2) {
		// 		let p1 = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
		// 		let p2 = {x: evt.touches[1].clientX, y: evt.touches[1].clientY};
		// 		let d2 = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
		// 		viewBoxParams2[2] = parseInt(viewBoxParams[2]) - d2 + d;
		// 		viewBoxParams2[3] = parseInt(viewBoxParams[3]) - d2 + d;
		// 	} else {
		// 		let p2 = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
		// 		viewBoxParams2[0] = parseInt(viewBoxParams[0]) + p.x - p2.x;
		// 		viewBoxParams2[1] = parseInt(viewBoxParams[1]) + p.y - p2.y;
		// 	}
		// 	obtSVG.setAttribute("viewBox", viewBoxParams2.join(" "));
		// });
	}

	Draggable(elem) {
		this.target = elem;
		this.clickPoint = this.target.ownerSVGElement.createSVGPoint();
		this.lastMove = this.target.ownerSVGElement.createSVGPoint();
		this.currentMove = this.target.ownerSVGElement.createSVGPoint();
		this.target.addEventListener("mousedown", this);
		this.target.addEventListener("touchstart", this);
		this.target.addEventListener("click", this);
		this.handleEvent = function (evt) {
			console.log("SVG child", evt.type);
			// evt.stopPropagation();
			evt.preventDefault();
			evt = evt.type === "touchstart" ? evt.touches[0] : evt;
			this.clickPoint = globalToLocalCoords(evt.clientX, evt.clientY);
			this.target.classList.add("dragged");
			this.target.setAttribute("pointer-events", "none");
			this.target.ownerSVGElement.addEventListener("click", this.clicked);
			this.target.ownerSVGElement.addEventListener("mousemove", this.move);
			this.target.ownerSVGElement.addEventListener("touchmove", this.move);
			this.target.ownerSVGElement.addEventListener("mouseup", this.endMove);
			this.target.ownerSVGElement.addEventListener("touchend", this.endMove);
		};

		this.clicked = function(evt){
			console.log("click inside Draggle", evt);
		};

		this.move = function (evt) {
			// console.log("SVG child", evt.type, evt);
			// evt.stopPropagation();
			// console.log(evt); //mousemove touchmove
			evt = evt.type === "touchmove" ? evt.touches[0] : evt;
			var p = globalToLocalCoords(evt.clientX, evt.clientY);
			// console.log(p);
			this.currentMove.x = this.lastMove.x + (p.x - this.clickPoint.x);
			this.currentMove.y = this.lastMove.y + (p.y - this.clickPoint.y);
			//Snap
			this.currentMove.x = Math.trunc(this.currentMove.x / 20) * 20;
			this.currentMove.y = Math.trunc(this.currentMove.y / 20) * 20;
			this.target.setAttribute("transform", "translate(" + this.currentMove.x + "," + this.currentMove.y + ")");
		}.bind(this);

		this.endMove = function (evt) {
			this.lastMove.x = this.currentMove.x;
			this.lastMove.y = this.currentMove.y;
			this.target.classList.remove("dragged");
			this.target.setAttribute("pointer-events", "all");
			this.target.ownerSVGElement.removeEventListener("click", this.clicked);
			this.target.ownerSVGElement.removeEventListener("mousemove", this.move);
			this.target.ownerSVGElement.removeEventListener("touchmove", this.move);
			this.target.ownerSVGElement.removeEventListener("mouseup", this.endMove);
			this.target.ownerSVGElement.removeEventListener("touchend", this.endMove);
		}.bind(this);

		function globalToLocalCoords(x, y) {
			var p = elem.ownerSVGElement.createSVGPoint();
			var m = elem.parentNode.getScreenCTM();
			p.x = x;
			p.y = y;
			return p.matrixTransform(m.inverse());
		}
	}

	svgClick(evt) {
		console.log(evt.target, evt.currentTarget);
	}
}
