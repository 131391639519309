import {JetView} from "webix-jet";

export default class Personal extends JetView {

	config() {
		return {
			cols: [
				{
					type: "clean",
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Personal"},
								{},
								{
									view: "button", type: "icon", icon: "wxi-plus", width: 37, css: "app_button",
									click: () => this.agregarClick()
								}
							]
						},
						{view: "text", localId: "filterTxt"},
						{
							view: "list",
							id: "perLst",
							width: 300,
							template: "#nom#, #ape#<br>#gru#",
							type: {height: 60},
							select: true,
							url: "server/personal/list.php",
							save: {
								url: "server/personal/save.php?XDEBUG_SESSION_START=12345",
								updateFromResponse: true
							},
							on: {
								onSelectChange: id => this.perLstSelectChange(id)
							}
						},
					]
				},
				{view: "resizer"},
				{
					rows: [
						{
							view: "toolbar", elements: [
								{
									view: "button",
									type: "icon",
									icon: "mdi mdi-arrow-left",
									width: 40,
									// click: () => this.cancelarClick()
								},
								{},
								{
									view: "button",
									label: "Guardar",
									type: "iconButton",
									icon: "save",
									click: () => this.guardarClick()
								},
								// {view: "button", label: "Borrar", type: "iconButton", icon: "minus-circle"}
							]
						},
						{
							view: "form",
							id: "perFrm",
							elementsConfig: {
								labelPosition: "top"
							},
							elements: [
								// {view:"text", name:"id", hidden: true},
								{
									id: "perPhoto",
									template: "<img id='perPhotoImg' style='width: 120px; height: 120px; border: 1px lightgray solid'>" +
										"<input type='file' id='perPhotoInput' accept='image/*' capture " +
										"onchange='$$(\"perPhoto\").$scope.handleFiles(this.files)' style='display:none;' />",
									height: 130,
									type: "clean"
								},
								// {
								// 	template: "<div id='imgselect_container'>\n" +
								// 	"            <div class='btn btn-primary imgs-upload'>Upload</div> <!-- .imgs-upload -->\n" +
								// 	"            <button type='button' class='btn btn-success imgs-webcam'>Webcam</button> <!-- .imgs-webcam -->\n" +
								// 	"            <div class='imgs-webcam-container'></div> <!-- .imgs-webcam-container -->\n" +
								// 	"            <div class='imgs-crop-container'></div> <!-- .imgs-crop-container -->\n" +
								// 	"            <div>\n" +
								// 	"                <button type='button' class='btn btn-primary imgs-save'>Save Image</button> <!-- .imgs-save -->\n" +
								// 	"                <button type='button' class='btn btn-primary imgs-newsnap'>New Snapshot</button> <!-- .imgs-newsnap -->\n" +
								// 	"                <button type='button' class='btn btn-primary imgs-capture'>Capture</button> <!-- .imgs-capture -->\n" +
								// 	"                <button type='button' class='btn btn-default imgs-cancel'>Cancel</button> <!-- .imgs-cancel -->\n" +
								// 	"            </div>\n" +
								// 	"            <div class='imgs-alert alert'></div> <!-- .imgs-alert -->\n" +
								// 	"        </div>"
								// },
								{
									cols: [{
										view: "button", type: "iconButton", icon: "camera", width: 120, click() {
											document.getElementById("perPhotoInput").click();
										}
									}, {}]
								},
								{template: "Datos Personales", type: "section"},
								{
									cols: [
										{view: "text", id: "nom", name: "nom", label: "Nombres"},
										{view: "text", id: "ape", name: "ape", label: "Apellidos"}
									]
								},
								{
									cols: [
										{view: "text", id: "tel", name: "tel", label: "Teléfono fijo"},
										{view: "text", id: "cel", name: "cel", label: "Teléfono móvil"}
									]
								},
								{view: "text", id: "dir", name: "dir", label: "Dirección"},
								{template: "Datos Usuario", type: "section"},
								{
									cols: [
										{view: "text", id: "usu", name: "usu", label: "Usuario"},
										{view: "text", id: "eml", name: "eml", label: "Email"}
									]
								},
								{
									view: "multicombo",
									label: "Sucursales",
									name: "suc_id",
									suggest: {
										url: "server/sucursales/options.php"
									}
								},
								{
									cols: [
										// {view:"combo", id:"suc_id", name:"suc_id", label:"Sucursal",
										// 	suggest:{template:"#suc#", body:{ template:"#suc#" }, url:"server/sucursales/list.php"}
										// },
										{
											view: "combo", id: "gru_id", name: "gru_id", label: "Grupo",
											suggest: {
												template: "#gru#",
												body: {template: "#gru#"},
												url: "server/grupos/list.php"
											}
										}
									]
								},
								{
									cols: [
										{
											view: "text",
											id: "con",
											name: "pas",
											label: "Contraseña",
											type: "password",
											bottomLabel: "* Mínimo 6 caracteres"
										},
										{
											view: "text",
											id: "con2",
											name: "pas2",
											label: "Confirmar Contraseña",
											type: "password",
											bottomLabel: ""
										}
									]
								},
								{}
							],
							rules: {
								nom: webix.rules.isNotEmpty,
								gru_id: webix.rules.isNotEmpty,
								suc_id: webix.rules.isNotEmpty,
								$obj(data) {
									if (data.pas !== "" && data.usu !== "") {
										if (data.pas.length < 6) {
											webix.alert("La contraseña debe contener 6 caracteres o más", "alert-error");
											return false;
										}
										if (data.pas !== data.pas2) {
											webix.alert("Las contraseñas no coinciden", "alert-error");
											return false;
										}
									}
									return true;
								}
							}
						}
					]
				}
			]
		};
	}

	init(view) {
		this.perLst = view.queryView({id: "perLst"});
		this.perFrm = view.queryView({id: "perFrm"});
		this.perFrm.bind(this.perLst);
		this.filterTxt = view.queryView({localId: "filterTxt"});
		webix.dp(view.queryView({id: "perLst"})).attachEvent("onBeforeUpdate", this.perLstDpBeforeUpdate);
		webix.dp(view.queryView({id: "perLst"})).attachEvent("onAfterSync", this.perLstDpAfterSync);
		webix.dp(view.queryView({id: "perLst"})).attachEvent("onAfterSaveError", this.perLstDpAfterSaveError);
		this.filterTxt.attachEvent("onTimedKeyPress", () => {
			const txt = this.filterTxt.getValue().toLowerCase();
			// this.perLst.filter("#nom#", txt);
			this.perLst.filter(obj => {
				obj.nom = obj.nom || "";
				obj.ape = obj.ape || "";
				return obj.nom.toLowerCase().indexOf(txt) !== -1 || obj.ape.toLowerCase().indexOf(txt) !== -1;
			}, null);
		});
		// document.getElementById("perPhotoInput").addEventListener("change", function(e){console.log(e);} , false);
	}

	perLstDpAfterSync(statusObj, text, data, loader) {
		console.log("perLstDpAfterSync", data);
		const r = data.json();
		if (r.status === "error") {
			webix.alert({type: "alert-error", text: r.msg});
			return;
		}
	}

	perLstDpAfterSaveError(id, status, response, details) {
		webix.alert({type: "alert-error", text: response.msg});
	}

	perLstDpBeforeUpdate(id, action) {
		delete action.data.suc;
		delete action.data.gru;
		// delete action.data.pas2;
		// delete action.data.newid;
		// delete action.data.status;
	}

	perLstSelectChange(ids) {
		console.log("id:", ids[0]);
		// this.perFrm.setValues(this.perLst.getItem(ids[0]));
		this.setParam("per_id", ids[0], true);
	}

	agregarClick() {
		this.perLst.unselectAll();
	}

	guardarClick() {
		//if (this.perFrm.isDirty()) {
		if (!this.perFrm.validate()) {
			return false;
		}
		// Es necesario convertirlo a un objeto any porque el método save sólo está
		// definido en el form cuando se ha hecho un binding
		// Revisar posibilidad de usar Mixins:
		// https://github.com/Microsoft/TypeScript/wiki/What's-new-in-TypeScript#typescript-22
		(this.perFrm).save();

		// const data = this.perFrm.getValues();
		// webix.ajax().post("server/personal/save.php", data).then(
		// 	res => {
		// 		const r = res.json();
		// 		if (r.status === "error") {
		// 			webix.alert({type: "alert-error", text: r.msg});
		// 			return;
		// 		}
		//
		// 		//this.setParam("per_id", r.data.id);
		// 		//this.perFrm.setValues(r.data);
		// 		const id = this.perLst.getSelectedId(true)[0] as number;
		//
		// 		webix.dp(this.perLst).ignore(
		// 			() => {
		// 				this.perLst.updateItem(id, r.data);
		// 			}
		// 		);
		//
		// 	}
		// );

		// this.perLst.moveTop(this.perLst.getLastId());
		//}
	}

	handleFiles(files) { // Error:(209, 3) TS2322: Type 'void' is not assignable to type '(this: HTMLElement, ev: Event) => any'.
		console.log(files[0]);
		const img = document.getElementById("perPhotoImg");
		img.src = window.URL.createObjectURL(files[0]);
		img.height = 60;
		img.onload = function () {
			window.URL.revokeObjectURL((this).src);
		};
	}
}