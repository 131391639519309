import {JetView} from "webix-jet";
// import Permisos from "views/permisos";

export default class Dispositivos extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Dispositivos"},
						{},
						{
							view: "button", type: "icon", icon: "wxi-plus", width: 37, css: "app_button",
							click() {
								const dt = this.$scope.$$("dispositivos_dt");
								dt.editRow(dt.add({des: "Referencia"}));
							}
						}
					]
				},
				{
					view: "datatable",
					id: "dispositivos_dt",
					columns: [
						{id: "id", header: "ID", width: 50},
						{
							id: "suc_id", header: "Sucursal", width: 200,
							editor: "combo",
							options: "server/sucursales/options.php"
						},
						{id: "des", header: "Descripción", fillspace: true, editor: "text"},
						{id: "num", header: "Número", editor: "text", width: 100},
						{id: "rid", header: "Tracking ID", editor: "text", width: 100}
					],
					url: "server/dispositivos/list.php",
					save: "server/dispositivos/save.php",
					editable: true,
					// editaction: "dblclick",
					updateFromResponse: true
				}
			]
		};
	}
}