import localbase from "localbase/localbase/localbase";

let db = new localbase("tdsx");

export function saveEscaneo(data) {
	db.collection("escaneos")
		.add(data)
		.then(response => {
			console.log(response);
			return sync();
		})
		.catch(error => console.log(error));
}

function sync() {
	db.collection("escaneos").get()
		.then(result => {
			const estados = JSON.stringify(result);
			webix.ajax().post("server/items/estados/save.php", {estados: estados})
				.then(response => {
					const errores = response.json();
					//console.log(errores);
					db.collection("escaneos").delete();
					for (const error of errores) {
						console.log("ERROR:" + error);
					}
					//$$("itemsLst").updateItem("10564559200", {err: "YEA!!!!"});
				})
				.catch(response => {
					console.log(response);
					webix.message({
						type: "error",
						text: response.responseText
					});
				});
			return "HEY!!!";
		});
}