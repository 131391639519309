export default {
	"Age":"나이",
	"Total money spent, $":"지출 된 총 돈, $",
	"Clients":"고객",
	"Income":"수입",
	"First name":"이름",
	"Last name":"성",
	"Position":"근무처",
	"Click to select":"클릭하여 선택하십시오.",
	"Notifications":"알림",
	"Yes":"예",
	"No":"아니",
	"City, country":"도시, 국가",
	"Address":"주소",
	"Birthday":"생일",
	"Click to add tags":"태그를 추가 하시려면 클릭하십시오.",
	"Notes":"노트",
	"Reset":"다시 놓기",
	"Save":"구하다",
	"Top clients":"최고의 고객",
	"Money spent, $":"지출 된 돈, $",
	"-- Not selected --":"-- 선택되지 않은 --",
	"Sales manager":"영업 관리자",
	"Customer service":"고객 서비스",
	"General manager":"일반 관리자",
	"Awarded Badges":"배지 부여",
	"New":"새로운",
	"Customer":"고객",
	"Supplier":"공급자",
	"Discount":"할인",
	"Old Buddy":"올드 버디",
	"Avid Supporter":"욕심 많은 서포터",
	"Date":"날짜",
	"Payment":"지불",
	"Purchase":"매수",
	"Sum":"합집합",
	"Left":"왼쪽",
	"Nothing on this day":"이 날에는 아무것도 없다.",
	"Information":"정보",
	"Statistics":"통계",
	"Transactions":"업무",
	"Customers":"고객",
	"Charts":"차트",
	"See all notifications":"모든 알림보기",
	"Deposits News":"예금 뉴스",
	"Dear client, we inform you about the following changes: beginning from September 24, 2018 all transactions (incoming and out...":"고객님, 안녕하세요. 다음과 같은 변경 사항에 대해 알려드립니다. 2018 년 9 월 24 일부터 모든 거래 (수신 및 발신...",
	"Search Improved":"검색 향상",
	"Dear client! Following the latest updates of the SeekMeEverywhere engines, your search has become more reliable and convenient! No...":"고객님 께! SeekMeEverywhere 엔진의 최신 업데이트에 이어 검색이 더욱 안정적이고 편리하게되었습니다! 지금...",
	"Transaction Reports":"거래 보고서",
	"Dear client, we inform you about a change in the date of monthly transaction reports. Beginning from September 20, 2018 reports...":"친애하는 고객 여러분, 월별 거래 보고서의 날짜가 변경되었음을 알려드립니다. 2018 년 9 월 20 일부터보고...",
	"Warning":"경고",
	"Dear client, we know where you store your passwords. We highly recommend you to memorize them and destroy those memos of yours.":"고객 여러분, 비밀번호 저장 위치를 ​​알고 있습니다. 우리는 당신을 암기하고 당신의 그 메모를 파괴하는 것이 좋습니다.",
	"Editing customers data":"고객 데이터 편집",
	"Dear client, if you want to see the details on every customer and edit them, double-click the customer in the list on the right.":"고객 여러분, 모든 고객의 세부 정보를보고 편집하려면 오른쪽 목록에서 해당 고객을 두 번 클릭하십시오.",
	"We miss you":"우리는 당신이 그리워.",
	"Dear client, it has been a while since you talked to us. We are waiting for you in the same place at the same time.":"친애하는 고객 여러분, 저희와 얘기 한 지 오래되었습니다. 우리는 같은 장소에서 동시에 당신을 기다리고 있습니다.",
	"Have a nice day":"좋은 하루 되세요",
	"Dear client, whenever you are reading this, we wish you a merry day. May the luck and success attend you.":"친애하는 고객, 당신이 이것을 읽고있을 때마다, 우리는 당신에게 즐거운 하루 되시기를 바랍니다. 행운과 성공이 당신을 기다리고 있습니다.",
	"Localization":"현지화",
	"Dear client, you can pick one of the six languages we provided for this demo app. Click the icon in the top right corner.":"고객 여러분,이 데모 앱에 제공 한 6 가지 언어 중 하나를 선택하실 수 있습니다. 오른쪽 상단의 아이콘을 클릭하십시오.",
	"We love you":"우리는 너를 사랑해",
	"Dear client, we love you very much. If our feelings are mutual, contact us and download Webix. Then we will love you eternally.":"친애하는 고객, 우리는 당신을 아주 사랑합니다. 우리의 감정이 상호 적이라면 저희에게 연락하여 Webix를 다운로드하십시오. 그러면 우리는 영원히 당신을 사랑할 것입니다.",
	"Persons":"명",
	"Click twice to see more goodies":"더 많은 케이크를 보려면 두 번 클릭하십시오.",
	"Saved":"저장 됨",
	"Settings":"설정",
	"Regional settings":"지역 설정",
	"Language":"언어",
	"Date format":"날짜 형식",
	"Money format":"돈 형식",
	"Environment settings":"환경 설정",
	"Theme":"테마",
	"Light":"빛",
	"Dark":"어두운",
	"Max list length":"최대 목록 길이",
	"Default settings":"기본 설정",
	"Open latest notifications":"최신 알림 열기",
	"Go to settings":"설정으로 바로 가기",
	"All":"모든",
	"Payments":"지불",
	"Incoming":"들어오는",
	"Email":"이메일",
	"Widgets":"위젯",
	"Demos":"시민",
	"Prices":"물가",
	"Tutorials":"자습서",
	"Tirane, Albania":"티라나, 알바니아",
	"Buenos Aires, Argentina":"아르헨티나 부에노스 아이레스",
	"Canberra, Australia":"오스트레일리아 캔버라",
	"Vienna, Austria":"오스트리아의 비엔나",
	"Minsk, Belarus":"벨로루시 민스크",
	"Brussels, Belgium":"벨기에의 브뤼셀",
	"Brasilia, Brazil":"브라질리아, 브라질리아",
	"Ottawa, Canada":"캐나다 오타와",
	"Beijing, China":"베이징, 중국",
	"Prague, Czech Republic":"프라하, 체코",
	"Copenhagen, Denmark":"코펜하겐, 덴마크",
	"Cairo, Egypt":"카이로, 이집트",
	"Helsinki, Finland":"핀란드 헬싱키",
	"Paris, France":"파리, 프랑스",
	"Tbilisi, Georgia":"트빌리시, 조지아",
	"Berlin, Germany":"베를린, 독일",
	"New Delhi, India":"인도, 뉴 델리",
	"Dublin, Ireland":"더블린, 아일랜드",
	"Rome, Italy":"이탈리아 로마",
	"Tokyo, Japan":"도쿄, 일본",
	"Wellington, New Zealand":"웰링턴, 뉴질랜드",
	"Seoul, Republic of Korea":"서울",
	"Madrid, Spain":"마드리드, 스페인",
	"Stockholm, Sweden":"스톡홀름, 스웨덴",
	"Washington, United States":"워싱턴, 미국",
	"Payment History":"지불 내역",
	"Origin of clients":"고객의 유래",
	"Income from various age groups":"다양한 연령대의 소득",
	"Clients by occupation":"직업 별 고객",
	"No new notifications":"새로운 알림 없음"
};