import "./../../styles/app.css";
import {JetView} from "webix-jet";

export default class EnRuta extends JetView {
	config() {

		// webix.protoUI({
		// 	name: "activeList"
		// }, webix.ui.list, webix.ActiveContent);

		return {
			view: "multiview",
			cells: [
				{
					localId: "listadoView",
					style: "space",
					rows: [
						{
							view: "toolbar",
							elements: [
								{width: 4},
								{view: "label", label: "Reparto", localId: "label"},
								{width: 4},
								{
									view: "text", localId: "search", hidden: true,
									on: {
										onTimedKeyPress: () => this.filterList()
									}
								},
								{
									view: "icon", icon: "mdi mdi-magnify",
									state: "closed", localId: "search_icon",
									tooltip: "Buscar Pedido (Representante, Nº Pedido)",
									click: function () {
										if (this.config.state === "closed") {
											this.$scope.$$("label").hide();
											this.$scope.$$("search").show();
											this.$scope.$$("search").focus();
											this.$scope.filterList();
											this.config.state = "open";
										} else if (this.config.state === "open") {
											this.$scope.$$("repartoDv").filter(null, null);
											this.$scope.$$("label").show();
											this.$scope.$$("search").hide();
											this.config.state = "closed";
										}
									}
								},
								{
									view: "icon", icon: "mdi mdi-map",
									tooltip: "Ver Mapa del Reparto",
									click: () => this.getParentView().show("reparto.map")
								}
							]
						},
						{
							view: "segmented", localId: "estadoFilterBtn",
							options: [
								{id: -1, value: "Todos"},
								{id: 7, value: "Con Novedad"},
								{id: 8, value: "Entregado"}
							],
							on: {
								onItemClick: (btn) => this.segmentedClick(btn)
							}
						},
						{
							view: "datatable",
							localId: "repartoDv",
							rowHeight: 100,
							// autoheight: true,
							rowLineHeight: 14,
							header: false,
							columns: [
								{
									header: "Reparto",
									fillspace: true,
									template: function (ped) {
										ped.c_ar = 0;
										ped.c_d = 0;
										ped.c_e = 0;
										ped.c_n = 0;

										ped.items.forEach(itm => {
											if (itm.est_id === 5) {
												ped.c_ar++;
											} else if (itm.est_id === 6) {
												ped.c_d++;
											} else if (itm.est_id === 7) {
												ped.c_n++;
											} else if (itm.est_id === 8) {
												ped.c_e++;
											}
										});

										return `<a class="call_btn" href="tel:+57 ${ped.cel}"><span class='mdi mdi-phone mdi-36px'></span></a>
											<span class='title'>
											<strong>${ped.rep}</strong><br>
											${ped.tel} ${ped.cel}<br>
											${ped.dir} - ${ped.bar}<br>
											${ped.ciu} - ${ped.zon}<br>
											${ped.id} CAJAS:${ped.caj} FRÍOS:${ped.ff}<br>
											<span class="est-ar"><span class="webix_icon mdi mdi-alpha-a-box"></span>${ped.c_ar} <span class="webix_icon mdi mdi-alpha-d-box est-d"></span>${ped.c_d} <span class="est-n"><span class="webix_icon mdi mdi-alpha-n-box"></span>${ped.c_n}</span> </span><span class="est-e"><span class="webix_icon mdi mdi-alpha-e-box"></span>${ped.c_e}</span>
											</div>`;
										// "<span class='webix_icon mdi mdi-phone'></span>#tel# <span class='webix_icon mdi mdi-cellphone-basic'></span>#cel#<br>" +
										// + "<input class='buttons novbtn' type='button' value='Novedad'>|<input class='buttons entbtn' type='button' value='Entregado'>"
									}
								}
							],
							onClick: {
								call_btn: () => {
									//Para prevenir el onItemClick
									return false;
								}
							},
							on: {
								onItemClick: (id) => this.repartoItemClick(id),
								"data->onAfterFilter": () => this.updatePedidosTpl()
							},
							//save: "offline->server/repartos/saveEstados.php"
						},
						// {
						// 	view: "dataview",
						// 	localId: "repartoDv",
						// 	// autowidth: true,
						// 	// autoheight: true,
						// 	gravity: 1,
						// 	template: function (item) {
						// 		console.log(item);
						// 		let str = "";
						// 		str += "<strong>" + item.rep + "</strong><br>";
						// 		str += item.dir + " " + item.bar + "<br>";
						// 		str += item.tel + " " + item.cel + "<br>";
						// 		str += "<table width='100%'>";
						//
						// 		//Conteo x Descripción
						// 		const des = new Map();
						// 		for (const i in item.items) {
						// 			if (!des.has(item.items[i].des_id)) {
						// 				des.set(item.items[i].des_id, {
						// 					id: item.items[i].des_id,
						// 					des: item.items[i].des,
						// 					d: 0,
						// 					n: 0,
						// 					e: 0
						// 				});
						// 			}
						// 			des.get(item.items[i].des_id).d++;
						// 		}
						// 		console.log(des);
						//
						// 		//Conteo x Tipo de Caja
						// 		const tc = [];
						// 		for (const i in item.items) {
						// 			if (tc[item.items[i].tc]) {
						// 				tc[item.items[i].tc]++;
						// 			} else {
						// 				tc[item.items[i].tc] = 1;
						// 			}
						// 			str += "<tr>" +
						// 				"<td>" + item.items[i].tc + "</td>" +
						// 				"<td>" + item.items[i].des + "</td>" +
						// 				"<tr>";
						// 		}
						//
						// 		console.log("TC:", tc);
						//
						// 		str += "</table>";
						// 		// str += "<input class='novbtn' type='button' value='Novedad'>|<input class='entbtn' type='button' value='Entregado'>";
						// 		str += "<div class='buttons'>ACTUALIZAR ESTADO</div>";
						//
						// 		return str;
						// 	},
						// 	item: {
						// 		// maxWidth:300, height: 180
						// 		width: "auto",
						// 		height: "auto",
						// 		// type:"tiles"
						//
						// 	},
						// 	xCount: 1,
						// 	yCount: 1,
						// 	// url: "server/repartos/getReparto.php?rpr_id=4",
						// 	pager: "pagerA",
						// 	on: {
						// 		"onSwipeX": (c1, c2) => {
						// 			if (c2.x > c1.x) {
						// 				// swipe from right to left
						// 				this.$$("pagerA").select("prev");
						// 			} else {
						// 				// swipe from left to right
						// 				this.$$("pagerA").select("next");
						// 			}
						// 			return false;
						// 		},
						// 		onItemClick: (id) => this.repartoItemClick(id)
						// 	}
						// },
						{
							cols: [
								// {
								// 	view: "pager", id: "pagerA",
								// 	animate: true,
								// 	size: 1,
								// 	// group:5,
								// 	template: "{common.first()} {common.prev()} Pedido {common.page()} de #limit# {common.next()} {common.last()}"
								// },
								{
									view: "template", localId: "pedTpl", template: "#c# Pedidos"
								},
								{
									view: "button", label: "Finalizar", width: 70, click: () => this.finalizarClick()
								}
							]
						}
					]
				},
				{
					localId: "detallesView",
					rows: [
						{
							view: "toolbar", elements: [
								{
									view: "icon", icon: "mdi mdi-arrow-left", width: 35,
									click() {
										this.$scope.$$("listadoView").show();
									}
								},
								{view: "label", label: "Detalles"}
							]
						},
						{
							localId: "detallesDt", view: "datatable", checkboxRefresh: true,
							// leftSplit: 1,
							rightSplit: 1,
							columns: [
								{id: "tc", header: "TC", width: 40},
								// {id: "est_id", header: "EST", width: 40},
								{id: "des", header: "DESCRIPCIÓN", adjust: "data"},
								{id: "id", header: "CÓDIGO", width: 200},
								{id: "caj_num", header: "Nº CAJA", width: 120},
								{id: "est_n_e", header: "", template: this.custom_checkbox, width: 40}
							]
						},
						{
							view: "form", localId: "estFrm",
							elements: [
								{
									view: "richselect",
									localId: "novCmb",
									name: "nov_id",
									label: "Novedad",
									// options:["One", "Two", "Three"]
									options: "server/eventos/reparto.php",
									labelPosition: "top"
								},
								{
									view: "textarea",
									localId: "obsTxt",
									name: "obs",
									label: "Observaciones",
									labelPosition: "top",
									height: 80
								},
								{
									view: "button", label: "Enviar",
									click: () => this.guardarClickIniciar()
								}
							]
						}
					]
				}
			]
		};
	}

	init(view) {
		this.currentPed = null;
		this.repartoDv = this.$$("repartoDv");
		this.detallesView = this.$$("detallesView");
		this.detallesDt = this.$$("detallesDt");
		this.estFrm = this.$$("estFrm");
		this.repartoDv.on_click.novbtn = (e, id, trg) => this.novedadClick(e, id, trg);
		this.repartoDv.on_click.entbtn = (e, id, trg) => this.entregadoClick(e, id, trg);
		// webix.dp(this.repartoDv).attachEvent("onBeforeUpdate", (id, action) => this.repartoDvBeforeUpdate(id, action));
	}

	filterList() {
		this.$$("estadoFilterBtn").setValue(-1);
		const input = this.$$("search").getValue().toLowerCase();
		this.repartoDv.filter(obj => {
			return (obj.rep.toLowerCase().indexOf(input) !== -1)
				|| (obj.id.toString().indexOf(input) !== -1);
		});
		this.repartoDv.showItemByIndex(0);
	}

	repartoItemClick(id) {
		this.currentPed = id;
		this.detallesView.show();
		const data = this.repartoDv.getItem(id).items;
		data.forEach(
			itm => {
				itm.est_n_e = (itm.est_id === 8) ? 1 : 0;
			}
		);
		this.detallesDt.clearAll();
		this.detallesDt.define("data", data);
	}

	novedadClick(e, id, trg) {
		this.detallesView.show();
		const data = this.repartoDv.getItem(id).items;
		this.detallesDt.clearAll();
		this.detallesDt.define("data", data);
		//block default onclick event
		return false;
	}

	entregadoClick(e, id, trg) {
		webix.message("row: " + id);
		return false;
	}

	custom_checkbox(obj, common, value) {
		if (value)
		// return "<div class='webix_table_checkbox checked'> EN </div>";
			return "<span class='webix_table_checkbox checked webix-icon mdi mdi-alpha-e-box'></span>";
		else
		// return "<div class='webix_table_checkbox notchecked'> NO </div>";
			return "<span class='webix_table_checkbox notchecked webix-icon mdi mdi-alpha-n-box'></span>";
	}

	urlChange(view) {
		const rprId = this.getParam("rprId", true);
		this.repartoDv.load("server/repartos/getReparto.php?rpr_id=" + rprId, "json",
			response => {
				this.updatePedidosTpl();
			}
		);
	}

	getLocation(options) {
		return new Promise(function (resolve, reject) {
			navigator.geolocation.getCurrentPosition(resolve, reject, options);
		});
	}

	validarForm() {
		const values = this.estFrm.getValues();

		let c = 0;
		this.detallesDt.data.each(
			itm => {
				if (itm.est_n_e === 0) {
					c++;
				}
			}
		);

		if(c > 0 && !values.nov_id) throw "Novedad no válida para ItemsView No Entregados";
	}


	guardarClickIniciar() {

		try {
			this.validarForm();
		} catch (e) {
			webix.alert({
				text: e,
				type: "alert-error"
			});
			return;
		}

		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0
		};
		this.getLocation(options)
			.then(position => this.guardarClickFinalizar(position))
			.catch(err => this.locationError(err));
	}

	locationError(err) {
		webix.message(`ERROR(${err.code}): ${err.message}`);
		this.guardarClickFinalizar({coords: {latitude: "", longitude: ""}});
	}

	guardarClickFinalizar(position) {
		const est = this.estFrm.getValues();
		est.t = Date.now();
		est.lat = position.coords.latitude;
		est.lon = position.coords.longitude;
		console.log(est);
		const ped = this.repartoDv.getItem(this.currentPed);
		let c_ent = 0;
		let c_no_ent = 0;

		ped.items.forEach(
			itm => {
				if (itm.est === undefined) itm.est = [];
				est.est_id = (itm.est_n_e === 1) ? 8 : 7; //TODO: Constantes
				itm.est_id = est.est_id;
				itm.est.push({
					est_id: est.est_id,
					t: est.t,
					lat: est.lat,
					lon: est.lon,
					nov_id: est.nov_id,
					obs: est.obs
				});
				if (itm.est_id === 8) c_ent++;
				if (itm.est_id === 7) c_no_ent++;
			}
		);
		ped.c_ent = c_ent;
		ped.c_no_ent = c_no_ent;
		ped.rpr_id = this.getParam("rprId", true);
		this.repartoDv.updateItem(ped.id, ped);
		this.estFrm.clear();
		this.saveEstados();
	}

	saveEstados(ped) {
		// server/repartos/saveEstados.php
		const estados = [];
		this.repartoDv.data.each(
			ped => {
				ped.items.forEach(
					itm => {
						if (itm.est !== undefined) {
							itm.est.forEach(
								est => {
									estados.push({
										est_id: est.est_id,
										t: est.t,
										nov_id: est.nov_id,
										ped: ped.id,
										cod: itm.id,
										lat: est.lat,
										lon: est.lon,
										obs: est.obs
									});
								}
							);
						}
					}
				);
			}
		);

		if (estados.length === 0) {
			webix.message("Sin estados pendientes por enviar");
			return;
		}

		const data = {
			rpr_id: this.getParam("rprId", true),
			estados: JSON.stringify(estados)
		};

		webix.ajax().post("server/repartos/saveEstados.php", data,
			response => {
				const r = JSON.parse(response);
				if (r.status === "success") {
					this.repartoDv.data.each(
						ped => {
							ped.items.forEach(
								itm => {
									if (itm.est !== undefined) {
										delete itm.est;
									}
								}
							);
							this.repartoDv.updateItem(ped.id, ped);
						}
					);
					webix.message("Se registraron " + r.c + " estados");
				}
				this.$$("listadoView").show();
			}
		);
	}

	segmentedClick(btn) {
		const id = this.$$(btn).getValue();
		//Los ids se asignan en el botón como número pero los devuelve como cadena
		if (id === "-1") {
			this.repartoDv.filter(null, null);
		} else if (id === "7") {
			this.repartoDv.filter(ped => {
				return ped.c_n > 0;
			});
		} else if (id === "8") {
			this.repartoDv.filter(ped => {
				return ped.c_ar === 0 && ped.c_d === 0 && ped.c_n === 0;
			});
		}
		this.repartoDv.showItemByIndex(0);
	}

	updatePedidosTpl() {
		this.$$("pedTpl").setValues({c: this.repartoDv.count()});
	}

	// repartoDvBeforeUpdate(id, action) {
	// 	action.rpr_id = this.getParam("rprId");
	// }
	finalizarClick() {
		this.saveEstados();
	}
}