import {JetView} from "webix-jet";

export default class Rutas extends JetView {

	config() {
		return {
			type: "space",
			cols: [
				{
					// gravity: 1,
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Rutas"},
								{},
								{view: "button", type: "icon", icon: "wxi-plus", width: 37, css: "app_button"},
								{
									view: "menu", openAction: "click", width: 35, /*subMenuPos: "bottom",*/
									data: [{
										icon: "ellipsis-v",
										submenu: [
											{id: "impRutMen", value: "Importar Rutas...", icon: "mdi mdi-upload"},
											{id: "expRutMen", value: "Exportar Rutas", icon: "mdi mdi-download"},
											// {$template:"Separator"}
										]
									}],
									on: {
										onMenuItemClick: id => {
											if (id === "impRutMen") {
												this.uploadAPI.fileDialog({rowid: id.row});
											}
										}
									}
								}
							]
						},
						{view: "search", localId: "filterTxt"},
						{
							// view: "list",
							view: "datatable",
							localId: "rutLst",
							// width: 300,
							// template: "#rut#<br>#emp#, #zon#<br>#mun# #bar#",
							columns: [
								{
									template: "<div class='ruta_sec'><div class='ruta'>#rut#</div></div>" +
										"<div class='ped_info'>#emp# - #zon#<br>#bar#<br><span style='font-size:smaller'>#mun#</span></div>",
									fillspace: true
								}
							],
							// type: {height: 60},
							rowHeight: 60,
							rowLineHeight: 20,
							header: false,
							select: true,
							scrollX: false,
							url: "server/rutas/list.php",
							// dynamic: true,
							// datafetch:50,
							// datathrottle: 500,
							// loadahead:100
							// save: {
							// 	url: "server/personal/save.php?XDEBUG_SESSION_START=12345",
							// 	updateFromResponse: true
							// },
							// on: {
							// 	onSelectChange: id => this.rutLstSelectChange(id)
							// }
						},
					]
				},
				{view: "resizer"},
				{
					// gravity: 2,
					rows: [
						{
							view: "toolbar", elements: [
								{view: "button", type: "icon", icon: "chevron-left", width: 35},
								{},
								{
									view: "button",
									// label: "Guardar",
									// type: "iconButton",
									type: "icon",
									width: 35,
									icon: "save",
									// click: () => this.guardarClick()
								},
								{
									view: "button",
									// label: "Borrar",
									type: "icon",
									autowidth: true,
									icon: "trash",
									width: 35
								}
							]
						},
						{
							view: "form",
							localId: "rutFrm",
							elementsConfig: {
								labelPosition: "top"
							},
							elements: [
								{
									cols: [
										{view: "text", name: "rut", label: "Ruta"},
										{
											view: "combo",
											name: "emp_id",
											label: "Empresa",
											options: {
												body: {
													template: "#emp#"
												},
												url: "server/empresas/list.php"
											},
											gravity: 2
										}
									]
								},
								{view: "text", name: "zon", label: "Zona"},
								{
									view: "richselect",
									localId: "depCmb",
									name: "dep_id",
									label: "Departamento",
									options: {url: "server/rutas/departamentos.php"}
								},
								{
									view: "richselect",
									localId: "munCmb",
									name: "mun_id",
									label: "Municipio",
									options: {
										url: "server/rutas/municipios.php",
										// on: {
										// 	onShow: () => {
										// 		const depId = this.getRoot().queryView({localId: "depCmb"}).getValue();
										// 		if (depId) {
										// 			// this.getList().filter(obj => obj.depId = depId);
										// 		}
										// 	}
										// }
									}
								},
								// {
								// 	view: "combo",
								// 	localId: "barCmb",
								// 	name: "bar_id",
								// 	label: "Barrio",
								// 	options: {url: "server/rutas/barrios.php"}
								// },
								{view: "text", name: "bar", label: "Barrio"},
								{view: "text", name: "rgx", label: "Condición", bottomLabel: "Expresión Regular"},
								// {view: "text", name: "sct", label: "Sector"},
								// {view: "text", name: "uni", label: "Unidad"},
								{}
							]
						}
					]
				}
			]
		};
	}

	init(view) {
		this.rutLst = view.queryView({localId: "rutLst"});
		this.rutFrm = view.queryView({localId: "rutFrm"});
		this.depCmb = view.queryView({localId: "depCmb"});
		this.munCmb = view.queryView({localId: "munCmb"});
		this.barCmb = view.queryView({localId: "barCmb"});
		// this.uploadAPI = view.queryView({localId: "uploadAPI"});
		this.uploadAPI = webix.ui({
			view: "uploader",
			localId: "uploadAPI",
			apiOnly: true,
			upload: "server/rutas/importar.php"
		});
		this.rutFrm.bind(this.rutLst);
		const filterTxt = view.queryView({localId: "filterTxt"});

		filterTxt.attachEvent("onTimedKeyPress", () => {
			const txt = filterTxt.getValue().toUpperCase();
			if (txt.length === 0) {
				this.rutLst.filter();
				return;
			}

			this.rutLst.filter(obj => {
				if (txt.length <= 2) {
					return obj.rut.toString().indexOf(txt) !== -1;
				} else {
					return obj.bar.toString().toUpperCase().indexOf(txt) !== -1
						|| obj.mun.toString().toUpperCase().indexOf(txt) !== -1;
					// || obj.dep.toUpperCase().indexOf(txt) !== -1;
				}
			}, null, false);
			this.rutLst.scrollTo(0, 0);
		});

		this.depCmb.attachEvent("onChange", () => {
			this.munCmb.setValue(null);
		});

		(this.munCmb.getPopup()).attachEvent("onShow", () => {
			const depId = this.depCmb.getValue();
			const list = this.munCmb.getList();
			list.filter(function (obj) {
				return obj.dep_id === depId;
			}, null);
		});

		// this.munCmb.attachEvent("onChange", () => {
		// 	this.barCmb.setValue(null);
		// });

		// (this.barCmb.getPopup()).attachEvent("onShow", () => {
		// 	const munId = this.munCmb.getValue();
		// 	const list = this.barCmb.getList() ;
		// 	list.filter(function (obj) {
		// 		return obj.mun_id === munId;
		// 	}, null);
		// });


	}
}