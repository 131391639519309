import {JetView} from "webix-jet";
import {download} from "../libs/utils";

export default class Asignacion extends JetView {
	config() {
		return {
			cols: [
				{$subview: true},
				{
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Pedidos"},
								{},
								{
									view: "icon", icon: "mdi mdi-minus-box",
									tooltip: "Exportar .txt",
									click: () => this.removerAsignacion()
								},
								{
									view: "icon", icon: "mdi mdi-file-download",
									tooltip: "Exportar .txt",
									click: () => this.exportarAsignacion()
								},

							]

						},
						{
							//header: "Pedidos",
							header: false,
							localId: "pedidosDt",
							view: "list",
							//columns: [
							//	{
							template: "<div class='ruta_sec'>" +
								"<div class='ruta'>#rut_nor#</div>" +
								"<div class='secuencia'>#sec_nor#</div>" +
								"</div>" +
								"<div class='ped_info'>" +
								"<strong>#id#</strong> CAJAS:#caj# FRÍOS:#ff#<br>" +
								"#rep#<br>" +
								"#dir#<br>" +
								"#bar#<br>" +
								"</div>",
							//		fillspace: true
							//	}
							//],
							//rowHeight: 90,
							type: {
								height: 90
							},
							drag: "order",
							select: "row",
							multiselect: true
						}
					]
				}
			]
		};
	}

	init(view) {
		this.on(this.app, "reparto:itemclick", id => this.repartoItemClick(id));
		this.on(this.app, "reparto:save", values => this.repartoSave(values));
	}

	repartoItemClick(id) {
		console.log("reparto:itemclick", id);
	}

	repartoAdd() {
		console.log("reparto:add");
		//this.getSubView("repartoForm").show();
		this.getRoot().getChildViews()[1].show();
	}

	repartoSave(values) {
		console.log(values);

		webix.ajax().post("server/reparto/save.php", values)
			.then(
				response => {
					const r = response.json();
					if (r.status === "success") {
						console.log(r);
						this.getParentView().show("asignacion.items?repId=" + r.id);
					} else if (r.status === "error") {
						webix.alert({type: "alert-error", title: "Error al guardar", text: r.msg});
					}
					// console.log(response, this.getSubView("repartosForm").setId(r.data.id));
				}
			);

	}

	exportarAsignacion() {
		var lineas = "GUIAS\r\n";
		this.$$("pedidosDt").data.each(i => lineas += i.id + "\r\n");
		lineas = lineas.substring(0, lineas.length - 2);
		download((new Date()).getTime() + ".txt", lineas);
	}

	removerAsignacion() {
		const pedidosRemove = this.$$("pedidosDt").getSelectedId(true);
		this.$$("pedidosDt").remove(pedidosRemove);

		const params = {
			pedidos: JSON.stringify(pedidosRemove)
		};

		webix.ajax().post("server/asignacion/remove.php", params)
			.then(response => {
				webix.message(response.json().data);
				console.log(response);
			});
	}

}