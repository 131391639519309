import {JetView} from "webix-jet";
import {DetallesData} from "models/items";

export default class InfoDetalles extends JetView {
	config() {
		return {
			localId: "detalleDt",
			header: "Detalle",
			view: "datatable",
			columns: [
				{id: "l", header: "L", width: 50, css: {"text-align": "right"}},
				{id: "des", header: "DESCRIPCIÓN", headermenu:false, fillspace: true, css: {"text-align": "center"}},
				{id: "r", header: "R", width: 50, css: {"text-align": "right"}},
				{id: "dm", header: "DM", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "a", header: "AI", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "d", header: "D", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "n", header: "N", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "e", header: "E", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "lg", header: "LG", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "ds", header: "DS", width: 50, css: {"text-align": "right"}, hidden: true},
				{ header:{ content:"headerMenu" }, headermenu:false, width:32 }
			],
			select: "cell",
			scrollX: false,
			on: {
				onSelectChange: () => this.detalleDtSelectChange()
			}
		};
	}

	init(view) {
		this.$$("detalleDt").sync(DetallesData);
	}

	detalleDtSelectChange() {
		const item = this.$$("detalleDt").getSelectedItem(false);
		if (item === undefined) {
			return;
		}
		const col = this.$$("detalleDt").getSelectedId(false, false).column;

		// this.itemsLst.filter(null, null);

		const filters = [];
		// this.itemsLst.filter(function (obj) {
		filters.push({k: "des_id", v: item.id});
		//return obj.des_id === item.id && obj.est_id === 1;
		if (col === "l") {
			filters.push({k: "est_id", v: 1});
		} else if (col === "r") {
			// return obj.des_id === item.id && obj.est_id === 2;
			filters.push({k: "est_id", v: 2});
		}

		this.app.callEvent("items:filter", [filters]);
	}

}