function status(){
	return webix.ajax().post("server/login.php?status")
		.then(a => a.json());
}

function login(user, pass){
	return webix.ajax().post("server/login.php?XDEBUG_SESSION_START=12345", {
		user, pass, t: Date.now()
	}).then(a => {
		a = a.json();
		if(a.status === "error"){
			webix.alert({
				title: "ERROR",
				text: a.msg,
				type: "alert-error"
			});
		} else {
			return a;
		}
	});
}

function logout(){
	document.cookie = "PHPSESSID= ; max-age=0";
	window.location.replace(window.location.origin);
	return webix.ajax().post("server/login.php?logout")
		.then(a => a.json());
}

export default {
	status, login, logout
};