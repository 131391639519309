import {JetView} from "webix-jet";

export default class ListadosImportar extends JetView {
	config(){
		return {
			view: "window",
			head: "Procesando listado ...",
			position: "center",
			body: [
				{template: "Empresa:#emp#<br>Campaña:#cam#<br>Zona:#zon#", data:{emp:"Avon",cam:"10",zon:"4001,4002,4003"}},
				{
					cols: [
						{view: "button", value: "Cancelar",
							click:()=>{
								// console.log(this);
								// (this.getRoot() as webix.ui.window).hide();
								this.getRoot().hide();
								// this.$scope.close();
							}},
						{view: "button", value: "Importar"}
					]
				}
			]
		};
	}

	showWindow(){
		this.getRoot().show();
	}
}