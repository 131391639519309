import {JetView} from "webix-jet";
import RepartoMap from "views/reparto/map";

export default class Dashboard extends JetView {
	config() {
		return {
			view: "scrollview",
			scroll: "y",
			body: {
				type: "space",
				rows: [
					{
						type: "wide", cols: [
							{
								view: "portlet", header: "Mapa", height: 500, body: {
									rows: [
										RepartoMap
									]
								}
							},
							{
								view: "portlet", header: "Novedades",
								body: {
									view: "datatable", autoConfig: true, autoheight: true,
									data: [
										{id: 1, Representante: "Maria Antonia Estupiñan", Causal: "NADIE EN CASA"},
										{id: 2, Representante: "Luz Mila Mendieta", Causal: "NADIE EN CASA"},
										{id: 3, Representante: "Yerly Patricia Rivera", Causal: "NO RESIDE"},
										{id: 4, Representante: "Angeliza María Díaz", Causal: "RECHAZADO"},
										{id: 5, Representante: "Fulano de Tal", Causal: "SE MUDO"},
										{id: 6, Representante: "Claudia Patricia Jimenez", Causal: "DIRECCIÓN DEFICIENTE"}
									]
								}
							}
						]
					},
					{
						view: "portlet", header: "Transportador - Estados", height: 500,
						body: {
							// container:"chartDiv",
							view: "chart",
							type: "stackedBarH",
							barWidth: 60,
							alpha: 0.8,
							padding: {
								left: 80
							},
							xAxis: {},
							yAxis: {
								lines: true,
								template: "'#tra#",
								//width: 200
							},
							legend: {
								values: [
									{text: "Asignado", color: "#FFD595"},
									{text: "Entregado", color: "#CAEDB9"},
									{text: "No Entregado", color: "#FFB6C5"}
								],
								valign: "top",
								align: "center",
								// width:100,
								layout: "x"
							},
							series: [
								{
									value: "#AS#",
									label: "#AS#",
									color: "#FFD595",
									tooltip: {
										template: "#AS#"
									}
								},
								{
									value: "#E#",
									color: "#CAEDB9",
									label: "#E#",
									tooltip: {
										template: "#E#"
									}
								},
								{
									value: "#NE#",
									color: "#FFB6C5",
									label: "#NE#",
									tooltip: {
										template: "#NE#"
									}
								}
							],
							url: "server/dashboard/estadosxtransportador.php?fecha=2020-11-07"
						}
					}
				]
			}
		};
	}
}