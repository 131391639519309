import {JetView, plugins} from "webix-jet";

export default class ViajesListado extends JetView {
	// load(empId, sucId){
	// 	console.log(empId, sucId);
	// }

	config() {
		return {
			view: "datatable", localId: "dt",
			leftSplit: 2,
			select: true,
			columns: [
				{id: "id", header: ["ID", {content: "serverFilter"}], width: 60, sort: "server", hidden: false},
				{id: "via", header: ["Viaje", {content: "serverFilter"}], width: 80, sort: "server"},
				{id: "cli", header: ["Cliente", ""], width: 60, sort: "server"},
				{id: "zon", header: ["Zona", {content: "serverFilter"}], fillspace: true, minWidth: 80, sort: "server"},
				{id: "yyyy", header: ["Año", {content: "serverFilter"}], width: 60, sort: "server"},
				{id: "cam", header: ["Camp.", {content: "serverFilter"}], width: 50, sort: "server"},
				// Ítems
				{
					id: "i", header: [
						{
							content: "columnGroup",
							closed: true,
							batch: "items",
							groupText: "Ítems",
							colspan: 10
						},
						{text: "Total", css: {"background-color": "#FFF", "font-weight": "bold"}}
					], width: 100,
					css: {"text-align": "right", "font-weight": "bold"}
				},
				{
					id: "i_l", batch: "items",
					header: [null, {text: "L", css: {"background-color": "#FFF", "font-weight": "bold"}}],
					width: 50,
					css: {"text-align": "right", "border-left": "#ccc solid 3px"},
					template: (item) => this.buildLink(item, "l"),
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#eee"};
						}
					}
				},
				{
					id: "i_r", batch: "items",
					header: [null, {text: "R", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#27aae1 solid 3px"},
					template(item) {
						return item.r > 0 ? item.r : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#cee1f9"};
						}
					}
				},
				{
					id: "i_dm", batch: "items",
					header: [null, {text: "DM", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#fbb040 dashed 3px"},
					template(item) {
						return item.dm > 0 ? item.dm : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#ffce95"};
						}
					}
				},
				{
					id: "i_o", batch: "items",
					header: [null, {text: "OR", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#fbb040 solid 3px"},
					template(item) {
						return item.o > 0 ? item.o : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#ffce95"};
						}
					}
				},
				{
					id: "i_d", batch: "items",
					header: [null, {text: "D", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#fbb040 solid 3px"},
					template(item) {
						return item.d > 0 ? item.d : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#ffce95"};
						}
					}
				},
				{
					id: "i_n", batch: "items",
					header: [null, {text: "N", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#ed5f64 solid 3px"},
					template(item) {
						return item.n > 0 ? item.n : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#FFB6C5"};
						}
					}
				},
				{
					id: "i_e", batch: "items",
					header: [null, {text: "E", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#8dc63f solid 3px"},
					template(item) {
						return item.e > 0 ? item.e : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#CAEDB9"};
						}
					}
				},
				{
					id: "i_lg", batch: "items",
					header: [null, {text: "LG", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#8dc63f dashed 3px"},
					template(item) {
						return item.er > 0 ? item.er : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#CAEDB9"};
						}
					}
				},
				{
					id: "i_ds", batch: "items",
					header: [null, {text: "DS", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#7f3f98 solid 3px"},
					template(item) {
						return item.ds > 0 ? item.ds : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#EEBBFF"};
						}
					}
				},
				//Pedidos
				{
					id: "p", header: [
						{
							content: "columnGroup",
							closed: true,
							batch: "pedidos",
							groupText: "Pedidos",
							colspan: 10
						},
						{text: "Total", css: {"background-color": "#FFF", "font-weight": "bold"}}
					], width: 100,
					css: {"text-align": "right", "font-weight": "bold"}
				},
				{
					id: "p_l", batch: "pedidos",
					header: [null, {text: "L", css: {"background-color": "#FFF", "font-weight": "bold"}}],
					width: 50,
					css: {"text-align": "right", "border-left": "#ccc solid 3px"},
					template: (item) => this.buildLink(item, "l"),
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#eee"};
						}
					}
				},
				{
					id: "p_r", batch: "pedidos",
					header: [null, {text: "R", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#27aae1 solid 3px"},
					template(item) {
						return item.r > 0 ? item.r : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#cee1f9"};
						}
					}
				},
				{
					id: "p_dm", batch: "pedidos",
					header: [null, {text: "DM", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#fbb040 dashed 3px"},
					template(item) {
						return item.dm > 0 ? item.dm : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#ffce95"};
						}
					}
				},
				{
					id: "p_o", batch: "pedidos",
					header: [null, {text: "OR", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#fbb040 solid 3px"},
					template(item) {
						return item.o > 0 ? item.o : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#ffce95"};
						}
					}
				},
				{
					id: "p_d", batch: "pedidos",
					header: [null, {text: "D", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#fbb040 solid 3px"},
					template(item) {
						return item.d > 0 ? item.d : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#ffce95"};
						}
					}
				},
				{
					id: "p_n", batch: "pedidos",
					header: [null, {text: "N", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#ed5f64 solid 3px"},
					template(item) {
						return item.n > 0 ? item.n : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#FFB6C5"};
						}
					}
				},
				{
					id: "p_e", batch: "pedidos",
					header: [null, {text: "E", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#8dc63f solid 3px"},
					template(item) {
						return item.e > 0 ? item.e : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#CAEDB9"};
						}
					}
				},
				{
					id: "p_lg", batch: "pedidos",
					header: [null, {text: "LG", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#8dc63f dashed 3px"},
					template(item) {
						return item.er > 0 ? item.er : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#CAEDB9"};
						}
					}
				},
				{
					id: "p_ds", batch: "pedidos",
					header: [null, {text: "DS", css: {"background-color": "#FFF"}}],
					width: 45,
					css: {"text-align": "right", "border-left": "#7f3f98 solid 3px"},
					template(item) {
						return item.ds > 0 ? item.ds : "";
					},
					cssFormat(value, config) {
						if (value !== "") {
							return {"background-color": "#EEBBFF"};
						}
					}
				}
			],
			on: {
				// onBeforeLoad(){
				// 	console.log("onBeforeLoad");
				// 	this.showOverlay("Loading...");
				// },
				// onAfterLoad() {
				// 	console.log("onAfterLoad");
				// 	this.hideOverlay();
				// },
				onItemClick(e, c, t) {
					const estados = {l: 1, r: 2, av: 3, dm: 4, ar: 5, d: 6, n: 7, e: 8, lg: 9, ds: 10};
					this.$scope.setParam("viaId", e, true);
					if (this.getItem(e.row)[e.column] > 0) {
						this.$scope.show("items.main?viaId=" + e.row + "&estId=" + estados[e.column]);
					}
				}
			},
			url: function (params) {
				params = params || {};
				params.empId = this.$scope.getParam("empId", true);
				return webix.ajax().get("server/viajes/list.php?XDEBUG_SESSION_START=12345", params);
			}
		};
	}

	init(view) {

	}

	urlChange(view, url) {
		// console.log("Viajes / Listado");
		// view.clearAll();
		// view.showOverlay("Cargando ...");
		//
		// const params = {
		// 	sucId: this.getParam("sucId", true),
		// 	empId: this.getParam("empId", true)
		// };
		//
		// webix.ajax().get("server/viajes/list.php", params, res => {
		// 	// console.log(res);
		// 	view.parse(JSON.parse(res), "json");
		// });
	}

	buildLink(item, est) {
		if (item[est] === 0) return "";

		const estados = {l: 1, r: 2, av: 3, dm: 4, ar: 5, d: 6, n: 7, e: 8, lg: 9, ds: 10};

		const filter = {
			glue: "and",
			rules: [
				{key: "via_id", value: item.id, rule: "equal"},
				{key: "est_id", value: estados[est], rule: "equal"},
			]
		};

		return "<a route=\"items.main?filters='" + JSON.stringify(filter) + "'\">" + item[est] + "</a>";
	}
}