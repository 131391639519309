import {JetView} from "webix-jet";

export default class List extends JetView{
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Reparto"},
					]
				},
				{
					view: "list",
					template: "<div class='iniciar_reparto'><span style='display: inline-block; line-height: 14px; vertical-align: middle;'>INICIAR REPARTO</span></div><strong>#con# - #pla#</strong><br>Dispositivo: #dis#<br>Muelle: #mue#<br>",
					url: "server/asignacion/repartos.php?XDEBUG_SESSION_START=12345",
					type: {
						height: 80
					},
					on: {
						onItemClick: id => {
							this.getParentView().setParam("rprId", id, true);
							this.getParentView().show("reparto.enruta");
						}
					}
				}
			]
		};
	}

}