import {JetView} from "webix-jet";

export default class Info extends JetView {

	config() {

		return {
			view: "scrollview",
			scroll: "y",
			body: {
				type: "space",
				rows: [
					{
						localId: "infTpl",
						type: "header", template: "Listados: #lisId#"
					},
					{
						view: "datatable",
						localId: "undxEstDt",
						autoheight: true,
						columns: this.buildColumns("Descripción"),
						leftSplit: 2,
						on: {
							onItemClick: (item) => {
								const filters = {
									lisId : this.getParam("lisId", true),
									desId : item.row //Des Id
								};
								if(item.column !== "tot" && item.column !== "des"){
									filters.estCod = item.column;
								}
								this.showUrl("items.main", filters);
							}
						}
					},
					{
						view: "datatable",
						localId: "undxFecPrmxEstDt",
						autoheight: true,
						columns: this.buildColumns("Fecha Promesa","date"),
						leftSplit: 2,
						on: {
							onItemClick: (item) => {
								const filters = {
									lisId : this.getParam("lisId", true),
									fecPrm : item.row,
									//desId : 2
								};
								if(["PC","C"].includes(this.und)) filters.desId = 2;
								if(item.column !== "tot" && item.column !== "des"){
									filters.estCod = item.column;
								}
								this.showUrl("items.main", filters);
							}
						}
					},
					{
						view: "datatable",
						localId: "undxZonxEstDt",
						autoheight: true,
						columns: this.buildColumns("Zona"),
						leftSplit: 2,
						on: {
							onItemClick: (item) => {
								const filters = {
									lisId : this.getParam("lisId", true),
									zon : item.row
								};
								if(["PC","C"].includes(this.und)) filters.desId = 2;
								if(item.column !== "tot" && item.column !== "des"){
									filters.estCod = item.column;
								}
								this.showUrl("items.main", filters);
							}
						}
					},
					{
						view: "datatable",
						localId: "undxRutxEstDt",
						autoheight: true,
						columns: this.buildColumns("Ruta","int"),
						leftSplit: 2,
						//select: "row",
						on: {
							onItemClick: (item) => {
								const filters = {
									lisId : this.getParam("lisId", true),
									rutNor : item.row
								};
								if(["PC","C"].includes(this.und)) filters.desId = 2;
								if(item.column !== "tot" && item.column !== "des"){
									filters.estCod = item.column;
								}
								this.showUrl("items.main", filters);
							}
						}
					},
					{
						view: "datatable",
						localId: "undxEtqxEstDt",
						autoheight: true,
						columns: this.buildColumns("Etiqueta"),
						leftSplit: 2,
						on: {
							onItemClick: (item) => {
								const filters = {
									lisId : this.getParam("lisId", true),
									tagId : item.row
								};
								if(["PC","C"].includes(this.und)) filters.desId = 2;
								if(item.column !== "tot" && item.column !== "des"){
									filters.estCod = item.column;
								}
								this.showUrl("items.main", filters);
							}
						}
					},
					{
						view: "datatable",
						localId: "undxIndxEstDt",
						autoheight: true,
						columns: this.buildColumns("Indicador"),
						leftSplit: 2,
						on: {
							onItemClick: (item) => {
								const filters = {
									lisId : this.getParam("lisId", true),
									ind : item.row
								};
								if(["PC","C"].includes(this.und)) filters.desId = 2;
								if(item.column !== "tot" && item.column !== "des"){
									filters.estCod = item.column;
								}
								this.showUrl("items.main", filters);
							}
						}
					},
				]

			}
		};

	}

	urlChange() {
		this.$$("undxEstDt").showOverlay("<img src='codebase/style/tdsx_logo_anim.svg' width='48' />");
		const lisId = this.getParam("lisId", true);
		this.und = this.getParam("und", true);
		console.log("INFO URLCHANGE", lisId);
		this.$$("infTpl").setValues({lisId});

		webix.ajax().get("server/Listados/getInfo.php", {lis_id: lisId, und: this.und})
			.then(response => {
				response = response.json();
				this.$$("undxEstDt").clearAll();
				this.$$("undxEtqxEstDt").clearAll();
				this.$$("undxFecPrmxEstDt").clearAll();
				this.$$("undxIndxEstDt").clearAll();
				this.$$("undxRutxEstDt").clearAll();
				this.$$("undxZonxEstDt").clearAll();
				this.$$("undxEstDt").parse(response.undxEst, null, true);
				this.$$("undxEtqxEstDt").parse(response.undxEtqxEst, null, true);
				this.$$("undxFecPrmxEstDt").parse(response.undxFecPrmxEst, null, true);
				this.$$("undxIndxEstDt").parse(response.undxIndxEst, null, true);
				this.$$("undxRutxEstDt").parse(response.undxRutxEst, null, true);
				this.$$("undxZonxEstDt").parse(response.undxZonxEst, null, true);
				this.$$("undxEstDt").hideOverlay();
			});
	}

	buildColumns(header, sort="raw") {
		return [
			{id: "des", header, minWidth: 100, fillspace: true, sort},
			{id: "tot", header: "Total", width: 50, sort: "int"},
			{id: "L", header: {text: "L", css: "estado_header estL_header"}, width: 40, css: "estado_cell estL", sort: "int"},
			{id: "R", header: {text: "R", css: "estado_header estR_header"}, width: 40, css: "estado_cell estR", sort: "int"},
			{id: "AS", header: {text: "AS", css: "estado_header estAS_header"}, width: 40, css: "estado_cell estAS", sort: "int"},
			{id: "DI", header: {text: "DI", css: "estado_header estDI_header"}, width: 40, css: "estado_cell estDI", sort: "int"},
			{id: "RP", header: {text: "RP", css: "estado_header estRP_header"}, width: 40, css: "estado_cell estRP", sort: "int"},
			{id: "N", header: {text: "N", css: "estado_header estN_header"}, width: 40, css: "estado_cell estN", sort: "int"},
			{id: "E", header: {text: "E", css: "estado_header estE_header"}, width: 40, css: "estado_cell estE", sort: "int"},
			{id: "LG", header: {text: "LG", css: "estado_header estLG_header"}, width: 40, css: "estado_cell estLG", sort: "int"},
			{id: "DS", header: {text: "DS", css: "estado_header estDS_header"}, width: 40, css: "estado_cell estDS", sort: "int"},
			{id: "DV", header: {text: "DV", css: "estado_header estDV_header"}, width: 40, css: "estado_cell estDV", sort: "int"},
			{id: "NE", header: {text: "NE", css: "estado_header estNE_header"}, width: 40, css: "estado_cell estNE", sort: "int"},
			{id: "EF", header: {text: "EF", css: "estado_header estEF_header"}, width: 40, css: "estado_cell estEF", sort: "int"},
		];
	}

	showUrl(url, filters){
		let paramsStr = "";
		for(const filter in filters){
			paramsStr += "&" + filter + "=" + filters[filter];
		}
		paramsStr = paramsStr.substr(1);
		this.show("/items.main?" + paramsStr);
	}

}