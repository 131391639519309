import {JetView} from "webix-jet";
import {getEstados} from "../../models/estados";
//import mapboxgl from "mapbox-gl/dist/mapbox-gl";
// var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

export default class RepartoMap extends JetView {
	config() {
		return {
			template: "<div id='repartoMap' style='width: 100%; height: 100%'></div>"
		};
	}

	init() {
		// if (mapboxgl.supported()) {
		// 	this.init_mapbox();
		// } else {
		// 	this.init_leaflet();
		// }
		this.init_leaflet();
	}

	init_leaflet() {
		// var marker = L.marker([51.5, -0.09]).addTo(mymap);
		// marker.bindPopup("<b>Hello world!</b><br>I am a popup.");

		var redIcon = new L.Icon({
			iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
			shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.5.1/images/marker-shadow.png",
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41]
		});

		var greenIcon = new L.Icon({
			iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
			shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.5.1/images/marker-shadow.png",
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41]
		});

		const entregado = L.layerGroup();
		const no_entregado = L.layerGroup();
		webix.ajax().sync().get("server/repartos/getLatLng.php", {rpr_id: this.getParentView().getParam("rprId")},
			(raw, res) => {
				const ubc = res.json();
				ubc.forEach(
					u => {
						console.log(u);
						const popupStr = u.rep + "<br>" + u.dir + "<br>" + u.bar + "<br>" + u.rut_nor + "-" + u.sec_nor;
						if (u.est_cod == "N") {
							L.marker([u.lat, u.lon], {icon: redIcon}).bindPopup(popupStr).addTo(no_entregado);
						} else if (u.est_cod == "E") {
							L.marker([u.lat, u.lon], {icon: greenIcon}).bindPopup(popupStr).addTo(entregado);
						}
					}
				);
			}
		);

		// let trayecto;
		// webix.ajax().sync().get("server/repartos/getPositions.php", {rpr_id: this.getParentView().getParam("rprId")},
		// 	(raw, res) => {
		// 		const ubc = res.json();
		// 		var latlngs = [];
		// 		ubc.forEach(
		// 			u => latlngs.push([u.lat, u.lon])
		// 		);
		// 		trayecto = L.polyline(latlngs, {color: "red"});
		// 	}
		// );

		//const mapbox = L.tileLayer("https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}", {
		const mapbox = L.tileLayer("https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}", {
			id: "mapbox.streets",
			attribution: "Map data &copy; <a href=\"https://www.openstreetmap.org/\">OpenStreetMap</a> contributors, <a href=\"https://creativecommons.org/licenses/by-sa/2.0/\">CC-BY-SA</a>, Imagery © <a href=\"https://www.mapbox.com/\">Mapbox</a>",
			maxZoom: 20,
			accessToken: "pk.eyJ1IjoibGpwYXJyYSIsImEiOiJjangzYXhja3QwMDNtNGFxbWxzZmhvbm1kIn0.b-QP-dt95NPY7d9z4yZLqw"
		});

		const osm = L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
			attribution: "Map data &copy; <a href=\"https://www.openstreetmap.org/\">OpenStreetMap</a> contributors, <a href=\"https://creativecommons.org/licenses/by-sa/2.0/\">CC-BY-SA</a>, Imagery © <a href=\"https://www.mapbox.com/\">Mapbox</a>",
			maxZoom: 19
		});
		const Esri_WorldImagery = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {
			attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
			maxZoom: 19
		});

		const Thunderforest_TransportDark = L.tileLayer("https://{s}.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey={apikey}", {
			attribution: "&copy; <a href=\"http://www.thunderforest.com/\">Thunderforest</a>, &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors",
			apikey: "535e352c315a423496b3bd6a5c65b16b",
			maxZoom: 22
		});

		const map = L.map("repartoMap", {
			center: [7.12, -73.11],
			zoom: 10,
			layers: [mapbox, no_entregado, entregado]
		});

		const baseLayers = {
			"MapBox": mapbox,
			"OpenStreetMap": osm,
			"Esri Satellite": Esri_WorldImagery,
			"Thunderforest Dark": Thunderforest_TransportDark
		};

		const overlays = {
			"No Entregado": no_entregado,
			"Entregado": entregado,
			// "Trayecto": trayecto
		};

		L.control.layers(baseLayers, overlays).addTo(map);
		//map.fitBounds(trayecto.getBounds());
	}

	// init_mapbox() {
	// 	mapboxgl.accessToken = "pk.eyJ1IjoibGpwYXJyYSIsImEiOiJjangzYXhja3QwMDNtNGFxbWxzZmhvbm1kIn0.b-QP-dt95NPY7d9z4yZLqw";
	// 	var map = new mapboxgl.Map({
	// 		container: "repartoMap",
	// 		style: "mapbox://styles/mapbox/streets-v11"
	// 	});
	// 	map.addControl(new mapboxgl.NavigationControl());
	// 	var marker = new mapboxgl.Marker()
	// 		.setLngLat([30.5, 50.5])
	// 		.addTo(map);
	//
	// 	webix.ajax().sync().get("server/repartos/getLatLng.php", {rpr_id: this.getParentView().getParam("rprId")},
	// 		(raw, res) => {
	// 			const ubc = res.json();
	// 			ubc.forEach(
	// 				u => {
	// 					//console.log(u);
	// 					// const popupStr = u.rep + "<br>" + u.dir + "<br>" + u.bar + "<br>" + u.rut_nor + "-" + u.sec_nor;
	// 					// if (u.est_cod == "N") {
	// 					// 	L.marker([u.lat, u.lon], {icon: redIcon}).bindPopup(popupStr).addTo(no_entregado);
	// 					// } else if (u.est_cod == "E") {
	// 					// 	L.marker([u.lat, u.lon], {icon: greenIcon}).bindPopup(popupStr).addTo(entregado);
	// 					// }
	// 					new mapboxgl.Marker({color: "#" + u.col})
	// 						.setLngLat([u.lon, u.lat])
	// 						.addTo(map);
	// 				}
	// 			);
	// 		}
	// 	);
	// }
}