import {JetView} from "webix-jet";

export default class ObtMap extends JetView {
	config() {
		const grid_data = [{
			"id": 1,
			"title": "The Shawshank Redemption",
			"year": 1994,
			"votes": 678790,
			"rating": 9.2,
			"rank": 1
		}, {"id": 2, "title": "The Godfather", "year": 1972, "votes": 511495, "rating": 9.2, "rank": 2}, {
			"id": 3,
			"title": "The Godfather: Part II",
			"year": 1974,
			"votes": 319352,
			"rating": 9,
			"rank": 3
		}, {
			"id": 4,
			"title": "The Good, the Bad and the Ugly",
			"year": 1966,
			"votes": 213030,
			"rating": 8.9,
			"rank": 4
		}, {"id": 5, "title": "My Fair Lady", "year": 1964, "votes": 533848, "rating": 8.9, "rank": 5}, {
			"id": 6,
			"title": "12 Angry Men",
			"year": 1957,
			"votes": 164558,
			"rating": 8.9,
			"rank": 6
		}];
		return {
			cols: [
				{view: "datatable", autoConfig: true, data: grid_data, drag: true},
				{view: "resizer"},
				{view: "abslayout", id: "targetLayout"},
				{view: "resizer"},
				{
					rows: [
						{type: "header", template: "Preview"},
						{id: "preview", template: "Properties from selected object"},
					]
				}
			]
		};
	}

	init() {
		this.absView = this.$$("targetLayout");

		webix.DragControl.addDrop(this.absView.getNode(), {
			$drop: function (source, target, event) {
				let context = webix.DragControl.getContext();
				let s = $$(source),
					t = $$(target);
				// make sure drop source if different from the target abslayout
				if (s.config.id !== t.config.id) {
					let item = $$(source).getItem(context.start);
					const id = $$(target).addView({
						template: "<div>#title#</div>",
						data: item,
						top: event.offsetY,
						left: event.offsetX,
						width: 150,
						height: 50
					});
				}
			}
		});

		webix.DragControl.addDrag(this.absView.$view, {
			$dragDestroy: function () {
				// preserve view instead of destroying DnD object
				return false;
			},
			$dragPos: function (pos, ev, source) {
				let control = source;
				control.style.left = pos.x - 1000 + "px";
				console.log(control.style.left, control.style.top);
			},
			// $dragPos: (pos, ev, source) => {
			// 	console.log(ev);
			// 	var context = webix.DragControl.getContext();
			// 	// let control = webix.$$(context.source[0]);
			// 	// let control = webix.$$(context.source);
			// 	let control = webix.$$(source.view_id);
			// 	console.log(control);
			// 	control.config.left = pos.x + context.x_offset;
			// 	pos.x = control.config.left - webix.DragControl.left;
			// 	control.config.top = pos.y + context.y_offset;
			// 	pos.y = control.config.top - webix.DragControl.top;
			// },
			$dragCreate: function (source, ev) {
				const el = webix.$$(ev);
				if (el && el !== this.absView) {
					var pos = webix.html.pos(ev);
					var context = webix.DragControl.getContext();

					context.source = [el.config.id];
					context.from = this.absView;
					context.y_offset = el.config.top - pos.y;
					context.x_offset = el.config.left - pos.x;

					// create DnD object from a view
					return el.$view;
				} else {
					// if DnD starts on empty area, start drawing a custom rectangle in abslayout
					drawStart(this.absView.$view);
					return false;
				}
			}
		});

	}
}