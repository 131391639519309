import {JetView} from "webix-jet";
// import Permisos from "views/permisos";

export default class Eventos extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Eventos"},
						{},
						{
							view: "button", type: "icon", icon: "plus", width: 37, css: "app_button",
							click() {
								const dt = this.$scope.$$("eventos_dt");
								dt.editRow(dt.add({pla: "Evento"}));
							}
						}
					]
				},
				{
					view: "datatable",
					id: "eventos_dt",
					columns: [
						{id: "id", header: "ID", width: 50},
						{id: "cod", header: "COD.", width: 50},
						{id: "evn", header: "Nombre", editor: "text", adjust:"data"},
						{id: "est_id", header: "Estado"}

					],
					url: "server/eventos/list.php",
					save: "server/eventos/save.php",
					editable: true,
					//editaction: "dblclick",
					updateFromResponse: true
				}
			]
		};
	}
}