import {JetView} from "webix-jet";
import {buildTags} from "../../models/etiquetas";

export default class InfoPedido extends JetView {
	config() {

		return {
			header: "Pedido",
			localId: "pedidoDt",
			view: "datatable",
			select: "cell",
			columns: [
				{id: "l", header: "L", width: 50, css: {"text-align": "right"}},
				{
					id: "des",
					header: "",
					height: 20,
					fillspace: true,
					css: {"text-align": "center"},
					// footer: "-"
				},
				{id: "r", header: "R", width: 50, css: {"text-align": "right"}}
			],
			footer: true,
			on: {
				onSelectChange: () => this.pedidoDtSelectChange()
			}
		};
	}

	init(){
		this.pedidoDt = this.$$("pedidoDt");
		this.on(this.app, "items:pedido:data", (item, data) => this.infoRefresh(item, data));
	}

	infoRefresh(item, data){
		console.log("ON items:pedido:data", data);
		this.pedidoDt.clearAll();
		this.pedidoDt.define("data", data);

		const screen = this.app.config.size;

		this.pedidoDt.config.columns[1].header = [
			{
				text: "<div style='font-size: x-small; font-weight: bold; line-height: 20px;text-align: center'>" + item.rep + "</div>",
				height: 20
			},
			{
				text: "<span style='font-size: x-small; line-height: 20px'>" + item.bar + " - " + item.dir + "</span>",
				height: 20
			}];

		const bgColor = (item.tag_color == null || item.tag_color === "") ? "#444" : "#" + item.tag_color;
		const tags = item.tags == null ? "" : buildTags(item.tags, "xx-large");

		if(screen !== "small") {
			this.pedidoDt.config.columns[0].footer = [
				{
					text: "<div style='padding-top: 30px; text-align: center; background-color: " + bgColor + "; color: white; line-height: 115px;'>" +
						"<div style='font-size: 200px; font-weight: bold;'>" + item.rutNor + "</div>" +
						"<div style='font-size: 72px; font-weight: bold'>" + item.secNor + "</div>" +
						"<div style='font-weight: bold; position: relative; top: -18px; line-height: 36px; overflow: auto; white-space: nowrap'>" + tags + "</div>" +
						"</div>",
					height: 280,
					colspan: 3
				}];
		} else {
			this.pedidoDt.config.columns[0].footer = [
				{
					text: "<div style='padding-top: 10px; text-align: center; background-color: " + bgColor + "; color: white; line-height: 70px;'>" +
						"<div style='font-size: 100px; font-weight: bold;'>" + item.rutNor + "</div>" +
						"<div style='font-size: 72px; font-weight: bold'>" + item.secNor + "</div>" +
						"<div style='font-weight: bold; position: relative; top: -10px; line-height: 36px; overflow: auto; white-space: nowrap'>" + tags + "</div>" +
						"</div>",
					height: 280,
					colspan: 3
				}];
		}

		this.pedidoDt.refreshColumns();
	}

	pedidoDtSelectChange(){
		const item = this.pedidoDt.getSelectedItem(false);
		if (item === undefined) {
			return;
		}
		const col = this.pedidoDt.getSelectedId(false, false).column;

		const filters = [];
		filters.push({k: "des_id", v: item.id});
		if (col === "l") {
			filters.push({k: "est_id", v: 1});
		} else if (col === "r") {
			filters.push({k: "est_id", v: 2});
		}

		this.app.callEvent("items:filter", [filters]);
	}
}