import {JetView} from "webix-jet";


export default class Escaneo extends JetView {
	config() {
		return {
			id: "col1B",
			// minWidth: 250,
			type: "clean",
			rows: [
				{
					view: "toolbar", elements: [
						{
							view: "icon", icon: "mdi mdi-arrow-left",
							click: () => {
								this.pedidosDt.clearAll();
								this.show("asignacion.list");
							}
						},
						{view: "label", label: "Asignar Reparto"},
						{},
						{
							view: "icon", icon: "mdi mdi-content-save", width: 35,
							click: () => this.asignacionRepartoSave()
						}
					]
				},
				{
					view: "form",
					elements: [
						{
							view: "text",
							localId: "codTxt",
							// label: "Código",
							placeholder: "Código de Pedido",
							labelWidth: 50,
							// labelPosition: "top",
							// labelAlign: "center",
							on: {
								onKeyPress: code => this.recCodTxtKeyPress(code)
							}
						}
					]
				},
				{
					view: "tabview",
					fitBiggest: true,
					cells: [
						{
							header: "General",
							localId: "generalDt",
							view: "datatable",
							select: "cell", footer: true,
							columns: [
								//{id: "l", header: "L", width: 50, css: {"text-align": "right"}},
								{
									id: "des",
									header: "RUTA",
									fillspace: true,
									//css: {"text-align": "center"},
									footer: {text: "Total:", colspan: 1}
								},
								{
									id: "as", header: "PEDIDOS", width: 80,
									css: {"text-align": "right"},
									footer: {content: "summColumn"}
								}
							],
							// autoheight: true,
							on: {
								onSelectChange: () => this.infoDtSelectChange()
							}
						},
						{
							localId: "detalleDt",
							header: "Detalle",
							view: "datatable",
							// scheme: {
							// 	$change: function (item) {
							// 		console.log(item);
							// 		item.$css = "itemChange";
							// 	}
							// },
							scheme: {
								$update: (item) => {
									//item.$css = "itemChange";
									const id = this.generalDt.getIndexById(item.id) + 1;
									console.log(id);
									setTimeout(() => {
										this.generalDt.$view.querySelectorAll("div[aria-rowindex=\"" + id + "\"]").forEach(function (cell) {
											cell.classList.add("itemChange");
										});
									}, 10);

									setTimeout(() => {
										this.generalDt.$view.querySelectorAll("div[aria-rowindex=\"" + id + "\"]").forEach(function (cell) {
											cell.classList.add("normal");
										});
									}, 100);
								}
							},
							columns: [
								//{id: "l", header: "L", width: 50, css: {"text-align": "right"}},
								{
									id: "des",
									header: {
										text: "DESCRIPCIÓN"
									},
									fillspace: true,
									css: {"text-align": "center"}
								},
								// {id: "r", header: "R", width: 50, css: {"text-align": "right"}}
								{id: "as", header: "AS", width: 50, css: {"text-align": "right"}}
							],
							select: "cell",
							scrollX: false,
							on: {
								onSelectChange: () => this.generalDtSelectChange()
							}
						}
					]
				}
			]
		};
	}

	init(view) {
		this.codTxt = view.queryView({localId: "codTxt"});
		this.pedidosDt = view.getParentView().queryView({localId: "pedidosDt"});
		this.generalDt = view.queryView({localId: "generalDt"});
		this.infoDetalles = new Map();
	}

	urlChange(view) {
		const rprId = this.getParam("rprId", true);
		console.log("URLCHANGE ITEMS", this.getParam("rprId"));
		this.pedidosDt.load("server/asignacion/getPedidos.php?rpr_id=" + rprId, "json",
			response => {
				this.calcularInfoGeneral();
			}
		);
	}

	recCodTxtKeyPress(code) {
		if (code !== 13) {
			return;
		}

		this.codTxt.config.value = this.codTxt.getInputNode().nodeValue;
		let cods = this.codTxt.getValue().trim();
		// Ignorar caracteres especiales en ciertas configuraciones de terminaciones en el scanner
		if (cods.length === 0) {
			return;
		}
		this.codTxt.setValue("");

		//...Set para eliminar códigos repetidos
		cods = [...new Set(cods.split(" "))];
		//TODO: Revisar: Antes solo era necesaria la línea anterior para convertir a Array
		cods = Array.from(cods[0]);

		//Agregar cod al DT
		for (const cod of cods) {
			this.pedidosDt.parse({
				id: parseInt(cod, 10),
				caj: "",
				ff: "",
				rep: "consultando ...",
				dir: "",
				bar: "",
				rut_act: "",
				sec_act: ""
			});
		}

		//Consultar información del código escaneado
		webix.ajax().get("server/asignacion/getCodInfo.php?cod=" + cods.join(","))
			.then(response => {
				const r = response.json();
				if (r.success) {
					this.pedidosDt.parse(r.data);
					this.refreshInfoGeneral(r.data);
				}
			});
	}

	calcularInfoGeneral() {
		this.pedidosDt.data.each(
			ped => this.refreshInfoGeneral([ped])
		);
	}

	refreshInfoRutas() {
		const rutas = new Map();
		this.pedidosDt.data.each(itm => {
			if (!rutas.has(itm.rut_nor)) rutas.set(itm.rut_nor, {ped_tot: 0});
			rutas.get(itm.rut_nor).ped_tot++;
		});
		let rutasData = [];
		rutas.forEach((v, k) => rutasData.push({id: k, des: k, as: v.ped_tot}));
		console.log("refreshInfoRutas", rutasData);
		this.generalDt.parse(rutasData);
	}

	refreshInfoGeneral(peds) {
		// if(this.infoGeneral.has(ped.))
		for (const ped of peds) {
			for (const item of ped.items) {
				if (!this.infoDetalles.has(item.des_id)) {
					this.infoDetalles.set(item.des_id, {id: item.des_id, des: item.des, as: 0});
				}
				this.infoDetalles.get(item.des_id).as++;
				if (this.$$("detalleDt").exists(item.des_id)) {
					this.$$("detalleDt").updateItem(item.des_id, this.infoDetalles.get(item.des_id));
				} else {
					this.$$("detalleDt").add(this.infoDetalles.get(item.des_id));
				}
			}
		}

		//this.$$("generalDt").define("data", Array.from(this.infoGeneral.values()));
		this.refreshInfoRutas();

	}


	asignacionRepartoSave() {
		const pedidos = [];
		let c = 1;
		this.pedidosDt.data.each(
			ped => {
				pedidos.push({ped: ped.id, ord: c++});
			}
		);

		const params = {
			rpr_id: this.getParam("rprId", true),
			pedidos: JSON.stringify(pedidos)
		};

		webix.ajax().post("server/asignacion/save.php", params)
			.then(response => {
				response =
					webix.message(response.json().data);
				console.log(response);
			});
	}
}