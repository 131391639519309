// import Permisos from "views/permisos";
import {JetView} from "webix-jet";

export default class Tamanos extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Tamaños"},
						{},
						{
							view: "button", type: "icon", icon: "wxi-plus", width: 37, css: "app_button",
							click() {
								const dt = this.$scope.$$("tamanos_dt");
								dt.editRow(dt.add({tam: "AAA", emp_id: -1, anc:0, alt:0, lar:0}));
							}
						}
					]
				},
				{
					view: "datatable",
					id: "tamanos_dt",
					columns: [
						{id: "id", header: "id", width: 100},
						{id:"emp_id", header:"Empresa",
							// editor: "text",
							editor: "combo",
							//template: "#emp#",
							options: [
								{id:-1, value:"Sin_Asignar", emp:"NA"},
								{id:1, value:"Avon", emp:"AVN"},
								{id:2, value:"Novaventa", emp:"NOV"}
								],
							suggest:{template:"#emp#"},
							// suggest:{options:"server/empresas/list.php"},
							width:100},
						{id: "tam", header: "Nombre", editor: "text", width: 200},
						{id: "anc", header: "Ancho", editor: "text", width: 80},
						{id: "alt", header: "Alto", editor: "text", width: 80},
						{id: "lar", header: "Largo", editor: "text", width: 80}
					],
					url: "server/tamanos/list.php",
					save: "server/tamanos/save.php?XDEBUG_SESSION_START=13188",
					editable: true,
					// editaction: "dblclick",
					updateFromResponse: true
				}
			]
		};
	}
}