import {JetView} from "webix-jet";
import {detallesData} from "models/detallesxdescripcion";
import {filtersToQueryString} from "../libs/utils";

export default class Detalles extends JetView {

	constructor(app, name, config) {
		super(app, name);
		this._toolbarVisible = config.toolbarVisible || false;
		this._config = config;
		this._visibilityColumns = {
			"L" : ["L","R","CM","AS","DI","RP","N","E","DS"],
			"R" : ["L","R"],
			"DI" : ["R","AS","DI"],
			"N" : ["RP","N"],
			"E" : ["RP","E"],
			"DV" : ["DV","NE","EF"],
		};
	}

	config() {
		const screen = this.app.config.size;

		const toolbar = {
			view: "toolbar", elements: [
				{
					view: "icon", icon: "mdi mdi-close",
					click: () => this.app.callEvent("detallesxdescripcion:close")
				},
				{view: "label", localId: "headerLbl", label: ""},
				{view: "multiselect", localId: "zonCmb", on: {onChange: v => this.zonCmbChange(v)}},
				{view: "icon"},
				{view: "icon"}
			]
		};

		const datatable = {
			localId: "detalleDt",
			view: "datatable", css: "rows", leftSplit: 2,
			columns: [
				{id: "tot", header: "TOTAL", width: 40, css: "detallesInfo"},
				{id: "ind", header: "IND.", width: 48, css: "detallesInfo", hidden: true},
				{
					id: "des",
					header: "DESCRIPCIÓN",
					headermenu: false,
					fillspace: true,
					minWidth: 100,
					css: {"font-size": "smaller"}
				},
				{id: "L", header: {text:"L", css:"estado_header estL_header"}, width: 40, css: "estado_cell estL", hidden: this.getVisibilityColumn("L")},
				{id: "R", header: {text:"R", css:"estado_header estR_header"}, width: 40, css: "estado_cell estR", hidden: this.getVisibilityColumn("R")},
				{id: "AS", header: {text:"AS", css:"estado_header estAS_header"}, width: 40, css: "estado_cell estAS", hidden: this.getVisibilityColumn("AS")},
				{id: "DI", header: {text:"DI", css:"estado_header estDI_header"}, width: 40, css: "estado_cell estDI", hidden: this.getVisibilityColumn("DI")},
				{id: "RP", header: {text:"RP", css:"estado_header estRP_header"}, width: 40, css: "estado_cell estRP", hidden: this.getVisibilityColumn("RP")},
				{id: "N", header: {text:"N", css:"estado_header estN_header"}, width: 40, css: "estado_cell estN", hidden: this.getVisibilityColumn("N")},
				{id: "E", header: {text:"E", css:"estado_header estE_header"}, width: 40, css: "estado_cell estE", hidden: this.getVisibilityColumn("E")},
				{id: "LG", header: {text:"LG", css:"estado_header estLG_header"}, width: 40, css: "estado_cell estLG", hidden: this.getVisibilityColumn("LG")},
				{id: "DS", header: {text:"DS", css:"estado_header estDS_header"}, width: 40, css: "estado_cell estDS", hidden: this.getVisibilityColumn("DS")},
				{id: "DV", header: {text:"DV", css:"estado_header estDV_header"}, width: 40, css: "estado_cell estDV", hidden: this.getVisibilityColumn("DV")},
				{id: "NE", header: {text:"NE", css:"estado_header estNE_header"}, width: 40, css: "estado_cell estNE", hidden: this.getVisibilityColumn("NE")},
				{id: "EF", header: {text:"EF", css:"estado_header estEF_header"}, width: 40, css: "estado_cell estEF", hidden: this.getVisibilityColumn("EF")},
				{header: {content: "headerMenu"}, headermenu: false, width: 32}
			],
			data: detallesData,
			select: "cell",
			//scrollX: false,
			on: {
				/*onItemClick: cell => {
					this.filters.dscId = cell.row;
					if (cell.column !== "tot" && cell.column !== "des") this.filters.estCod = cell.column;
					this.app.callEvent("detallesxdescripcion:click", [this.filters]);
				}*/
				onSelectChange: () => {
					const cell = this.$$("detalleDt").getSelectedId();
					console.log("Detalles / SelectChange", cell);
					if(this.filters == undefined) this.filters = {};
					this.filters.desId = cell.row;
					this.filters.estCod = cell.column;
					this.app.callEvent("detallesxdescripcion:select", [this.filters]);
				}
			}
		};

		const datatable_small = {
			localId: "detalleDt",
			view: "datatable",
			rowHeight: 80, rowLineHeight: 35, header: false,
			columns: [
				{
					id: "inf", width:300,
					template: (row) => {
						row.lisId = this.getParam("lisId");
						return "<div>" + row.tot + " " + row.des + "</div>" +
							"<div class='flex-container'>" +
							(row.L ? this.buildEstadoHtml(row, "L") : "") +
							(row.R ? this.buildEstadoHtml(row, "R") : "") +
							(row.CM ? this.buildEstadoHtml(row, "CM") : "") +
							(row.AS ? this.buildEstadoHtml(row, "AS") : "") +
							(row.DI ? this.buildEstadoHtml(row, "DI") : "") +
							(row.RP ? this.buildEstadoHtml(row, "RP") : "") +
							(row.N ? this.buildEstadoHtml(row, "N") : "") +
							(row.E ? this.buildEstadoHtml(row, "E") : "") +
							(row.DS ? this.buildEstadoHtml(row, "DS") : "") +
							(row.DV ? this.buildEstadoHtml(row, "DV") : "") +
							(row.NE ? this.buildEstadoHtml(row, "NE") : "") +
							(row.EF ? this.buildEstadoHtml(row, "EF") : "") +
							"</div>";
					},
				}
			],
			data: detallesData,
			select: "cell",
			//scrollX: false,
			// on: {
			// 	onItemClick: cell => {
			// 		this.filters.desId = cell.row;
			// 		if (cell.column !== "tot" && cell.column !== "des") this.filters.estCod = cell.column;
			// 		this.app.callEvent("detallesxdescripcion:click", [this.filters]);
			// 	}
			// }
		};


		let rows = [];
		if (this._toolbarVisible) rows.push(toolbar);
		//TODO: Mejorar versión small teniendo en cuenta compatibilidad con Items sin salir del proceso de escaneo
		//rows.push((screen === "small") ? datatable_small : datatable);
		rows.push(datatable);

		return {
			rows: rows
		};
	}

	init(view) {
		this.on(this.app, "update", (filters, label) => {
			this.filters = filters;
			if (this._toolbarVisible) this.$$("headerLbl").setValue(label);
			if (filters.zon !== undefined) {
				this.$$("zonCmb").define("options", filters.zon.split(","));
				this.$$("zonCmb").setValue("");
				delete filters.zon;
			}
			this.updateInfo();
		});

		this.on(this.app, "detallesxdescripcion:change", data => {
			console.log("detallesxdescripcion:change", data);
			this.$$("detalleDt").define("data", data);
		});
	}

	updateInfo() {
		detallesData.clearAll();
		detallesData.load("server/items/detallesxdescripcion.php?" + filtersToQueryString(this.filters));
	}

	zonCmbChange(v) {
		this.filters.zon = v.join(",");
		this.updateInfo();
	}

	buildEstadoHtml(row, estCod){
		return "<a route='items.main?lisId=" + row.lisId + "&desId=" + row.id + "&estCod=" + estCod + "'><div class='estado-cant est" + estCod + "'>" + estCod + "<br>" + row[estCod] + "</div></a>";
	}

	getVisibilityColumn(estadoColumn){
		if(this._config.estado === undefined) return false;
		return !this._visibilityColumns[this._config.estado].includes(estadoColumn);
	}
}