import {JetView} from "webix-jet";
import Permisos from "views/permisos";

export default class Grupos extends JetView {

	config() {
		return {
			cols: [
				{
					type: "clean",
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Grupos"},
								{},
								{
									view: "button", type: "icon", icon: "wxi-plus", width: 37, css: "app_button",
									click() {
										const dt = this.$scope.$$("gruposLst");
										dt.editRow(dt.add({gru: "Nombre Grupo"}));
									}
								}
							]
						},
						{view: "text", localId: "filterTxt"},
						{
							view: "list",
							localId: "gruposLst",
							width: 300,
							select: true,
							template: "#gru#",
							// columns: [
							// 	{id: "id", header: "ID", width: 50},
							// 	{id: "gru", header: "Nombre", editor: "text", fillspace: true}
							// ],
							url: "server/grupos/list.php",
							// save: "server/grupos/save.php",
							// editable: true,
							// editaction: "dblclick",
							// updateFromResponse: true
						}
					]
				},
				{view: "resizer"},
				Permisos
			]
		};
	}

	init(view) {
		this.gruposLst = view.queryView({localId: "gruposLst"});
		this.filterTxt = view.queryView({localId: "filterTxt"});

		this.gruposLst.attachEvent("onSelectChange", () => this.gruposDtChange());
		this.filterTxt.attachEvent("onTimedKeyPress", () => {
			const txt = this.filterTxt.getValue();
			this.gruposLst.filter("#gru#", txt);
		});
	}

	gruposDtChange() {
		const gruId = this.gruposLst.getSelectedId(false);
		this.setParam("gruId", gruId, true);
	}

	urlChange(view, url) {
		this.gruposLst.waitData.then(
			() => {
				const gruId = this.getParam("gruId", false) || this.gruposLst.data.getFirstId();
				this.gruposLst.select(gruId, false);
			}
		);
	}
}