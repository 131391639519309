import {JetView} from "webix-jet";

export default class FichaReparto extends JetView {
	config() {
		return {
			minWidth: 250,
			maxWidth: 300,
			rows: [
				{
					view: "toolbar", elements:
						[
							{view: "label", label: "Muelle:"},
							{
								view: "richselect", localId: "mue",
								options: ["1.1", "1.2", "2.1", "2.2", "3"] /*, width: 60*/},
							// {view: "datepicker", timepicker: true, format: "%Y-%m-%d %H-%i", width: 150}
							{view: "datepicker", localId: "t_pro_ini", type: "time", stringResult: true, width: 100},
							{
								view: "menu", openAction: "click", width: 35, /*subMenuPos: "bottom",*/
								data: [{
									icon: "mdi mdi-dots-vertical",
									submenu: [
										{id: "descartarRep", value: "Reparto", icon: "minus-circle"},
										{id: "descartarRec", value: "Recursos Sel.", icon: "minus-circle"},
										{id: "descartarPed", value: "Pedidos Sel.", icon: "minus-circle"},
										// {$template:"Separator"}
									]
								}],
								on: {
									onMenuItemClick: id => {
										if (id === "impRutMen") {
											// this.uploadAPI.fileDialog({ rowid : id.row });
										}
									}
								}
							}
						]
				},
				{
					view: "datatable",
					localId: "recursosDt",
					header: false,
					// height: 150,
					minHeight: 50,
					autoheight: true,
					drag: true,
					columns: [
						{id: "nom"},
						{id: "value", fillspace: true}
					],
					on: {
						onAfterDrop: () => this.recursosDtAfterDrop(),
						onBeforeDrop: (context, ev) => {
							if (context.from.config.localId === "pedidosTt") return false;
							const recursosDt = this.$$("recursosDt");
							for (var i = 0; i < context.source.length; i++) {
								context.from.copy(context.source[i], context.start, recursosDt, webix.uid());
							}
							recursosDt.hideOverlay();
							return false;
						}
					}
				},
				{
					view: "datatable",
					localId: "pedidosDt",
					select: true,
					drag: true,
					minHeight: 50,
					autoheight: true,
					columns: [
						{id: "emp", header: "EMP", fillspace: true},
						{id: "zon", header: "ZON", fillspace: true},
						{id: "rutNor", header: "RUT", fillspace: true},
						{id: "p", header: "PED", fillspace: true},
						{id: "v", header: "m3", fillspace: true, format: webix.i18n.numberFormat}
					],
					on: {
						onAfterDrop: () => this.pedidosDtAfterDrop(),
						onBeforeDrop: (context, ev) => {
							if (context.from.config.localId === "conLst"
								|| context.from.config.localId === "vehLst"
								|| context.from.config.localId === "disLst"
								|| context.from.config.localId === "auxLst"
							) return false;
							this.$$("pedidosDt").hideOverlay();
							return true;
						}
					}
				}
			]
		};
	}

	init(view){
		this.id = webix.uid();
	}

	ready() {
		(this.$$("recursosDt")).showOverlay("Arrastrar Recursos aquí");
		(this.$$("pedidosDt")).showOverlay("Arrastrar Pedidos aquí");
	}

	recursosDtAfterDrop() {
		(this.$$("recursosDt")).hideOverlay();
	}

	pedidosDtAfterDrop() {
		const pedidosDt = this.$$("pedidosDt");
		pedidosDt.hideOverlay();

		let pedTot = 0;
		let volTot = 0.0;

		pedidosDt.data.each(item => {
			pedTot += item.p;
			volTot += parseFloat(item.v);
		});

		pedidosDt.config.columns[3].header = [
			{text: "PED", height: 20},
			{text: pedTot, height: 20}
		];
		pedidosDt.config.columns[4].header = [
			{text: "m3", height: 20},
			{text: webix.i18n.numberFormat(volTot), height: 20}
		];

		pedidosDt.refreshColumns();
	}

	getValues() {
		const t_pro_ini = this.$$("t_pro_ini").getValue();
		const men = this.$$("mue").getValue();

		const recursosDt = this.$$("recursosDt");
		const con = recursosDt.find(function (obj) {
			return typeof (obj.con_id) != "undefined";
		}, true);

		const veh = recursosDt.find(function (obj) {
			return typeof (obj.veh_id) != "undefined";
		}, true);

		const dis = recursosDt.find(function (obj) {
			return typeof (obj.dis_id) != "undefined";
		}, true);

		let aux = recursosDt.find(function (obj) {
			return typeof (obj.aux_id) != "undefined";
		}, false);

		aux = aux.flatMap(a => a.aux_id);

		return {
			id: this.id,
			t_pro_ini: t_pro_ini,
			men: men,
			con_id: con === null ? "" : con.con_id,
			veh_id: veh === null ? "" : veh.veh_id,
			dis_id: dis === null ? "" : dis.dis_id,
			aux_id: aux === null ? "" : aux.join(),

		};
	}
}