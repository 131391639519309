export default class ElementBase {

	constructor(params = {}) {
		this._ns = "http://www.w3.org/2000/svg";
		this._params = {};
		this._params.x = params.x || 0;
		this._params.y = params.y || 0;
		this._params.width = params.width || 48;
		this._params.height = params.height || 48;
		this._params.fill = params.fill || "#000";
		this._params.stroke = params.stroke || "";
	}

	setAttributes(_svg){
		for(let p in this._params){
			_svg.setAttribute(p, this._params[p]);
		}
	}
}