import {JetView} from "webix-jet";

export default class RepartoForm extends JetView {

	config() {
		return {
			rows: [
				{
					view: "form",
					localId: "repFrm",
					maxWidth: 300,
					elementsConfig: {
						labelPosition: "top",
					},
					elements: [
						// {
						// view: "flexlayout",
						// cols: [
						{
							cols: [
								{
									view: "datepicker", label: "Fecha - Hora",
									format: "%Y-%m-%d %H:%i",
									// width: 200,
									localId: "fecHorDp",
									timepicker: true,
									stringResult: true,
									suggest: {
										type: "calendar",
										body: {
											timepicker: true,
											minDate: new Date(new Date().setDate(new Date().getDate() - 1))
										}
									},
									on: {
										onChange(fechor) {
											this.$scope.fecHorDp(this.getValue());
										}
									}
								},
								{view: "counter", label: "Duración", width: 100, step: 5}
							]
						},
						{
							view: "segmented", multiview: true/*, minWidth: 300*/, /*value:1,*/
							localId: "mueSeg", name: "mueId", label: "Muelle",
							options: [
								{id: "1", value: "1"},
								{id: "2", value: "2"},
								{id: "3", value: "3"},
								{id: "4", value: "4"},
								{id: "5", value: "5"},
							]
						},
						{view: "combo", localId: "conCmb", name: "conId", label: "Conductor"},
						{view: "combo", localId: "vehCmb", name: "vehId", label: "Vehículo"},
						{view: "combo", localId: "disCmb", name: "disId", label: "Dispositivo"},
						{view: "multicombo", localId: "auxCmb", name: "aux", label: "Auxiliares", options: []},
						{view:"button", label:"Seleccionar Pedidos"},
						{
							view: "datatable",
							localId: "rutPedDt",
							columns: [
								{id: "id", header: "Ruta", width: 50},
								{id: "p", header: "Pedidos", width: 60},
								{id: "c", header: "Cajas", width: 60},
								{
									id: "v",
									header: "m<sup>3</sup>",
									width: 50,
									format: webix.i18n.numberFormat
								}
							]
						}
					]
					// 	}
					// ]
				}
			]
		};
	}

	init(view) {
		this.repFrm = view.queryView({localId: "repFrm"});
		this.conCmb = view.queryView({localId: "conCmb"});
		this.vehCmb = view.queryView({localId: "vehCmb"});
		this.disCmb = view.queryView({localId: "disCmb"});
		this.auxCmb = view.queryView({localId: "auxCmb"});
		this.rutPedDt = view.queryView({localId: "rutPedDt"});
	}

	setRutPed(data) {
		this.rutPedDt.clearAll();
		this.rutPedDt.define("data", data);
	}

	getRepartoValues(){
		return this.repFrm.getValues();
	}

	fecHorDp(fechor) {
		this.conCmb.define("options", "server/programacion/repartoConductores.php?fec=" + fechor);
		this.vehCmb.define("options", "server/programacion/repartoVehiculos.php?fec=" + fechor);
		this.disCmb.define("options", "server/programacion/repartoDispositivos.php?fec=" + fechor);
		this.auxCmb.define("options", "server/programacion/repartoAuxiliares.php?fec=" + fechor);
	}
}