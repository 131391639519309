import {JetView} from "webix-jet";
import RutaSugerida from "views/preruteo/rutaSugerida";


export default class PreRuteoForm extends JetView {
	config() {
		return {
			rows: [
				{
					view: "form",
					elementsConfig: {
						labelPosition: "top"
					},
					elements: [
						{
							template: "<div class='ruta_sec'><div class='ruta'>#rut_act#</div>#sec_act#</div>" +
								"<strong>#rep#</strong><br>" +
								"Código: <strong>#rep_cod#</strong><br>" +
								"Ciudad: <strong>#ciu#</strong><br>" +
								"Zona: <strong>#zon#</strong>  Sección: <strong>#scc#</strong><br>" +
								"Dirección: <strong>#dir#</strong><br>" +
								"Barrio: <strong>#bar#</strong><br>" +
								"Referencia: <strong>#ref#</strong>",
							localId: "repInfo",
							height: 150,
							data: {rut_act: "", sec_act: "", rep: "", rep_cod: "", ciu: "", zon: "", dir: "", bar: ""}
						},
					]
				},
				{
					view: "tabview", fitBiggest: true,
					cells: [
						{
							header: "Ruta Secuencia",
							body: {
								id: "rutasecuencia",
								view: "form",
								elementsConfig: {
									labelWidth: 100
								},
								elements: [
									{
										view: "text",
										name: "rut_nor",
										label: "Ruta",
									},
									{
										view: "counter",
										name: "sec_nor",
										label: "Secuencia",
										min: 0
									},
									{
										view: "counter",
										name: "sec_inc",
										label: "Incremento",
										min: 0
									},
									{
										view: "counter",
										name: "caj_x_estiba",
										label: "Cajas x Estiba",
										value: 25,
										min: 0
									},
									{
										view: "button",
										label: "Asignar",
										icon: "mdi mdi-content-save",
										click: () => this.asignarClick()
									},
									{template: "Rutas Sugeridas", type: "section"},
									RutaSugerida
								]
							}
						},
						{
							header: "Correción Dirección",
							body: {
								id: "ubicacion_req",
								view: "form",
								elementsConfig:
									{
										attributes:
											{
												autocomplete: "disabled"
											}
									},
								elements: [
									{view: "text", label: "Zona", name: "zon"},
									{view: "text", label: "Sección", name: "scc"},
									{view: "text", label: "Dirección", name: "dir"},
									{view: "text", label: "Barrio", name: "bar"},
									{view: "text", label: "Ciudad", name: "ciu"},
									{view: "text", label: "Teléfono", name: "tel"},
									{view: "text", label: "Referencia", name: "ref"},
									{
										cols: [
											{
												view: "button", value: "Eliminar CD.", css:"webix_danger",
												click: ()=>{
													webix.confirm("¿Eliminar Corrección de Dirección?", "confirm-error")
														.then(result => {
															console.log(result);
															this.eliminarCDClick();
														});
												}
											},
											{
												view: "button", value: "Asignar",
												click: () => this.corregirDirClick()
											}
										]
									}
								]
							}
						}
					]
				}
			]
		};
	}


	init(view) {
		this.codTxt = view.queryView({localId: "codTxt"});
		this.on(this.app, "ruteo:select", (data) => {
			this.$$("repInfo").parse(data);
			if(data.es_cd) {
				this.$$("ubicacion_req").setValues(data);
			}  else {
				this.$$("ubicacion_req").clear();
			}
		});
		this.on(this.app, "rutasugerida:change", (ruta) => {
			this.$$("rutasecuencia").setValues({rut_nor: ruta});
		});
	}

	/*asignarClick() {
		const data = this.$$("rutasecuencia").getValues();
		webix.ajax().post("server/preruteo/setRutaSecuencia.php", data)
			.then(
				response => webix.msg(response.json())
			);
	}*/

	asignarClick() {
		const values = this.$$("rutasecuencia").getValues();
		//this.app.callEvent("rutasecuencia:change", [values]);
		this.getParentView().asignarRutaSecuencia(values);
	}

	corregirDirClick() {
		let values = this.$$("ubicacion_req").getValues();
		values.codType = 3; //TODO: Obtener de la selección del usuario
		webix.ajax().post("server/preruteo/corregir_dir.php", values)
			.then( res => webix.message(res.json().msg));
	}

	eliminarCDClick() {
		let values = this.$$("ubicacion_req").getValues();
		values.codType = 3;
		webix.ajax().post("server/preruteo/eliminar_cd.php", values)
			.then( result => {
				this.$$("ubicacion_req").clear();
			});
	}

}