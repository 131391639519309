import {JetView} from "webix-jet";
import session from "../models/session";
import ChangePasswordWindow from "jet-views/ChangePasswordWindow";

export default class ToolView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const theme = this.app.config.theme;
		const user = this.app.getService("user").getUser();
		//SUCURSAL ACTIVA (URL > SESSION > USUARIO)
		this.sucId = this.getParam("sucId", true);
		if (!this.sucId) this.sucId = webix.storage.session.get("sucId");
		if (!this.sucId) this.sucId = user.suc[0].id;
		webix.storage.session.put("sucId", this.sucId);
		this.setParam("sucId", this.sucId, true);

		const menuToggleBtn = {
			paddingY: 7,
			rows: [
				{
					view: "icon", icon: "mdi mdi-menu",
					click: () => this.app.callEvent("menu:toggle")
				}
			]
		};

		const glbSucSel = {
			view: "richselect", localId: "glbSuc", /*label: "Sucursal", labelPosition: "top",*/
			value: this.sucId,
			// gravity: 2,
			minWidth: 40,
			maxWidth: 150,
			// options: {
			// 	popupWidth: 300,
			// 	// template: "#cod#", body: {template: "#suc#"},
			// 	// template: "#suc#",
			// 	url: "server/sucursales/list.php"
			// }
			//options: "server/sucursales/options.php",
			options: {
				view: "suggest", // optional
				//filter:function(item, value){ ...},
				body: { // list configuration
					view: "list", // optional
					data: user.suc,
					template: "#suc#",
					yCount: 7
				}
			},
			on: {
				onChange: (opt) => {
					this.getParentView().setParam("sucId", this.$$("glbSuc").getValue(), true);
					webix.storage.session.put("sucId", opt);
					this.app.callEvent("sucId:change", [opt]);
				}
			}
		};

		const cliSel = {
			view: "multicombo", localId: "glbEmp", /*label: "Empresas", labelPosition: "top",*/ value: "1,2",
			minWidth: 40,
			maxWidth: 120,
			options: {
				template: "#emp#", body: {template: "#emp#"},
				url: "server/empresas/list.php"
			},
			tagMode: false,
			on: {
				onChange: () => this.getParentView().setParam("empId", this.$$("glbEmp").getValue(), true)
			}
		};

		const fullScreenBtn = {
			view: "icon",
			icon: "mdi mdi-arrow-expand-all",
			click() {
				// document.body.webkitRequestFullScreen();
				// $$("$layout1").$view.webkitRequestFullScreen();

				const doc = window.document;
				const docEl = doc.documentElement;

				const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
				const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

				if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
					requestFullScreen.call(docEl);
					//Se desactivó el llamado a la función porque en modo fullScreen los
					// valores para document.body.offsetWidth se invertían :/
					//webix.ui.fullScreen();
				} else {
					cancelFullScreen.call(doc);
				}
			}
		};

		const chatBtn = {
			view: "icon", icon: "mdi mdi-chat", localId: "chatIcon",
			click: () => {
				//const chatFrame = document.getElementById("chatFrame"); //No es necesario, se puede llamar directamente por el ID del iframe
				if (chatFrame && chatFrame.contentDocument && chatFrame.contentDocument.getElementsByClassName("sidebar").length > 0) {
					chatFrame.contentDocument.getElementsByClassName("sidebar")[0].style.transform = "translate3d(0px, 0px, 0px)";
					chatFrame.contentDocument.getElementsByClassName("sidebar")[0].style.boxShadow = "rgba(0, 0, 0, 0.3) 0px 0px 15px 1px";
					chatFrame.contentDocument.getElementsByClassName("sidebar-wrap")[0].style.width = "100%";
					chatFrame.contentDocument.getElementsByClassName("sidebar-wrap")[0].style.backgroundColor = "rgb(0,0,0)";
				}
				const width = $$("chatView").$width;
				$$("chatView").define("width", (width === 60) ? 380 : 60);
				$$("chatView").resize();
			}
		};

		const userBtn = {
			view: "menu", openAction: "click", width: 40,
			tooltip: "Usuario",
			submenuConfig: {
				width: 200,
				//template:"SUB #value#"
			},
			data: [{
				icon: "mdi mdi-account",
				submenu: [
					{id: "profile", value: user.name, icon: "mdi mdi-account"},
					{id: "changePass", value: "Cambiar Contraseña", icon: "mdi mdi-form-textbox-password"},
					{$template: "Separator"},
					{id: "logout", value: "Cerrar sesión", icon: "mdi mdi-logout"},
				]
			}],
			on: {
				onMenuItemClick: id => {
					if (id === "logout") {
						session.logout();
					} else if (id === "changePass") {
						this.changePasswordWin.showWindow();
					}
				}
			}
		};

		return {
			view: "toolbar", css: theme,
			height: 40,
			elements: (this.app.config.withChat) ? [menuToggleBtn, {}, glbSucSel, cliSel, fullScreenBtn, chatBtn] : [menuToggleBtn, {}, glbSucSel, cliSel, fullScreenBtn, userBtn]
		};
	}

	init(view) {
		this.on(this.app, "chat:unreadchanged", d => {
			this.$$("chatIcon").config.badge = d;
			this.$$("chatIcon").refresh();
		});

		this.changePasswordWin = this.ui(ChangePasswordWindow);

		// this.notifications = this.ui(NotificationView);
		//
		// this.on(this.app,"read:notifications",() => {
		// 	this.$$("bell").config.badge = 0;
		// 	this.$$("bell").refresh();
		//
		// 	setTimeout(() => {
		// 		if (this.app){
		// 			this.$$("bell").config.badge += 1;
		// 			this.$$("bell").refresh();
		// 			this.app.callEvent("new:notification");
		// 		}
		// 	},10000);
		// });
		//
		// const user = this.app.getService("user").getUser();
		// this.$$("glbSuc").define("options", {
		// 	template: "#cod#",
		// 	body: {template: "#suc#"},
		// 	data: user.suc
		// });

	}

	urlChange() {
		console.log("TOOLBAR urlChange");
	}

}
