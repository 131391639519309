import {JetView} from "webix-jet";

export default class InfoGeneral extends JetView {
	config() {
		return {
			localId: "generalDt",
			header: "General",
			view: "datatable",
			columns: [
				{id: "l", header: "L", width: 50, css: {"text-align": "right"}},
				{id: "des", header: "DESCRIPCIÓN", fillspace: true, css: {"text-align": "center"}},
				{id: "r", header: "R", width: 50, css: {"text-align": "right"}},
				{id: "DM", header: "DM", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "AI", header: "AI", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "D", header: "D", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "N", header: "N", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "E", header: "E", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "LG", header: "LG", width: 50, css: {"text-align": "right"}, hidden: true},
				{id: "DS", header: "DS", width: 50, css: {"text-align": "right"}, hidden: true},
			],
			select: "cell",
			scrollX: false,
			// on: {
			// 	onSelectChange: () => this.generalDtSelectChange()
			// }
		};
	}

	init(view) {
		this.on(this.app, "items:general:data", data => this.infoRefresh(data));
	}

	infoRefresh(data){
		this.$$("generalDt").define("data", data);
	}

}