import {JetView} from "webix-jet";

export default class List extends JetView{
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "No Entregado"},
						{},
						{
							view: "icon", icon: "mdi mdi-plus",
							click: () => {
								this.show("novedad.form?rprId=0");
							}
						}
					]
				},
				{
					view: "list",
					template: "<strong>#con# - #pla#</strong><br>Dispositivo: #rid# - #dis#<br>Muelle: #mue#<br>" +
						"TDS: #tra# - Carga:#crg_d#",
					url: "server/asignacion/repartos.php?XDEBUG_SESSION_START=12345",
					type: {
						height: 100
					},
					on: {
						onItemClick: id => {
							this.show("novedad.escaneo?rprId=" + id);
						}
					}
				}
			]
		};
	}
}