import {JetView} from "webix-jet";

export default class Workflow extends JetView {

	config() {
		return {
			rows: [
				{
					view: "button", label: "Guardar",
					click: () => {
						let workflow = [];
						const ignoreCols = ["id", "cod", "evn"];
						this.$$("workflowDt").data.each(evento => {
							for (const destino in evento) {
								if (!ignoreCols.includes(destino) && evento[destino])
									workflow.push([evento.id, parseInt(destino, 10)]);
							}
						});
						console.log(JSON.stringify(workflow));
						webix.ajax().post("server/workflow/save.php", {workflow})
							.then(response => {
								response = response.json();
								webix.message(response.msg);
							});
					}
				},
				{
					view: "datatable",
					localId: "workflowDt",
					leftSplit: 2, tooltip: true, hover: "workflowRowHover",
					columns: [],
				}
			]
		};
	}

	ready() {
		webix.ajax("server/workflow/get.php")
			.then(response => {
				const data = response.json().data;
				this.$$("workflowDt").config.columns = this.buildColumns(data);
				this.$$("workflowDt").refreshColumns();
				this.$$("workflowDt").define("data", data);
			});
	}

	buildColumns(data) {
		let columns = [];
		columns.push({id: "cod", header: "ID", width: 40});
		columns.push({id: "evn", header: "Evento", width: 220});
		for (const item of data) {
			columns.push({
				id: item.id,
				header: item.cod,
				template: "{common.checkbox()}",
				tooltip: item.cod + " " + item.evn,
				width: 40
			});
		}
		return columns;
	}
}