import {JetView} from "webix-jet";
import {getEstados} from "../models/estados";

export default class Estados extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Estados"},
						{},
						{
							view: "button", type: "icon", icon: "wxi-plus", width: 37, css: "app_button",
							// click() {
							// 	const dt = this.$scope.$$("estados_dt");
							// 	dt.editRow(dt.add({pla: "ABC123"}));
							// }
						}
					]
				},
				{
					view: "datatable",
					id: "estados_dt",
					columns: [
						{id: "id", header: "ID", width: 50},
						{id: "cod", header: "Cód.", editor: "text", width: 50},
						{id: "est", header: "Nombre", editor: "text", width: 200},
						/*{
							id: "req",
							header: "Estado Previo Requerido",
							optionslist: true,
							editor: "multiselect",
							fillspace: true,
							options: [
								{id: "", value: "Ninguno"},
								{id: "L", value: "Listado"},
								{id: "F", value: "Faltante"},
								{id: "R", value: "Recepción"},
								{id: "AV", value: "Averiada"},
								{id: "CM", value: "Carga Masiva"},
								{id: "DI", value: "Distribución Interna"},
								{id: "AS", value: "Asignación"},
								{id: "RP", value: "Reparto"},
								{id: "N", value: "No Entregado"},
								{id: "E", value: "Entregado"},
								{id: "LG", value: "Legalización"},
								{id: "DS", value: "Desmantelado"},
								{id: "DV", value: "Devolución"},
								{id: "NE", value: "No Efectivo"},
								{id: "EF", value: "Efectivo"}
							]
						}*/

					],
					url: "server/estados/list.php",
					save: "server/estados/save.php",
					editable: true,
					// editaction: "dblclick",
					updateFromResponse: true
				}
			]
		};
	}
}