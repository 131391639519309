export const estados = new webix.DataCollection({
	url: "server/estados/list.php"
});

export function getEstados() {
	let estados_array = [];
	return estados.waitData.then(() => {
		estados.data.each(obj => {
			estados_array.push({id: obj.id, value: obj.value, col: obj.col, cod: obj.id, req: obj.req});
		});

		return estados_array;
	});
}

export function getEstadosByCod() {
	let estByCod = {};
	return estados.waitData.then(() => {
		estados.data.each(obj => {
			estByCod[obj.cod] = {id: obj.id, value: obj.value, col: obj.col, req: obj.req};
		});

		return estByCod;
	});
}