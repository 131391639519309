import {JetView} from "webix-jet";

export default class RepartoMap extends JetView {
	config() {
		return {
			template: "<div id='repartoMap' style='width: 100%; height: 100%'></div>"
		};
	}

	init() {
		// var marker = L.marker([51.5, -0.09]).addTo(mymap);
		// marker.bindPopup("<b>Hello world!</b><br>I am a popup.");

		var vehIcon = new L.Icon({
			iconUrl: "https://tdsx.app/library/img/truck_2x.png",
			shadowUrl: "https://tdsx.app/library/img/truck_2x_shadow.png",
			iconSize: [40, 40],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41]
		});


		const entregado = L.layerGroup();
		const no_entregado = L.layerGroup();
		webix.ajax().sync().get("server/repartos/tracking.php", null,
			(raw, res) => {
				const ubc = res.json();
				ubc.forEach(
					u => {
						const popupStr = u.rid + "<br>" + u.nom + "<br>" + u.i + "<br>" +
						 "<a href='#'>" + u.i + "</a>" + u.i + "ÍTEMS: " + u.i_e + "<br>" +
						"PEDIDOS:";
						// if (u.est_id == 7) {
						// 	L.marker([u.lat, u.lon], {icon: vehIcon}).bindPopup(popupStr).addTo(no_entregado);
						// } else if (u.est_id == 8) {
						// 	L.marker([u.lat, u.lon], {icon: vehIcon}).bindPopup(popupStr).addTo(entregado);
						//}
						L.marker([u.lat, u.lon], {icon: vehIcon}).bindPopup(popupStr).addTo(no_entregado);
					}
				);
			}
		);

		let trayecto;
		webix.ajax().sync().get("server/repartos/getPositions.php", {rpr_id: this.getParam("rprId")},
			(raw, res) => {
				const ubc = res.json();
				var latlngs = [];
				ubc.forEach(
					u => latlngs.push([u.lat, u.lon])
				);
				trayecto = L.polyline(latlngs, {color: "red"});
			}
		);

		const mapbox = L.tileLayer("https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}", {
			id: "mapbox.streets",
			attribution: "Map data &copy; <a href=\"https://www.openstreetmap.org/\">OpenStreetMap</a> contributors, <a href=\"https://creativecommons.org/licenses/by-sa/2.0/\">CC-BY-SA</a>, Imagery © <a href=\"https://www.mapbox.com/\">Mapbox</a>",
			maxZoom: 20,
			accessToken: "pk.eyJ1IjoibGpwYXJyYSIsImEiOiJjangzYXhja3QwMDNtNGFxbWxzZmhvbm1kIn0.b-QP-dt95NPY7d9z4yZLqw"
		});

		const osm = L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
			attribution: "Map data &copy; <a href=\"https://www.openstreetmap.org/\">OpenStreetMap</a> contributors, <a href=\"https://creativecommons.org/licenses/by-sa/2.0/\">CC-BY-SA</a>, Imagery © <a href=\"https://www.mapbox.com/\">Mapbox</a>",
			maxZoom: 19
		});
		const Esri_WorldImagery = L.tileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", {
			attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
			maxZoom: 19
		});

		const Thunderforest_TransportDark = L.tileLayer("https://{s}.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey={apikey}", {
			attribution: "&copy; <a href=\"http://www.thunderforest.com/\">Thunderforest</a>, &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors",
			apikey: "535e352c315a423496b3bd6a5c65b16b",
			maxZoom: 22
		});

		const map = L.map("repartoMap", {
			center: [7.12, -73.11],
			zoom: 10,
			layers: [mapbox, no_entregado, entregado]
		});

		const baseLayers = {
			"MapBox": mapbox,
			"OpenStreetMap": osm,
			"Esri Satellite": Esri_WorldImagery,
			"Thunderforest Dark": Thunderforest_TransportDark
		};

		const overlays = {
			"No Entregado": no_entregado,
			"Entregado": entregado,
			"Trayecto": trayecto
		};

		L.control.layers(baseLayers, overlays).addTo(map);
		//map.fitBounds(trayecto.getBounds());

	}
}