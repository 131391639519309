import {JetView} from "webix-jet";
import {estados} from "models/estados";
import {getTiposAveria} from "models/tiposaveria";
import {getCorrectivos} from "models/correctivos";

export default class form extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Cajas Averiadas"},
						{
							view: "icon", localId: "saveBtn",
							icon: "mdi mdi-content-save", tooltip: "Guardar",
							click: () => this.$$("ca_uploader").send( response => {
								webix.message(response.status);
								this.$$("averiadasFrm").clear();
								this.clearCanvas();
							})
						}
					]
				},
				{
					view: "form",
					localId: "averiadasFrm",
					elementsConfig: {
						labelPosition: "top"
					},
					elements: [
						// {
						// 	view: "richselect", localId: "proCmb", name: "pro_id", label: "Proceso" //, options: estados
						// },
						// {
						// 	view: "richselect", localId: "ave_id", name: "ave_id", label: "Tipo Avería", options: getTiposAveria()
						// },
						{
							view: "segmented", localId: "tipAveSgm", label: "Tipo Avería", options: getTiposAveria()
						},
						// {
						// 	view: "richselect", name: "cor_id", label: "Correctivo", options: getCorrectivos()
						// },
						{
							view: "segmented", localId: "corSgm", label: "Correctivo", options: getCorrectivos()
						},
						{
							view: "text", localId: "codigoTxt", name: "cod", label: "Código",
							on: {
								onChange: (value) => {
									console.log("ON CHANGE");
									this.$$("cajAveUpl").define("disabled", value === "");
								}
							}
						},
						{
							view: "uploader",
							id: "cajAveUpl", localId: "ca_uploader",
							value: "Foto",
							disabled: true,
							//link: "cajAveLst",
							//link: "mytemplate",
							upload: "server/cajasaveriadas/upload.php",
							autosend: false,
							on: {
								onBeforeFileAdd: item => this.fileAdded(item)
							}
						},
						// {
						// 	id: "mytemplate",
						// 	// autoheight:true,
						// 	height: 50,
						// 	template: function (data) {
						// 		console.log(data);
						// 		var names = [];
						// 		var reader = new FileReader();
						//
						// 		reader.addEventListener("load", function () {
						// 			var img = document.querySelector("#cajaAveriada_01");
						// 			img.src = reader.result;
						// 		}, false);
						//
						// 		if (data.each)
						// 			data.each(function (obj) {
						// 				reader.readAsDataURL(obj.file);
						// 				names.push(obj.name); /*console.log(obj);*/
						// 			});
						// 		// return names.join(", ");
						// 		return "<img id='cajaAveriada_01' src='' height='100' alt='Image preview...'>";
						// 	},
						// 	borderless: true
						// },
						{
							view: "list",
							id: "cajAveLst",
							type: "uploader",
							autoheight: true,
							borderless: true
						},
						{
							template: "<canvas id='ca_canvas' width='600' height='600'></canvas>",
							height: 600,
							borderless: true
						}
					]
				}
			]
		};
	}

	init() {
		// const proCmb = this.$$("proCmb");
		// TODO: Filtrar solo los procesos relevantes con Cajas Averiadas
		// proCmb.define("options", estados);
		// proCmb.getPopup().getList().parse(estados);
	}

	fileAdded(item) {
		let data = this.$$("averiadasFrm").getValues();
		//data.cod = this.$$("codigoTxt").getValue().trim().split("\n").pop();
		data.cod = this.$$("codigoTxt").getValue().trim();
		item.formData = data;
		console.log("item.file:", item.file);
		var can = document.getElementById("ca_canvas");
		var ctx = can.getContext("2d");
		var img = new Image();
		var reader = new FileReader();
		reader.onload = (evt) => {
			console.log("READER.ONLOAD");
			img.src = evt.target.result;
			img.onload = () => {
				ctx.drawImage(img, 0, 0, 600, 600);

				var img2 = new Image();
				//img2.crossOrigin = "Anonymous";
				img2.onload = () => {
					// ctx.fillStyle = "rgba(255, 255, 255, 0.6)";
					// ctx.fillRect(0, 550, 600, 50);
					ctx.drawImage(img2, 0, 550, 600, 50);
					ctx.fillStyle = "black";
					//TODO: Solo es seguro obtener el Label de esta forma si los valores están en orden en la fuente
					const averia = getTiposAveria()[this.$$("tipAveSgm").getValue() - 1].value.toUpperCase();
					const correctivo = getCorrectivos()[this.$$("corSgm").getValue() - 1].value.toUpperCase();
					ctx.font = "20px Arial";
					ctx.fillText(data.cod, 100, 570);
					ctx.font = "10px Arial";
					ctx.fillText(averia + " / " + correctivo, 100, 582);
					//Fecha
					ctx.font = "10px Arial";
					//ctx.fillText(new Date().toLocaleString("es"), 100, 595);
					ctx.fillText(new Date().toISOString(), 100, 595);

					can.toBlob(blob => {
						console.log("CANVAS TO BLOB");
						item.file = blob;
					}, "image/jpeg", 0.9);
				};
				//TODO: md5_runbox -> variable de app
				img2.src = "media/md5_runbox/caj_ave_footer.png";
			};
		};
		reader.readAsDataURL(item.file);
	}

	clearCanvas(){
		var canvas = document.getElementById("ca_canvas");
		const context = canvas.getContext('2d');
		context.clearRect(0, 0, canvas.width, canvas.height);
	}
}