import {JetView} from "webix-jet";
import {estados} from "models/estados";
import {clearData, getItems} from "models/items";
import {buildTags} from "models/etiquetas";
import {download} from "../../libs/utils";


export default class ItemsList extends JetView {
	config() {
		const screen = this.app.config.size;

		const toolbar_small = {
			view: "toolbar", elements: [
				{
					view: "icon", icon: "mdi mdi-arrow-left",
					click: () => {
						console.log("ItemsList / Back click");
					}
				},
				{view: "label", label: "Items"},
				{
					view: "icon",
					localId: "exportBtn",
					icon: "mdi mdi-file-download",
					click: () => {
						this.exportarArchivo();
					}
				},
				{
					view: "icon",
					localId: "clipboardBtn",
					icon: "mdi mdi-clipboard-text",
					click: () => {
						this.getValues();
					}
				},
				{
					view: "icon",
					icon: "mdi mdi-arrow-right-bold-box",
					tooltip: "Listado -> Recepción"
				},
				{
					view: "menu", openAction: "click", width: 35, /*subMenuPos: "bottom",*/
					data: [{
						icon: "mdi mdi-dots-vertical",
						submenu: [
							{id: "ordT", value: "Ordenar x Tiempo", icon: "mdi mdi-upload"},
							{id: "ordRS", value: "Ordenar x Ruta-Secuencia", icon: "mdi mdi-download"},
							// {$template:"Separator"}
						]
					}],
					on: {
						onMenuItemClick: id => {
							if (id === "ordT") {
								$$("itemsLst").sort({by: "t", dir: "desc", as: "int"});
							} else if (id === "ordRS") {
								$$("itemsLst").sort({by: "rutNor", dir: "asc"});
							}
						}
					}
				}

			]
		};

		const toolbar_wide = {
			view: "toolbar", elements: [
				{view: "label", label: "Items"},
				{
					view: "icon",
					localId: "exportBtn",
					icon: "mdi mdi-file-download",
					tooltip: "Exportar .txt",
					click: () => {
						this.exportarArchivo();
					}
				},
				{
					view: "icon",
					localId: "clipboardBtn",
					icon: "mdi mdi-clipboard-text",
					click: () => {
						this.getValues();
					}
				},
				{
					view: "icon",
					icon: "mdi mdi-arrow-right-bold-box",
					tooltip: "Listado -> Recepción"
				}
			]
		};

		return {
			rows: [
				screen === "small" ? toolbar_small : toolbar_wide,
				{
					view: "search", localId: "filterTxt",
					on: {
						onTimedKeyPress: () => this.filterItemsLst()
					}
				},
				{
					//view: "list",
					view: "datatable",
					id: "itemsLst",
					localId: "itemsLst",
					gravity: 1,
					header: false,
					rowHeight: 90,
					columns: [
						{
							template(obj) {
								obj.rutEsc = obj.rutEsc || "";
								//console.log(obj.id, obj.tags);
								const tags = buildTags(obj.tags);
								//obj.err = obj.err || "";
								//console.log("obj.estCod:", obj.estCod);
								//obj.$css = "est" + obj.estCod;
								return "<div class='ruta_sec'>" +
									"<div class='ruta'>" + obj.rutNor + "</div>" +
									"<div class='secuencia'>" + obj.secNor + "</div>" +
									"</div>" +
									//"<div class='estado' style='color:white; background-color:#" + estados.getItem(obj.estCod).col + "'>"
									"<div class='estado est" + obj.estCod + "_header' >"
									+ obj.estCod
									+ "</div>" +
									"<div class='caj_info'>" +
									"<strong>" + obj.rep + "</strong><br>" +
									obj.ped + " " + obj.id + " Nº" + obj.cajNum + "<br>" +
									obj.tam + " " + obj.des + "<br>" +
									obj.ciu + "/" + obj.bar + "<br>" +
									//"FH:" + obj.t + "<br>" +
									(obj.err ? "<span style='color:white; background-color: red; padding: 2px;'>" + obj.err + "</span>" : "") +
									//"<span style='color:white; padding: 2px; font-size: xx-small; font-weight: bold; background-color: #" + obj.tag_color + "'>" + obj.tag + "</span>" +
									tags +
									"</div>";
							},
							fillspace: true
						}
					],
					select: true,
					on: {
						"data->onAfterFilter": () => this.updateStatusTpl(),
						onSelectChange: id => this.filterPedido()
					}
				},
				{
					view: "template", localId: "statusTpl", template: "#c# Ítem(s)", height: 25
				},
			]
		};
	}

	init() {
		console.log("ITEMS INIT");
		this.itemsLst = this.$$("itemsLst");
		this.on(this.app, "items:load", filter => this.setData(filter));
		this.on(this.app, "detallesxdescripcion:select", filter => this.filter(filter));
		this.on(this.app, "escaneo:codigo", (data) => this.procesarCodigo(data));
		this.on(this.app, "item:change", (data) => this.procesarCodigo(data));
		/*this.socket = io("https://n1.tdsx.app");
		this.socket.on("connect", () => {
			console.log("ON CONNECT - socket.id:" + this.socket.id);
		});
		this.socket.on("item-change", data => {
			console.log("ITEM-CHANGE", data);
			if(this.itemsLst.exists(data.cod)) {
				this.itemsLst.updateItem(data.cod, data);
				this.itemsLst.sort("t", "desc", "int");
				this.updateDetallesXDescripcion();
			}
		});*/
		/*webix.attachEvent("test", (...params) => {
			console.log("test event from common/items", params);
		});*/
	}

	procesarCodigo(data) {
		console.log("common/items procesarCodigo", data);
		if(!this.itemsLst.exists(data.cod)) return;
		this.itemsLst.updateItem(data.cod, data);
		// ORDENAR DE MAYOR A MENOR SEGÚN TIEMPO RECEPCIÓN
		//move funciona pero cuando hay un filtro en un dispositivo
		//se genera error si desde otro se está escaneando y se intenta
		//mover al principio un ítem que no se muestre después de filtrar
		//this.itemsLst.moveTop(data.cod);
		//Sort funciona correctamente aunque esté filtrado, se desconoce
		//cuál de las dos funcione ejecuta más rápido
		this.itemsLst.sort("t", "desc", "int");

		//const item = this.itemsLst.getItem(data.cod);
		//data.itmId = item.itmId;
		//data._c = document.cookie;
		//if(typeof this.socket !== "undefined") {
		//console.log("SOCKET EMIT", data);
		//this.socket.emit("escaneo", data);
		//this.app.callEvent("escaneo:emit", [data]);
		//}
		this.updateDetallesXDescripcion();
	}

	filterPedido() {
		const id = this.itemsLst.getSelectedId();
		if (!id) return;
		const item = this.itemsLst.getItem(id);
		const itemsPedido = this.itemsLst.find(obj => obj.ped === item.ped);
		this.app.callEvent("items:pedido:data", [item, itemsPedido]);
	}

	filter(filter) {
		this.$$("itemsLst").filter(obj => {
			return (obj.desId === filter.desId && (obj.estCod === filter.estCod || filter.estCod === "tot" || filter.estCod === "des"));
		});
	}

	setData(filter) {
		//this.itemsLst.clearAll();
		clearData();
		const data = getItems(filter);
		this.itemsLst.parse(data);
		data.waitData.then(() => {
			this.updateDetallesXDescripcion();
			this.updateStatusTpl(data.count);
		});
	}

	updateDetallesXDescripcion() {
		let detalles = new Map();
		this.itemsLst.data.each(itm => {
			if (!detalles.has(itm.desId)) detalles.set(itm.desId, {id: itm.desId, des: itm.des, tot: 0});
			if (detalles.get(itm.desId)[itm.estCod] === undefined) detalles.get(itm.desId)[itm.estCod] = 0;
			detalles.get(itm.desId)[itm.estCod]++;
			detalles.get(itm.desId).tot++;
		}, null, true);
		let detallesArray = [];
		for (const v of detalles.values()) detallesArray.push(v);
		this.app.callEvent("detallesxdescripcion:change", [detallesArray]);
	}

	filterItemsLst() {
		const txt = this.$$("filterTxt").getValue().toUpperCase();
		if (txt.length === 0) {
			this.$$("itemsLst").filter(null, null);
			return;
		}

		this.$$("itemsLst").filter(obj => {
			if (txt.length <= 2) {
				return obj.rut_nor === txt;
			} else {
				return obj.rep.toString().indexOf(txt) !== -1
					|| obj.id.toString().indexOf(txt) !== -1
					|| obj.ciu.toString().indexOf(txt) !== -1
					|| obj.bar.toString().indexOf(txt) !== -1
					|| obj.zon.toString() === txt;
			}
		}, null);
	}

	updateStatusTpl() {
		this.$$("statusTpl").setValues({c: this.itemsLst.count()});
	}

	getValues() {
		let resultados = "";

		this.itemsLst.data.each(itm => {
			resultados += itm.id + "\n";
		});

		console.log(resultados);
		navigator.permissions.query({name: "clipboard-write"})
			.then(result => {
				if (result.state === "granted" || result.state === "prompt") {
					navigator.clipboard.writeText(resultados);
					webix.message("Resultados copiados al portapapeles");
				}
			});
	}

	exportarArchivo() {
		var lineas = "CODIGOS\r\n";
		this.itemsLst.data.each(i => lineas += i.id + "\r\n");
		lineas = lineas.substring(0, lineas.length - 2);
		download((new Date()).getTime() + ".txt", lineas);
	}
}