import ElementBase from "jet-views/obt/ElementBase";

export default class Box extends ElementBase {

	constructor(params = {}) {
		super(params);
	}

	build(){
		// if (params.id) this._svg.setAttribute("id", params.id);
		this._svg = document.createElementNS(this._ns, "g");
		let rect = document.createElementNS(this._ns, "rect");
		rect.setAttributeNS(null,"width", this._params.width);
		rect.setAttributeNS(null,"height", this._params.height);
		rect.setAttributeNS(null,"fill", this._params.fill);
		rect.setAttributeNS(null,"stroke", this._params.stroke);
		rect.setAttributeNS(null,"stroke-width", this._params.strokeWidth);

		// this._svg.setAttribute("x", this._params.x);
		// this._svg.setAttribute("y", this._params.y);
		this._svg.setAttribute("transform", "translate(" + this._params.x + "," + this._params.y + ")");
		this._svg.appendChild(rect);
		return this._svg;
	}


}