import {JetView} from "webix-jet";

export default class MarcarBoletasWindow extends JetView {
	config() {
		return {
			view: "window",
			modal: true,
			//close: true,
			position: "center",
			//fullscreen: true,
			//width: 400,
			//height: "auto",
			head: {
				view: "toolbar", elements: [
					//{template: "<div class='webix_header'>Marcar Boletas</div>"},
					{template: "Marcar Boletas", type: "header"},
					{
						view: "icon", localId: "closeBtn", icon: "wxi-close",
						click: () => {
							this.getRoot().hide();
						}
					}
				]
			},
			body: {
				view: "form", localId: "formView",
				elementsConfig: {
					labelWidth: 150
				},
				elements: [
					{
						view: "radio", name: "cod_typ",
						label: "Identificar por", labelPosition: "top", //vertical: true,
						value: "cedulas", options: [
							{id: "cedulas", value: "Cédula"}, // the initially selected item
							{id: "pedidos", value: "Pedido"}
						]
					},
					{template: "Información a incluir", type: "section"},
					{view: "checkbox", name: "cod", label: "Cédula/Pedido"},
					{view: "checkbox", name: "rs", label: "Ruta Secuencia"},
					{view: "checkbox", name: "etq", label: "Etiquetas"},
					{view: "checkbox", name: "cd", label: "Corrección Dirección"},
					{view: "checkbox", name: "logo", label: "Logo"},
					{view: "checkbox", name: "ma", label: "Mensaje Auditoría"},
					{
						view: "uploader",
						localId: "boletasUpl", //name: "files",
						accept: "application/pdf",
						upload: "server/Listados/upload.php",
						value: "Seleccionar Boletas ...",
						//link:"doclist",
						autosend: false, //!important
						on: {
							onFileUpload: (file, response) => {
								webix.modalbox({
									title: file.name,
									text: "Boletas marcadas correctamente.",
									buttons: ["Cancelar", "Descargar"]
								}).then(
									result => {
										if (result === "1") {
											window.location = "server/getFile.php?file=" + response.file;
										}
									}
								);
							}
						}
					},
					{
						view: "button", label: "Marcar", localId: "okBtn", //disabled: true,
						click: () => {
							this.$$("boletasUpl").define("formData", this.$$("formView").getValues());
							this.$$("boletasUpl").send();
							//this.setUplParams();
							//this.getRoot().hide();
						}
					},
				]
			}
		};
	}

	showWindow(config = {}) {
		this.getRoot().$scope.$$("closeBtn").define("hidden", config.hideCloseBtn || false);
		this.getRoot().show();
	}

	destroy() {
		console.log("destroy");
		super.destroy();
	}

	setUplParams() {
		console.log(this.$$("formView").getValues());
	}
}
