import {JetView} from "webix-jet";

export default class Vehiculos extends JetView {

	config() {

		const GeneralTab = {
			rows: [
				{
					view: "form",
					localId: "vehFrm",
					elementsConfig: {
						labelPosition: "top"
					},
					elements: [
						{
							rows: [
								{
									cols: [
										{view: "text", label: "Placa*", name: "pla", width: 100},
										{
											view: "richselect", label: "Sucursal*", name: "suc_id",
											suggest: {
												url: "server/sucursales/options.php"
											}
										}
									]
								},
								{
									cols: [
										{
											view: "richselect", label: "Clase*", name: "cla_id",
											suggest: {
												template: "#cla#",
												body: {template: "#cla#"},
												url: "server/vehiculo_clases/list.php"
											}
										},
										{
											view: "richselect", label: "Combustible*", name: "com_id",
											options: [
												{id: 1, value: "Gasolina"},
												{id: 2, value: "GNV"},
												{id: 3, value: "Diesel"},
												{id: 4, value: "Gas-Gasolina"},
												{id: 5, value: "Eléctrico"},
												{id: 6, value: "Hidrógeno"},
												{id: 7, value: "Etanol"},
												{id: 8, value: "Biodiesel"},
												{id: 9, value: "GLP"},
												{id: 10, value: "Gaso Eléctrico"},
												{id: 11, value: "Diesel Eléctrico"}
											]
										}
									]
								},
								{
									cols: [
										{view: "text", label: "Licencia de Tránsito Nº", name: "lic"},
										{
											view: "richselect", label: "Servicio", name: "ser_id",
											suggest: {
												data: [
													{id: 1, value: "Particular"},
													{id: 2, value: "Público"}
												]
											}
										}
									]
								},
								{
									cols: [
										{view: "text", label: "Marca", name: "mar"},
										{view: "text", label: "Línea", name: "lin"},
										{view: "text", label: "Modelo", name: "mdl"}
									]
								},
								{type: "section", template: "Volumen de Carga"},
								{
									cols: [
										{view: "text", label: "Ancho", name: "anc"},
										{view: "text", label: "Alto", name: "alt"},
										{view: "text", label: "Largo", name: "lar"},
										{view: "text", label: "m<sup>3</sup>"}
									]
								},
								{view: "textarea", label: "Notas", name: "des"},
								{}
							]
						}
					],
					rules: {
						pla: webix.rules.isNotEmpty,
						cla_id: webix.rules.isNotEmpty,
						com_id: webix.rules.isNotEmpty
					}
				}
			]
		};
		const HojaVidaTab = {
			view: "form",
			localId: "vehFrm",
			elementsConfig: {
				labelPosition: "top"
			},
			elements: [
				{view: "datepicker", label: "SOAT - Fecha Renovación", name: "soat_ren"},
				{view: "datepicker", label: "Licencia Tránsito - Fecha Renovación", name: "lic_tra_ren"},
				{}
			]
		};
		const MantenimientoTab = {
			rows: [{
				view: "toolbar", elements: [
					{
						view: "button", value: "Agregar", click: function () {
							$$("dt1").add({
								rank: 99,
								title: "",
								year: "2012",
								votes: "100"
							});
						}
					},
					// {
					// 	view: "button", value: "Delete Row", click: function () {
					// 		var id = $$("dt1").getSelectedId();
					// 		if (id)
					// 			$$("dt1").remove(id);
					// 	}
					// },
					{}
				]
			}, {
				view: "datatable", id: "dt1",
				columns: [
					{map: "(date)#start#", header: "Fecha", sort: "date", format: webix.i18n.dateFormatStr, width: 100},
					{id: "text", header: "Descripción", fillspace: true}
				],
				autoheight: true,
				scroll: false,
				data: [
					{text: "Revisión, cambio de aceite", start: "2021-02-14"},
					{text: "Cambio llantas", start: "2020,12,12"},
					{text: "Cambio pastillas frenos", start: "2020-7-29"},
					{text: "Revisión Técnico Mecánica", start: "2020-4-30"}
				],
				select: "row", editable: true, editaction: "dblclick",
				// save: {
				// 	updateFromResponse:true,
				// 	url:"rest->//docs.webix.com/samples/server/films"
				// },
				// url: "//docs.webix.com/samples/server/films"
			}, {}]
		};


		return {
			type: "space",
			cols: [
				{
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Vehículos"},
								{},
								{
									view: "button", type: "icon", icon: "wxi-plus", width: 37, css: "app_button",
									click: () => this.agregarClick()
								}
							]
						},
						{view: "text", localId: "filterTxt"},
						{
							view: "list", localId: "vehLst", width: 300,
							template: "#pla#<br>#cla# - #com# - #vol#",
							url: "server/vehiculos/list.php",
							save: "server/vehiculos/save.php",
							select: true
						}
					]

				},
				{view: "resizer"},
				{
					rows: [{
						view: "toolbar", elements: [
							{
								view: "button",
								type: "icon",
								icon: "mdi mdi-arrow-left",
								width: 40,
								// click: () => this.cancelarClick()
							},
							{},
							{
								view: "button",
								label: "Guardar",
								type: "iconButton",
								icon: "save",
								click: () => this.guardarClick()
							},
							// {view: "button", label: "Borrar", type: "iconButton", icon: "minus-circle"}
						]
					},
						{
							view: "tabview",
							fitBiggest: true,
							cells: [
								{
									header: "General",
									body: GeneralTab
								},
								{
									header: "Documentos",
									body: HojaVidaTab
								},
								{
									header: "Mantenimientos",
									body: MantenimientoTab
								}
							]
						}]
				}
			]
		};
	}

	init(view) {
		this.vehLst = view.queryView({localId: "vehLst"});
		this.vehFrm = view.queryView({localId: "vehFrm"});
		this.vehFrm.bind(this.vehLst);
		this.filterTxt = view.queryView({localId: "filterTxt"});
		webix.dp(this.vehLst).attachEvent("onBeforeInsert", this.vehLstBeforeSave);
		webix.dp(this.vehLst).attachEvent("onBeforeUpdate", this.vehLstBeforeSave);

		this.filterTxt.attachEvent("onTimedKeyPress", () => {
			const txt = this.filterTxt.getValue().toUpperCase();
			// this.perLst.filter("#nom#", txt);
			this.vehLst.filter(obj => {
				return obj.pla.indexOf(txt) !== -1;
			}, null);
		});
	}

	vehLstBeforeSave(id, action) {
		delete action.data.cla;
		delete action.data.com;
	}

	agregarClick() {
		this.vehLst.unselectAll();
		this.vehFrm.elements.pla.focus();
	}

	guardarClick() {
		//if (this.vehFrm.isDirty()) {
		if (!this.vehFrm.validate()) {
			return false;
		}
		// Es necesario convertirlo a un objeto any porque el método save sólo está
		// definido en el form cuando se ha hecho un binding
		// Revisar posibilidad de usar Mixins:
		// https://github.com/Microsoft/TypeScript/wiki/What's-new-in-TypeScript#typescript-22
		(this.vehFrm).save();
		this.vehLst.moveTop(this.vehLst.getLastId());
		//}
	}
}