import {JetView} from "webix-jet";

import ItemsView from "views/common/items";

export default class Recepcion2 extends JetView {
	config() {
		const screen = this.app.config.size;
		console.log("Recepcion2 screen:" + screen);

		const small = {
			//view: "multiview",
			cols: [
				{$subview: true},
				//{$subview: ItemsView, name: "itemsView"}
				{$subview: "views/common/items"}
			]
		};

		const wide = {
			cols: [
				{$subview: true},
				{$subview: ItemsView, name: "itemsView"}
			]
		};

		const test = {
			cols: [
				{template: "COL 1"},
				{template: "COL 2"},
			]
		};

		if(screen === "small") return small;
		return wide;

		//return screen === "small" ? small : wide;

	}

	init() {
		this.on(this.app, "viaje:change", id => this.viajeChange(id));
	}

	urlChange(view, url) {
		console.log("Recepcion/urlChange");
		const dscId = this.getParam("dscId");
		//this.getSubView("itemsView").setData(getItems({dscId}));
		this.app.callEvent("items:load", [{dscId}]);
	}

	viajeChange(id) {
		//this.getSubView("itemsView").setData({});
	}
}