import {JetView} from "webix-jet";
import ItemsView from "views/common/items";


export default class Distribucion extends JetView {
	config() {
		return {
			cols: [
				{$subview: true},
				ItemsView,
			]
		};
	}

	init(view) {
		this.on(this.app, "reparto:itemclick", id => this.repartoItemClick(id));
		this.on(this.app, "reparto:save", values => this.repartoSave(values));
	}

	repartoItemClick(id) {
		console.log("reparto:itemclick", id);
	}

	repartoAdd() {
		console.log("reparto:add");
		//this.getSubView("repartoForm").show();
		this.getRoot().getChildViews()[1].show();
	}

	repartoSave(values) {
		console.log(values);
		this.getParentView().show("distribucion.items?repId=" + r.id);
		return;
		/*webix.ajax().post("server/reparto/save.php?XDEBUG_SESSION_START=12345", values)
			.then(
				response => {
					const r = response.json();
					if (r.status === "success") {
						console.log(r);
						this.getParentView().show("distribucion.items?repId=" + r.id);
					} else if (r.status === "error") {
						webix.alert({type: "alert-error", title: "Error al guardar", text: r.msg});
					}
					// console.log(response, this.getSubView("repartosForm").setId(r.data.id));
				}
			);
		 */

	}

	urlChange(view, url) {
		console.log("Distribucion/urlChange");
		const rprId = this.getParam("rprId");
		//this.getSubView("itemsView").setData(getItems({rprId}));
		this.app.callEvent("items:load", [{rprId}]);
	}

}