import {JetView} from "webix-jet";

export default class LoginView extends JetView {
	config() {
		return ui;
	}

	init(view) {
		view.$view.querySelector("input").focus();
		//this.$$("versionLbl").setValue("Powered by <img src='tdsx_logo.png' height='18px' /> TDSX V" + this.app.config.version);
		this.$$("versionLbl").setValue("Powered by <img src='tdsx_logo.png' height='18px' alt='TDSX logo' /> TDSX V0.7.9.4");
	}

	do_login() {
		const user = this.app.getService("user");
		const form = this.getRoot().queryView({view: "form"});

		if (form.validate()) {
			const data = form.getValues();
			user.login(data.login, data.pass).catch(function() {
				webix.html.removeCss(form.$view, "invalid_login");
				form.elements.pass.focus();
				form.clear();
				form.focus("login");

				webix.delay(function() {
					webix.html.addCss(form.$view, "invalid_login");
				});
			});
		}
	}
}

const login_form = {
	view: "form",
	width: 300, borderless: false, margin: 10,
	rows: [
		{view: "label", label: "", height: 200, css: "app-logo"},
		{view: "text", name: "login", label: "Usuario", labelPosition: "top"},
		{view: "text", type: "password", name: "pass", label: "Contraseña", labelPosition: "top"},
		{
			view: "button", value: "Login", click() {
				this.$scope.do_login();
			},
			hotkey: "enter"
		},
		{view: "label", localId:"versionLbl", label: "Powered by TDS Cloud", align: "right"}
	],
	rules: {
		login: webix.rules.isNotEmpty,
		pass: webix.rules.isNotEmpty
	}
};

const ui = {rows: [{}, {cols: [{}, login_form, {}]}, {}]};