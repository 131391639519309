// import SignaturePad from "signature_pad";
import {JetView} from "webix-jet";

export default class InspecVeh extends JetView {
	config() {

		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Inspección"},
						{view: "text", value: "ABC123", width: 70},
						{view: "datepicker", width: 120}
					]
				},
				{
					view: "scrollview",
					scroll: "y",
					autoheight: true,
					body: {
						view: "form",
						elementsConfig: {
							labelPosition: "top",
							labelWidth: 150,
						},
						elements: [
							{view: "text", label: "Kilometraje"},
							{
								elementsConfig: {height: 60},
								rows: [
									{template: "LUCES", type: "section"},
									{
										view: "segmented",
										label: "Frontales de servicio, alta y baja",
										value: 0,
										height: 80,
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large notchecked'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large checked'></span>"}
										]
									},{
										cols: [
											{
												view:"text", label: "Observaciones:"
											},
											{
												width: 60,
												view: "button", type:"iconTop", icon:"mdi mdi-camera", label:"foto"
											}
										]
									},

									{
										height: 20
									},
									{
										view: "segmented",
										label: "Direccionales delanteras, parqueo y giro",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Direccionales traseras, parqueo y giro",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Luces de retroceso, parqueo, frenado",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							},
							{
								elementsConfig: {height: 60},
								rows: [
									{template: "CABINA", type: "section"},
									{
										view: "segmented",
										label: "Espejo central y laterales",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Puertas de acceso al vehículo",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Vidrio fontal",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Vidrios laterales y trasero",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Indicadores de Aceite, Combustible, Temperatura, Velocímetro",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Condiciones de los asientos ",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Condiciones apoyacabezas",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Estado cinturones de seguridad",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							}, {
								elementsConfig: {height: 60},
								rows: [
									{template: "CONDICIONES TÉCNICO-MECÁNICAS", type: "section"},
									{
										view: "segmented",
										label: "Caja de cambios",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Estado de pedales: acelerador, freno, cloche",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Estado de latas y pintura",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Freno de emergencia",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Nivel de fluidos: aceite, refrigerante, frenos y agua de batería",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Fugas del motor",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Tensión correas",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Tapas",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Transmisión",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Dirección ",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Filtros húmedos y secos",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Baterías y cables",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							}, {
								elementsConfig: {height: 60},
								rows: [
									{template: "SISTEMA DE ADVERTENCIA", type: "section"},
									{
										view: "segmented",
										label: "Pito",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Alarma de Retroceso",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							}, {
								elementsConfig: {height: 60},
								rows: [
									{template: "LLANTAS", type: "section"},
									{
										view: "segmented",
										label: "En buen estado (sin cortaduras profundas y sin abultamiento y 2 mm de huella mínima).",
										height: 80,
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Todas las ruedas tienen sus esparragos completos",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Presión de inflado (inspección visual)",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Estado del repuesto y equipo para cambio de llantas",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							}, {
								elementsConfig: {height: 60},
								rows: [
									{template: "Otros", type: "section"},
									{
										view: "segmented",
										label: "Caja de Herramientas ",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Limpiaparabrisas",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							}, {
								elementsConfig: {height: 60},
								rows: [
									{template: "EQUIPO DE PREVENCIÓN Y SEGURIDAD", type: "section"},
									{
										view: "segmented",
										label: "Extintor vigente",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Conos reflectivos",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Linterna",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Botiquín de primeros auxilios",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							}, {
								elementsConfig: {height: 60},
								rows: [
									{template: "AIRE ACONDICIONADO", type: "section"},
									{
										view: "segmented",
										label: "Cuenta con Aire Acondicionado, y en funcionamiento",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							}, {
								elementsConfig: {height: 60},
								rows: [
									{template: "DOCUMENTOS", type: "section"},
									{
										view: "segmented",
										label: "Tarjeta de Propiedad",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Tarjeta de Servicio",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "SOAT",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Certificación Gases",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
									{
										view: "segmented",
										label: "Revisión Técnico Mecánica",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									},
								]
							}, {
								elementsConfig: {height: 60},
								rows: [
									{template: "LIMPIEZA", type: "section"},
									{
										view: "segmented",
										label: "Orden y aseo",
										options: [
											//{id: 0, value: "<span class='webix_icon mdi mdi-checkbox-blank-circle-outline icon_large'></span>"},
											{id: -1, value: "<span class='webix_icon mdi mdi-thumb-down icon_large'></span>"},
											{id: 1, value: "<span class='webix_icon mdi mdi-thumb-up icon_large'></span>"}
										]
									}
								]
							},
							{template: "OBSERVACIONES", type: "section"},
							{view: "textarea"},
							{template: "FIRMA", type: "section"},
							{
								view: "iframe",
								src: "http://192.168.1.50/tds3_ts/signaturepad/index.html",
								height: 250
							}
						]
					}
				}

				// {
				// 	localId: "spad",
				// 	view: "template",
				// 	height: 200,
				// }
			]
		};
	}

// init() {
// 	(this.$$("spad") as webix.ui.template).setHTML("<div class='wrapper'>" +
// 		"<canvas id='signature-pad' class='signature-pad' width=400 height=200></canvas>" +
// 		"</div>");
// }
//
// ready() {
// 	const canvas = document.getElementById("signature-pad") as HTMLCanvasElement;
//
// 	function resizeCanvas() {
// 		// When zoomed out to less than 100%, for some very strange reason,
// 		// some browsers report devicePixelRatio as less than 1
// 		// and only part of the canvas is cleared then.
// 		const ratio = Math.max(window.devicePixelRatio || 1, 1);
// 		canvas.width = canvas.offsetWidth * ratio;
// 		canvas.height = canvas.offsetHeight * ratio;
// 		canvas.getContext("2d").scale(ratio, ratio);
// 	}
//
// 	window.onresize = resizeCanvas;
// 	resizeCanvas();
//
// 	const signaturePad = new SignaturePad(canvas, {
// 		backgroundColor: "rgb(255, 255, 255)" // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
// 	});
// }
}