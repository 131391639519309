import {JetView} from "webix-jet";

export default class ViajesDetalle extends JetView {
	config(){
		return {
			view: "datatable", localId: "dt",
			select: true,
			columns: [
				{id: "tot", header: "TOTAL", width: 40},
				{id: "des", header: "DESCRIPCIÓN", fillspace: true},
				{
					id: "l", header: {text: "L", css: {"background-color": "#FFF", "font-weight": "bold"}}, width: 50,
					css: {"text-align": "right", "border-left": "#ccc solid 3px"},
					template(item) {
						return item.l > 0 ? item.l : "";
					},
					cssFormat(value, config) {
						if (value > 0) {
							return {"background-color": "#eee"};
						}
					}
				},
				{
					id: "r",
					header: {text: "R", css: {"background-color": "#FFF"}},
					width: 50,
					css: {"text-align": "right", "border-left": "#27aae1 solid 3px"},
					template(item) {
						return item.r > 0 ? item.r : "";
					},
					cssFormat(value, config) {
						if (value > 0) {
							return {"background-color": "#cee1f9"};
						}
					}
				},
				{
					id: "d",
					header: {text: "D", css: {"background-color": "#FFF"}},
					width: 50,
					css: {"text-align": "right", "border-left": "#fbb040 solid 3px"},
					template(item) {
						return item.d > 0 ? item.d : "";
					},
					cssFormat(value, config) {
						if (value > 0) {
							return {"background-color": "#ffce95"};
						}
					}
				},
				{
					id: "dm",
					header: {text: "DM", css: {"background-color": "#FFF"}},
					width: 50,
					css: {"text-align": "right", "border-left": "#fbb040 dashed 3px"},
					template(item) {
						return item.dm > 0 ? item.dm : "";
					},
					cssFormat(value, config) {
						if (value > 0) {
							return {"background-color": "#ffce95"};
						}
					}
				},
				{
					id: "n",
					header: {text: "N", css: {"background-color": "#FFF"}},
					width: 50,
					css: {"text-align": "right", "border-left": "#ed5f64 solid 3px"},
					template(item) {
						return item.n > 0 ? item.n : "";
					},
					cssFormat(value, config) {
						if (value > 0) {
							return {"background-color": "#FFB6C5"};
						}
					}
				},
				{
					id: "e",
					header: {text: "E", css: {"background-color": "#FFF"}},
					width: 50,
					css: {"text-align": "right", "border-left": "#8dc63f solid 3px"},
					template(item) {
						return item.e > 0 ? item.e : "";
					},
					cssFormat(value, config) {
						if (value > 0) {
							return {"background-color": "#CAEDB9"};
						}
					}
				},
				{
					id: "er",
					header: {text: "LG", css: {"background-color": "#FFF"}},
					width: 50,
					css: {"text-align": "right", "border-left": "#8dc63f dashed 3px"},
					template(item) {
						return item.er > 0 ? item.er : "";
					},
					cssFormat(value, config) {
						if (value > 0) {
							return {"background-color": "#CAEDB9"};
						}
					}
				},
				{
					id: "ds",
					header: {text: "DS", css: {"background-color": "#FFF"}},
					width: 50,
					css: {"text-align": "right", "border-left": "#7f3f98 solid 3px"},
					template(item) {
						return item.ds > 0 ? item.ds : "";
					},
					cssFormat(value, config) {
						if (value > 0) {
							return {"background-color": "#EEBBFF"};
						}
					}
				}
			],
			on: {
				// onBeforeLoad(){
				// 	console.log("onBeforeLoad");
				// 	this.showOverlay("Loading...");
				// },
				onAfterLoad() {
					console.log("onAfterLoad");
					this.hideOverlay();
				}
			},
			url: "server/viajes/details.php?XDEBUG_SESSION_START=12345"
		};
	}

	urlChange(view,url){
		console.log("Viajes / Detalle", url);
	}
}