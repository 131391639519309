import "./styles/app.css";
import {JetApp, HashRouter, plugins} from "webix-jet";
import session from "models/session";

/*
Se agregó import "babel-polyfill" y "babel-polyfill": "^6.26.0" en "devDependencies"
para evitar errores al usar async / await
 */
//import "babel-polyfill";

// webix.ui.fullScreen();

try {
	document.domain = "tdsx.app";
} catch (e) {
	console.log(e);
}

// Service Worker
/*if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker.register("sw.js")
			.then(registration => {
				console.log("SW registered: ", registration);
			})
			.catch(registrationError => {
				console.log("SW registration failed: ", registrationError);
			});
	});
}*/

export default class MyApp extends JetApp {
	constructor(config) {
		const size = () => {
			const screen = document.body.offsetWidth;
			//const screen = window.innerWidth;
			//console.log("screen width", screen);
			//webix.message(screen);
			return screen > 800 ? "wide" : (screen > 600 ? "mid" : "small");
		};

		let theme = "webix_dark";
		let localeConfig = {};
		// let cookies = true;

		try {
			// theme = webix.storage.local.get("tdsx_theme");
			localeConfig.storage = webix.storage.local;
		} catch (err) {
			// cookies = false;
			webix.message("You disabled cookies. The language and theme won't be restored after page reloads.", "debug");
		}

		const defaults = {
			id: APPNAME,
			name: APPNAME,
			version: VERSION,
			router: HashRouter,
			debug: !PRODUCTION,
			start: "/top/dashboard",
			// start: "/top",
			theme: theme || "",
			dateFormat: "%j %F, %H:%i",
			listLength: 50,
			size: size(),
			withChat: false, // size() !== "small",
			views: {
				"information": "customers.information",
				"statistics": "customers.statistics",
				"paymenthistory": "customers.paymenthistory"
			}
		};

		super({...defaults, ...config});

		this.use(plugins.Locale, localeConfig);
		this.use(plugins.User, {model: session});

		webix.event(window, "resize", () => {
			const newSize = size();
			//webix.message(newSize + " W:" + document.body.offsetWidth);
			if (newSize !== this.config.size) {
				this.config.size = newSize;
				// TODO: Si no se ejecuta la siguiente línea a veces no toma la altura completa,
				// Si se ejecuta la siguiente línea se ejecuta de nuevo config() init() ready()
				// perdiendo el estado actual
				this.refresh();
			}
		});

		//console.log("webix.env.touch:", webix.env.touch);
		//webix.Touch.enable();
		//webix.Touch.limit(true);
		// webix.Touch.disable();

		this.attachEvent("app:error:resolve", function (err, url) {
			console.log(err);
			webix.delay(() => this.show("/top/dashboard"));
		});
	}
}

if (!BUILD_AS_MODULE) {
	webix.ready(() => {
		if (!webix.env.touch && webix.env.scrollSize && webix.CustomScroll)
			webix.CustomScroll.init();
		new MyApp().render();
	});
}


// webix.debug({ events: true, size:true });

//track js errors
// if (PRODUCTION){
// 	window.Raven
// 		.config(
// 			"https://59d0634de9704b61ba83823ec3bf4787@sentry.webix.io/12"
// 		)
// 		.install();
// }