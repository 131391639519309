import FichaReparto from "views/fichaReparto";
import {JetView} from "webix-jet";

export default class Programacion extends JetView {

	config() {
		return {
			// type: "clean",
			cols: [
				{
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Fecha Programación:", width: 155},
								{},
								{view: "datepicker", localId:"fec_pro", format: "%Y-%m-%d", width: 120}
							]
						},
						{
							view: "scrollview",
							minWidth: 250,
							maxWidth: 300,
							body: {
								rows: [
									{
										view: "toolbar",
										elements: [
											{view: "label", label: "Recursos"}
										]
									},
									{
										view: "label",
										label: "Conductores"
									},
									{
										view: "list",
										localId: "conLst",
										layout: "x",
										scroll: "x",
										select: true,
										drag: true,
										css: "recursoItem",
										type: {
											width: 80,
											height: 40
										},
										url: "server/programacion/repartoConductores.php",
										on: {
											onBeforeDrop() {
												console.log(arguments);
											}
										}
									},
									{
										view: "label",
										label: "Vehículos"
									},
									{
										view: "list",
										localId: "vehLst",
										layout: "x",
										scroll: "x",
										select: true,
										drag: true,
										css: "recursoItem",
										type: {
											width: 80,
											height: 40
										},
										url: "server/programacion/repartoVehiculos.php"
									},
									{
										view: "label",
										label: "Dispositivos"
									},
									{
										view: "list",
										localId: "disLst",
										layout: "x",
										scroll: "x",
										select: true,
										drag: true,
										css: "recursoItem",
										type: {
											width: 100,
											height: 40
										},
										url: "server/programacion/repartoDispositivos.php"
									},
									{
										view: "label",
										label: "Auxiliares"
									},
									{
										view: "list",
										localId: "auxLst",
										layout: "x",
										scroll: "x",
										autoheight: true,
										select: true,
										drag: true,
										css: "recursoItem",
										type: {
											width: 80,
											height: 40
										},
										url: "server/programacion/repartoAuxiliares.php"
									},
									{
										view: "toolbar",
										elements: [
											{view: "label", label: "Pedidos"},
											{},
											{
												view: "toggle",
												localId: "selBtn",
												type: "icon",
												icon: "mdi mdi-check",
												width: 40
											},
											{
												view: "menu", openAction: "click",
												subMenuPos: "left",
												// submenuConfig: { width: 35},
												width: 40,
												data: [{
													icon: "mdi mdi-dots-vertical", // , config: {width: 35},
													submenu: [
														{view: "label", value: "Agrupar por:", disabled: true},
														{value: "Fecha Promesa"},
														{value: "Empresa"},
														{value: "Zona"}
													]
												}],
												on: {
													onMenuItemClick: id => {
														if (id === "impRutMen") {
															// this.uploadAPI.fileDialog({ rowid : id.row });
														}
													}
												}
											}
										]
									},
									{
										view: "treetable",
										localId: "pedidosTt",
										// autowidth: true,
										autoheight: true,
										drag: true,
										select: "row",
										// multiselect: "touch",
										columns: [
											// {id:"fecPrm", header:{text:"FEC", height: 20}, width: 35},
											{
												id: "emp", header: {text: "EMP", height: 20}, fillscpace: true,
												template(obj, common) {
													if (obj.$group) {
														obj.$open = 1;
														return common.treetable(obj, common) + obj.fecPrm;
													}
													return obj.emp;
												}
											},
											{id: "zon", header: {text: "ZON", height: 20}, fillspace: true},
											{id: "rutNor", header: {text: "RUT", height: 20}, fillspace: true},
											{
												id: "p", header: [
													{text: "PED", height: 20},
													{localId: "pedTotSel", text: "", height: 20}
												],
												fillspace: true
											},
											{
												id: "v", header: [
													{
														// text: "m<sup>3</sup>",
														text: "m3",
													},
													{localId: "volTotSel", text: "", height: 20}
												],
												format: webix.i18n.numberFormat,
												fillspace: true
											},
										],
										scheme: {
											$group: {
												by: "fecPrm",
												map: {
													emp: ["fecPrm"],
													p: ["p", "sum"],
													v: ["v", "sum"]
												},
												row(obj) {
													return `${obj.emp} ${obj.p}P ` + webix.i18n.numberFormat(obj.v) + "m3";
													// return obj.emp;
												}
											}
										},
										url: "server/programacion/repartoRutaPedidos.php",
										on: {
											onSelectChange: () => this.pedidosTtSelectChange()
										}
									},
									{view: "button", label: "Asignar"}

									// {}
								]
							}

						},
					]
				},
				{
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Repartos"},
								{},
								{
									view: "icon", icon: "wxi-plus", width: 35,
									click: () => {
										this.$$("fichasLayout").addView(FichaReparto);
									}
								},
								{
									view: "icon", icon: "mdi mdi-content-save", width: 35,
									click: () => {
										let pro_rep = {};
										pro_rep.fec_pro = this.$$("fec_pro").getValue().toISOString().substr(0,10);
										const repartos = [];
										this.$$("fichasLayout").getChildViews().forEach(function (f) {
											repartos.push(f.$scope.getValues());
										});
										pro_rep.repartos = repartos;

										webix.ajax().post("server/programacion/saveRepartos.php",pro_rep);
									}
								}
							]
						},
						{
							view: "scrollview",
							body: {
								localId: "fichasLayout",
								type: "wide",
								view: "flexlayout",
								cols: [
									{$subview: "fichaReparto"},
									{$subview: "fichaReparto"},
									{$subview: "fichaReparto"},
									{$subview: "fichaReparto"},
									{$subview: "fichaReparto"},
								]
							}
						}

					]
				}
			]
		};
	}

	init(view) {
		this.pedidosTt = this.$$("pedidosTt");
		this.selBtn = this.$$("selBtn");
		this.pedTotSel = this.$$("pedTot");

		this.selBtn.attachEvent("onItemClick", () => {
			this.pedidosTt.define("multiselect", parseInt(this.selBtn.getValue(), 10) === 1 ? "touch" : true);
		});

		this.pedidosTt.waitData.then(
			() => {
				this.pedidosTt.openAll();
			}
		);
	}

	pedidosTtSelectChange() {
		let pedTot = 0;
		let volTot = 0.0;

		for (const item of this.pedidosTt.getSelectedItem(true)) {
			pedTot += item.p;
			volTot += parseFloat(item.v);
		}

		this.pedidosTt.config.columns[3].header = [
			{
				text: "PED",
				height: 20
			},
			{
				text: pedTot,
				height: 20
			}];
		this.pedidosTt.config.columns[4].header = [
			{
				text: "m3",
				height: 20
			},
			{
				text: webix.i18n.numberFormat(volTot),
				height: 20
			}];
		this.pedidosTt.refreshColumns();
	}
}