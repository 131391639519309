import {JetView} from "webix-jet";

export default class rutaSugerida extends JetView {

	config(){
		return {
			view: "list",
			localId: "lisRutSugLis",
			template: "#id# - #bar#",
			height: 150,
			select: true,
			click: id => this.app.callEvent("rutasugerida:change", [id])
		};
	}

	init(){
		this.on(this.app,"ruteo:select", data => {
			this.$$("lisRutSugLis").clearAll();
			this.$$("lisRutSugLis")
				.load("server/Listados/sugerirBarrios.php?codType=" + data.codType + "&cod=" + data.id);
		});
	}
}