import ListadosListado from "views/listados/listado";
import ListadosRutas from "views/listados/rutas";
import {JetView} from "webix-jet";
import listadoInfoTpl from "views/listados/listadoInfoTpl";
import Detalles from "views/detalles";
import {filtersToParams, filtersToQueryString} from "../libs/utils";
import MarcarBoletasWindow from "jet-views/MarcarBoletasWindow";

export default class Listados extends JetView {

	config() {
		this.listadoView = new ListadosListado(this.app, null);
		this.detallesView = new Detalles(this.app, null, {toolbarVisible: true, estado: "L"});
		const screen = this.app.config.size;

		const toolbar = {
			view: "toolbar",
			elements: [
				{view: "label", label: "Listados"},
				{},
				{
					view: "select", localId: "undCmb",
					width: 150,
					options: [
						{id: "P", value: "Pedidos"},
						{id: "PC", value: "Pedidos (Cajas)"},
						{id: "I", value: "Ítems"},
						{id: "C", value: "Ítems (Cajas)"}
					],
					on: {
						onChange: v => {
							webix.storage.local.put("und", v);
							this.setParam("und", v, true);
						}
					}
				},
				{
					view: "toggle",
					localId: "selBtn",
					type: "icon",
					icon: "mdi mdi-checkbox-multiple-marked-outline",
					tooltip: "Selección Múltiple",
					width: 40,
					on: {
						onChange: v => this.listadoView.setMultipleSelection(v)
					}
				},
				{
					view: "icon", icon: "mdi mdi-file-upload",
					tooltip: "Cargar Listado",
					click: () => {
						this.uploadAPI.fileDialog();
					}
				},
				{
					view: "icon", icon: "mdi mdi-stamper",
					tooltip: "Marcar Boletas",
					click: () => {
						this.marcarBoletasWindow.showWindow();
					}
				},
				/*{
					view: "icon", tooltip: "Ver Rutas", icon: "mdi mdi-routes",
					click() {
						// const dt = this.$scope.getRoot().queryView({localId: "lisLis_dt"});
						const lisId = this.$scope.listadoView.getRoot().getSelectedItem().id;
						this.$scope.getRoot().getChildViews()[1].$scope.showListado(lisId);
					}
				},*/
				{
					view: "icon", tooltip: "Ver PreRuteo", icon: "mdi mdi-routes",
					click: () => {
						// const dt = this.$scope.getRoot().queryView({localId: "lisLis_dt"});
						const lisId = this.listadoView.getRoot().getSelectedItem().id;
						this.show("preruteo?lisId=" + lisId);
					}
				},
				{
					view: "menu", openAction: "click", width: 35, /*subMenuPos: "bottom",*/
					autowidth: true,
					data: [{
						icon: "mdi mdi-dots-vertical",
						submenu: [
							{id: "updCchMen", value: "Actualizar Cache", icon: "mdi mdi-upload"},
							{id: "lisTDXSaTDS", value: "Cargar Listado en TDS", icon: "mdi mdi-upload"},
						]
					}],
					on: {
						onMenuItemClick: id => {
							if (id === "updCchMen") {
								this.listadoView.updateCache();
							} else if(id === "lisTDXSaTDS") {
								this.cargarListadoEnTDS();
							}
						}
					}
				}
			]
		};

		let view = this.listadoView;

		if (screen === "wide") {
			view = {
				cols: [
					{
						gravity: 1,
						rows: [
							this.listadoView
						]
					},
					{view: "resizer"},
					{
						gravity: 2,
						$subview: true
					}
				]
			};
		}


		return {
			rows: [
				toolbar,
				view
			]
		};
	}

	init(view) {

		this.uploadAPI = webix.ui({
			view: "uploader",
			localId: "lisUpl",
			apiOnly: true,
			accept: ".xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf",
			upload: "server/Listados/upload.php?XDEBUG_SESSION_START=12345",
			on: {
				onFileUpload: (file, response) => this.onFileUpload(file, response, view),
				onFileUploadError: (file, response) => webix.alert({
					type: "alert-error",
					title: "Error al importar",
					text: response.msg
				})
			}
		});

		this.marcarBoletasWindow = this.ui(MarcarBoletasWindow);

		/*this.on(this.app, "listadoClick", lis => {
			console.log("ListadoClick", lis);
			if(lis === undefined) return;
			this.setParam("lisId", lis.id, true);
			const filters = {
				lisId: lis.id,
				zon: lis.zon
			};
			const label = "Listado " + lis.id;
			this.app.callEvent("update", [filters, label]);
			this.$$("detallesContainer").show();
		});*/

		this.on(this.app, "sucId:change", params => {
			console.log("sucId:change");
			/*const filters = {
				sucId: this.getParam("sucId", true),
				empId: this.getParam("empId", true),
			};
			this.setParam("lisId","", true);
			this.app.callEvent("listados:update", [filters]);*/

		});

		this.on(this.app, "detallesxdescripcion:click", filters => {
			// filters.lisId = this.getParam("lisId", false);
			console.log(filters);
			this.show("items.main?" + filtersToParams(filters));
		});

		this.on(this.app, "detallesxdescripcion:close", () => {
			this.$$("detallesContainer").hide();
			this.setParam("lisId", "", true);
		});
	}

	onFileUpload(item, response, view) {
		const tpl = webix.template(listadoInfoTpl);

		if (response.task === "CargarListado") {
			webix.modalbox({
				title: "Información Listado",
				css: {
					".webix_popup_title": {
						"color": "red"
					}
				},
				text: tpl(response.info),
				buttons: ["Cancelar", "Importar en " + response.info.suc],
				width: 500,
			}).then(
				result => {
					if (result === "1") { //Importar
						webix.ajax().post("server/Listados/importar.php", {uploadId: response.info.uploadId}, res => {
							const lisDt = view.queryView({localId: "lisLisDt"});
							//lisDt.clearAll();
							res = JSON.parse(res);
							console.log(res);
							//lisDt.load("server/Listados/list.php?_noc=" + Date.now());
							//lisDt.load("server/Listados/list.php");
							//TODO: Revisar cuando se carga el ID que devuelve el servidor
							// reemplaza el primero de la lista
							//lisDt.load("server/Listados/list.php?filter[lisId]=" + res.lisId);
							lisDt.load("server/Listados/list.php?filter[sucId]=" + this.getParam("sucId", true));
							webix.message(res);
						});
					}
				}
			);
		} else if (response.task === "MarcarBoletas") {
			webix.modalbox({
				title: item.name,
				text: "Boletas marcadas correctamente.",
				buttons: ["Cancelar", "Descargar"]
			}).then(
				result => {
					if (result === "1") {
						window.location = "server/getFile.php?file=" + response.file;
					}
				}
			);
		}
	}

	urlChange(view) {
		const sucId = this.getParam("sucId", true);
		const empId = this.getParam("empId", true);
		let und = this.getParam("und", true) || webix.storage.local.get("und") || "I";
		this.setParam("und", und, true);
		this.$$("undCmb").setValue(this.getParam("und", true));

		// if (und === undefined) {
		// 	const undLocal = webix.storage.local.get("und");
		// 	if (undLocal !== undefined) {
		// 		und = undLocal;
		// 	} else {
		// 		und = "I";
		// 	}
		// 	this.setParam("und", und, true);
		// }

		if (this.sucId === undefined
			|| this.empId === undefined
			|| sucId !== this.sucId
			|| empId !== this.empId) {
			this.sucId = sucId;
			this.empId = empId;
			this.app.callEvent("listados:update", [{sucId, empId}]);
			this.uploadAPI.define("formData", {sucId});
		}

		const filters = {
			sucId,
			empId,
			lisId: this.getParam("lisId", false),
			und
		};

		console.log("Listados urlChange", filters.lisId);

		if (filters.lisId) {
			const label = "Listado " + filters.lisId;
			//this.$$("detallesContainer").show();
			this.app.callEvent("update", [filters, label]);
		}
	}

	cargarListadoEnTDS() {
		const lisId = this.getParam("lisId");
		webix.ajax().post("server/Listados/cargarEnTDS.php", {lisId})
			.then(result => webix.message(result));
	}
}
