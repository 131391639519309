const filtersToQueryString = function (filters) {
	if (filters == null) return "";
	// ES6
	// for (const [k, v] of Object.entries(filters)) {
	// 	console.log(k, v);
	// }
	let f = [];
	for (const k in filters) {
		if (filters.hasOwnProperty(k)) {
			f.push(`filter[${k}]=${filters[k]}`);
		}
	}
	return f.join("&");
};

const filtersToParams = function (filters) {
	if (filters == null) return "";
	let f = [];
	for (const k in filters) {
		if (filters.hasOwnProperty(k)) {
			f.push(`${k}=${filters[k]}`);
		}
	}
	return f.join("&");
};

// The browser will limit the number of concurrent audio contexts
// So be sure to re-use them whenever you can
const myAudioContext = new AudioContext();

/**
 * Helper function to emit a beep sound in the browser using the Web Audio API.
 *
 * @param {number} duration - The duration of the beep sound in milliseconds.
 * @param {number} frequency - The frequency of the beep sound.
 * @param {number} volume - The volume of the beep sound.
 *
 * @returns {Promise} - A promise that resolves when the beep sound is finished.
 */
function beep(duration, frequency, volume) {
	return new Promise((resolve, reject) => {
		// Set default duration if not provided
		duration = duration || 200;
		frequency = frequency || 440;
		volume = volume || 100;

		try {
			let oscillatorNode = myAudioContext.createOscillator();
			let gainNode = myAudioContext.createGain();
			oscillatorNode.connect(gainNode);

			// Set the oscillator frequency in hertz
			oscillatorNode.frequency.value = frequency;

			// Set the type of oscillator
			oscillatorNode.type = "square";
			gainNode.connect(myAudioContext.destination);

			// Set the gain to the volume
			gainNode.gain.value = volume * 0.01;

			// Start audio with the desired duration
			oscillatorNode.start(myAudioContext.currentTime);
			oscillatorNode.stop(myAudioContext.currentTime + duration * 0.001);

			// Resolve the promise when the sound is finished
			oscillatorNode.onended = () => {
				resolve();
			};
		} catch (error) {
			reject(error);
		}
	});
}

function download(filename, text) {
	var element = document.createElement("a");
	element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
	element.setAttribute("download", filename);

	element.style.display = "none";
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

export {filtersToQueryString, filtersToParams, beep, download};
