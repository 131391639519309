const template = "<div>" +
"<table style=\"margin: auto\">" +
"<tr><td>Archivo:</td><td><strong>#file#</strong></td></tr>" +
"<tr><td>Sucursal:</td><td><strong>#suc#</strong></td></tr>" +
"<tr><td>Empresa:</td><td><strong>#emp#</strong></td></tr>" +
"<tr><td>Año:</td><td><strong>#yyyy#</strong></td></tr>" +
"<tr><td>Campaña:</td><td><strong>#cam#</strong></td></tr>" +
"<tr><td>Viaje:</td><td><strong>#via#</strong></td></tr>" +
"<tr><td>Ciudad:</td><td><strong>#ciu#</strong></td></tr>" +
"<tr><td>Zona:</td><td><strong>#zon#</strong></td></tr>" +
"<tr><td>Rutas:</td><td><strong>#rut#</strong></td></tr>" +
"<tr><td>Sin Ruta:</td><td><strong>#no_rut#</strong></td></tr>" +
"<tr><td>Pedidos:</td><td><strong>#ped#</strong></td></tr>" +
"<tr><td>Cajas:</td><td><strong>#caj#</strong></td></tr>" +
"<tr><td>Pedidos Fríos:</td><td><strong>#ped_f#</strong></td></tr>" +
"<tr><td>Ítems Fríos:</td><td><strong>#caj_f#</strong></td></tr>" +
"<tr><td>Devoluciones:</td><td><strong>#ped_dv#</strong></td></tr>" +
"<tr><td>Ítems Devoluciones:</td><td><strong>#caj_dv#</strong></td></tr>" +
"<tr><td>Devoluciones con Pedido:</td><td><strong>#dv_con_ped#</strong></td></tr>" +
"</table>" +
"</div>";

export default template;