export default {
	"Age":"年齡",
	"Total money spent, $":"总数花的钱, $",
	"Clients":"顾客",
	"Income":"收入",
	"First name":"名字",
	"Last name":"姓",
	"Position":"位置",
	"Click to select":"点击点选",
	"Notifications":"通知",
	"Yes":"是",
	"No":"不是",
	"City, country":"国家",
	"Address":"地址",
	"Birthday":"生日",
	"Click to add tags":"点击以添加标签",
	"Notes":"笔记",
	"Top clients":"顶级顾客",
	"Money spent, $":"花的钱, $",
	"-- Not selected --":"-- 不中选 --",
	"Sales manager":"销售经理",
	"Customer service":"顾客服务",
	"General manager":"总经理",
	"Awarded Badges":"荣获的徽章",
	"New":"新",
	"Customer":"客户",
	"Supplier":"供应商",
	"Discount":"折扣",
	"Old Buddy":"老巴迪",
	"Avid Supporter":"追客支持者",
	"Date":"日期",
	"Payment":"付款",
	"Purchase":"收购",
	"Sum":"总和",
	"Left":"剩下",
	"Nothing on this day":"这一天没什么",
	"Information":"信息",
	"Statistics":"统计",
	"Transactions":"交易", 
	"Customers":"顾客",
	"Charts":"图表",
	"See all notifications":"查看所有通知",
	"Deposits News":"存款新闻",
	"Dear client, we inform you about the following changes: beginning from September 24, 2018 all transactions (incoming and out...":"亲顾客， 我们通知您以下更改， 从2018年9月24日买卖都 （来向和去向。。。",
    "Search Improved":"搜索改进",
	"Dear client! Following the latest updates of the SeekMeEverywhere engines, your search has become more reliable and convenient! No...":"亲爱的顾客！引擎的最新更新，您的搜索变得更加可靠和方便！現在..",
    "Transaction Reports":"交易報告", 
	"Dear client, we inform you about a change in the date of monthly transaction reports. Beginning from September 20, 2018 reports...":"亲爱的顾客， 我们會通知您每月交易報告日期的变化。從2018年9月20日开始報導...",
    "Warning":"警告",
	"Dear client, we know where you store your passwords. We highly recommend you to memorize them and destroy those memos of yours.":"亲爱的顾客， 我们知道您存储密码的位置。我们強烈建议您記住它们並銷毀您的備忘錄",
    "Editing customers data":"編輯客戶數據",
	"Dear client, if you want to see the details on every customer and edit them, double-click the customer in the list on the right.":"亲爱的顾客，如果您想查看每個客戶的詳細信息並進行編輯，请双擊右側列表中的客后",
	"We miss you":"我们想念",
	"Dear client, it has been a while since you talked to us. We are waiting for you in the same place at the same time.":"亲爱的顾客，自從您與我们交談以來已经有一段時间了。我们在同一個地方等你。",
	"Have a nice day":"祝您一个好天",
	"Dear client, whenever you are reading this, we wish you a merry day. May the luck and success attend you.":"亲爱的顾客，无论何時，只要您正在阅读本文，我们祝您聖誕快乐。祝你好運和成功。",
	"Localization":"本土化",
	"Dear client, you can pick one of the six languages we provided for this demo app. Click the icon in the top right corner.":"情爱的，您可以选择我们為此演示應用程序提供的六種語言之一。单击右上角的图标",
	"We love you":"我们爱您",
	"Dear client, we love you very much. If our feelings are mutual, contact us and download Webix. Then we will love you eternally.":"亲啊爱的顾客，我们非常愛您。如果我们的感受是相互的，请联系我们並下載Webix。然後我们會永遠愛您",
	"Persons":"人们",
	"Click twice to see more goodies":"点击兩次查看更多好東西",
	"Saved":"保存",
	"Settings":"设置",
	"Regional settings":"区域设置",
	"Language":"语言",
	"Date format":"日期格式",
	"Money format":"钱格式",
	"Environment settings":"环境设置",
	"Theme":"主題",
	"Light":"光",
	"Dark":"黑暗",
	"Max list length":"最大列表長度",
	"Default settings":"默认设置",
	"Reset":"重啟",
	"Save":"保存",
	"Open latest notifications":"打开最新通知",
	"Go to settings":"前往设置",
	"All":"所有",
	"Payments":"支付",
	"Incoming":"來",
	"Email":"电子邮件",
	"Widgets":"小工具",
	"Demos":"演示",
	"Prices":"價格",
	"Tutorials":"教程",
	"Tirane, Albania":"地拉那，阿尔巴尼亚",
	"Buenos Aires, Argentina":"布宜诺斯艾利斯， 阿根廷",
	"Canberra, Australia":"堪培拉， 澳大利亚",
	"Vienna, Austria":"維也納，奧地利",
	"Minsk, Belarus":"民斯克， 白俄罗斯",
	"Brussels, Belgium":"布鲁塞尔， 比利时",
	"Brasilia, Brazil":"巴西利亚， 巴西",
	"Ottawa, Canada":"渥太华， 加拿大",
	"Beijing, China":"北京， 中国",
	"Prague, Czech Republic":"布拉格， 捷克",
	"Copenhagen, Denmark":"哥本哈根， 丹麦",
	"Cairo, Egypt":"开罗， 埃及",
	"Helsinki, Finland":"赫尔辛基， 芬兰",
	"Paris, France":"巴黎， 法国",
	"Tbilisi, Georgia":"第比利斯， 乔治亚",
	"Berlin, Germany":"柏林， 德国",
	"New Delhi, India":"印度新德里", 
	"Dublin, Ireland":"都柏林， 爱尔兰",
	"Rome, Italy":"罗马，意大利",
	"Tokyo, Japan":"日本東京",
	"Wellington, New Zealand":"威灵顿， 新西兰",
	"Seoul, Republic of Korea":"首尔， 韩国",
	"Madrid, Spain":"马德里， 西班牙",
	"Stockholm, Sweden":"斯德哥尔摩， 瑞典",
	"Washington, United States":"华盛顿， 美国",
	"Payment History":"支付历史",
	"Origin of clients":"客戶的起源",
	"Income from various age groups":"各年龄组的收入",
	"Clients by occupation":"客戶按职业化分",
	"No new notifications":"没有新的通知"
};