export default {
	"Age":"Age",
	"Total money spent, $":"Total money spent, $",
	"Clients":"Clients",
	"Income":"Income",
	"First name":"First name",
	"Last name":"Last name",
	"Position":"Position",
	"Click to select":"Click to select",
	"Notifications":"Notifications",
	"Yes":"Yes",
	"No":"No",
	"City, country":"City, country",
	"Address":"Address",
	"Birthday":"Birthday",
	"Click to add tags":"Click to add tags",
	"Notes":"Notes",
	"Reset":"Reset",
	"Save":"Save",
	"Top clients":"Top clients",
	"Money spent, $":"Money spent, $",
	"-- Not selected --":"-- Not selected --",
	"Sales manager":"Sales manager",
	"Customer service":"Customer service",
	"General manager":"General manager",
	"New":"New",
	"Customer":"Customer",
	"Supplier":"Supplier",
	"Discount":"Discount",
	"Old Buddy":"Old Buddy",
	"Avid Supporter":"Avid Supporter",
	"Date":"Date",
	"Payment":"Payment",
	"Purchase":"Purchase",
	"Sum":"Sum",
	"Left":"Left",
	"Nothing on this day":"Nothing on this day",
	"Information":"Information",
	"Statistics":"Statistics",
	"Transactions":"Transactions",
	"Customers":"Customers",
	"Charts":"Charts",
	"See all notifications":"See all notifications",
	"Deposits News":"Deposits News",
	"Dear client, we inform you about the following changes: beginning from September 24, 2018 all transactions (incoming and out...":"Dear client, we inform you about the following changes: beginning from September 24, 2018 all transactions (incoming and out...",
	"Search Improved":"Search Improved",
	"Dear client! Following the latest updates of the SeekMeEverywhere engines, your search has become more reliable and convenient! No...":"Dear client! Following the latest updates of the SeekMeEverywhere engines, your search has become more reliable and convenient! No...",
	"Transaction Reports":"Transaction Reports",
	"Dear client, we inform you about a change in the date of monthly transaction reports. Beginning from September 20, 2018 reports...":"Dear client, we inform you about a change in the date of monthly transaction reports. Beginning from September 20, 2018 reports...",
	"Warning":"Warning",
	"Dear client, we know where you store your passwords. We highly recommend you to memorize them and destroy those memos of yours.":"Dear client, we know where you store your passwords. We highly recommend you to memorize them and destroy those memos of yours.",
	"Editing customers data":"Editing customers data",
	"Dear client, if you want to see the details on every customer and edit them, double-click the customer in the list on the right.":"Dear client, if you want to see the details on every customer and edit them, double-click the customer in the list on the right.",
	"We miss you":"We miss you",
	"Dear client, it has been a while since you talked to us. We are waiting for you in the same place at the same time.":"Dear client, it has been a while since you talked to us. We are waiting for you in the same place at the same time.",
	"Have a nice day":"Have a nice day",
	"Dear client, whenever you are reading this, we wish you a merry day. May the luck and success attend you.":"Dear client, whenever you are reading this, we wish you a merry day. May the luck and success attend you.",
	"Localization":"Localization",
	"Dear client, you can pick one of the six languages we provided for this demo app. Click the icon in the top right corner.":"Dear client, you can pick one of the six languages we provided for this demo app. Click the icon in the top right corner.",
	"We love you":"We love you",
	"Dear client, we love you very much. If our feelings are mutual, contact us and download Webix. Then we will love you eternally.":"Dear client, we love you very much. If our feelings are mutual, contact us and download Webix. Then we will love you eternally.",
	"Persons":"Persons",
	"Click twice to see more goodies":"Click twice to see more goodies",
	"Saved":"Saved",
	"Settings":"Settings",
	"Regional settings":"Regional settings",
	"Language":"Language",
	"Date format":"Date format",
	"Money format":"Money format",
	"Environment settings":"Environment settings",
	"Theme":"Theme",
	"Light":"Light",
	"Dark":"Dark",
	"Max list length":"Max list length",
	"Default settings":"Default settings",
	"Open latest notifications":"Open latest notifications",
	"Go to settings":"Go to settings",
	"All":"All",
	"Payments":"Payments",
	"Incoming":"Incoming",
	"Email":"Email",
	"Widgets":"Widgets",
	"Demos":"Demos",
	"Prices":"Prices",
	"Tutorials":"Tutorials",
	"Tirane, Albania":"Tirane, Albania",
	"Buenos Aires, Argentina":"Buenos Aires, Argentina",
	"Canberra, Australia":"Canberra, Australia",
	"Vienna, Austria":"Vienna, Austria",
	"Minsk, Belarus":"Minsk, Belarus",
	"Brussels, Belgium":"Brussels, Belgium",
	"Brasilia, Brazil":"Brasilia, Brazil",
	"Ottawa, Canada":"Ottawa, Canada",
	"Beijing, China":"Beijing, China",
	"Prague, Czech Republic":"Prague, Czech Republic",
	"Copenhagen, Denmark":"Copenhagen, Denmark",
	"Cairo, Egypt":"Cairo, Egypt",
	"Helsinki, Finland":"Helsinki, Finland",
	"Paris, France":"Paris, France",
	"Tbilisi, Georgia":"Tbilisi, Georgia",
	"Berlin, Germany":"Berlin, Germany",
	"New Delhi, India":"New Delhi, India",
	"Dublin, Ireland":"Dublin, Ireland",
	"Rome, Italy":"Rome, Italy",
	"Tokyo, Japan":"Tokyo, Japan",
	"Wellington, New Zealand":"Wellington, New Zealand",
	"Seoul, Republic of Korea":"Seoul, Republic of Korea",
	"Madrid, Spain":"Madrid, Spain",
	"Stockholm, Sweden":"Stockholm, Sweden",
	"Washington, United States":"Washington, United States",
	"Payment History":"Payment History",
	"Origin of clients":"Origin of clients",
	"Income from various age groups":"Income from various age groups",
	"Awarded Badges":"Awarded Badges",
	"Clients by occupation":"Clients by occupation",
	"No new notifications":"No new notifications"
};