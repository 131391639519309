import {saveEscaneo} from "../db/escaneos";
import EtiquetasData from "./etiquetas";

function itemsSave() {
	console.log("itemsSave");
	return undefined;
}

const itemsData = new webix.DataCollection({
	scheme: {
		$init: function (itm) {
			//console.log(itm.id, itm.tags);
			//if(itm.tags) itm.tags.split(",");
			//itm.tags = itm.tags.map(o => parseInt(o, 10));
			//if(itm.tags.reduce == undefined) debugger;
			itm.tag_color = itm.tags.length > 0 ? EtiquetasData.getItem(itm.tags[0]).color : "444";
		}
	},
	save: () => itemsSave(),
	on: {
		onDataUpdate: () => updateInfo(),
		onAjaxError: () => onLoadError()
	}
});

export function getItems(filter) {
	console.log("models/items/getItems/webix.callEvent", webix.callEvent("test", [1, 2, 3]));
	itemsData.define("url", "server/items/get.php?" + filter2urlParams(filter));
	return itemsData;
}

export function clearData() {
	itemsData.clearAll();
}

export function getItemId(cod) {
	const item = itemsData.getItem(cod);
	return item.itmId;
}

function filter2urlParams(filter) {
	let kv = [];
	for (let k in filter) kv.push("filter[" + k + "]=" + filter[k]);
	return kv.join("&");
}

//TODO: Procesar y ordenar al cargar, calcular cantidades x estado al actualizar
// No se espera que cambie la lista Detalle
// La lista es pequeña, el tiempo de ordenamiento es muy corto

export const DetallesData = new webix.DataCollection();

function setDetallesData() {
	const detalles = new Map();
	const items = {l: 0, r: 0};
	const pedidosL = new Set();
	const pedidosR = new Set();

	itemsData.data.each(i => {
		if (!detalles.has(i.des_id)) detalles.set(i.des_id, {id: i.des_id, des: i.des, l: 0, r: 0});

		//TODO: Declarar constantes para los Estados
		if (i.est_id === 1) {
			detalles.get(i.des_id).l++;
			items.l++;
			pedidosL.add(i.ped);
		} else if (i.est_id === 2) {
			detalles.get(i.des_id).r++;
			items.r++;
			pedidosR.add(i.ped);
		}

	}, this, true);

	DetallesData.parse(Array.from(detalles.values()));
	// DetallesData.sort("#id#","asc","int");
	DetallesData.sort("#des#", "asc");
	return DetallesData;
}

function updateInfo() {
	//console.log("models/items/updateInfo/this.app", this.app);
	setDetallesData();
	return undefined;
}

/**
 * Función que procesa el código escaneado junto con
 * información adicional necesaria según el estado
 * en el que se escanea
 */
export function registrarEscaneoItem(app, scanData) {
	console.log(scanData);
	const data = itemsData.getItem(scanData.cod);
	if (data) {
		scanData.itmId = data.itmId;
		itemsData.updateItem(scanData.cod, {estCod: scanData.estCod, t: scanData.t});
		app.callEvent("update", [{dscId: scanData.dscId}, "YEA!!!"]);
	}

	// Guardar localmente (indexedDB)
	// Intentar guardar en servidor (lote)
	// Si se guarda correctamente eliminar de la DB local (indexedDB)

	console.log("SAVEESCANEO:" + saveEscaneo(scanData));
}

function onLoadError() {
	console.log("onLoadError");
	return undefined;
}
