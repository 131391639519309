import {JetView} from "webix-jet";
import Items from "views/recepcion/items";

export default class Recepcion extends JetView {

	config() {
		return {
			// type: "space",
			responsive: true,
			cols: [
				{
					id: "col1",
					responsiveCell: false,
					minWidth: 250,
					$subview: true
				},
				Items
			]

		};
	}

	init_old(view) {
		// (webix.proxy as any).faye.client = new (Faye as any).Client("https://tdsx.app:8000/faye");
		// (webix.proxy as any).faye.clientId = webix.uid();

		this.viaCmb = view.queryView({localId: "viaCmb"});
		this.recFrm = view.queryView({localId: "recFrm"});
		this.rutTxt = view.queryView({localId: "rutTxt"});
		this.palTxt = view.queryView({localId: "palTxt"});
		this.codTxt = view.queryView({localId: "codTxt"});
		this.generalDt = view.queryView({localId: "generalDt"});
		this.infoDt = view.queryView({localId: "infoDt"});
		this.itemsLst = view.queryView({localId: "itemsLst"});
		this.pedidoDt = view.queryView({localId: "pedidoDt"});
		this.peerIdTxt = view.queryView({localId: "peerIdTxt"});

		this.on(this.app, "descargue:save", values => this.descargueSave(values));
		this.on(this.app, "viaje:change", id => this.viaCmbChange(id));
		// this.on(this.app, "descargue:itemclick", id => this.descargueItemClick(id));

		webix.dp(this.itemsLst).attachEvent("onBeforeDataSend", obj => {
			obj.data.via_id = this.viaCmb.getList().getSelectedItem().value;
		});

		// this.peerIdTxt.setValue(webix.storage.local.get("peerId"));

		this.beep_error = new Audio("codebase/beep_error.mp3");

		this.generalData = [
			{id: "ped", des: "Pedidos", l: 0, r: 0},
			{id: "itm", des: "Items", l: 0, r: 0},
			{id: "fri", des: "Fríos", l: 0, r: 0},
			{id: "pedInc", des: "Pedidos Incompletos", l: "", r: 0},
			{id: "sinReg", des: "Sin Registro", l: "", r: 0},
			{id: "cajAve", des: "Cajas Averiadas", l: "", r: 0},
			{id: "rutInc", des: "Ruta Incorrecta", l: "", r: 0},
			{id: "palInc", des: "Estiba Incorrecta", l: "", r: 0},
		];

		// webix.attachEvent("onRotate", orientation => {
		// 	console.log("orientation:", orientation, this.recFrm.$width);
		// });

		// this.peer = new Peer({key: "lwjd5qra8257b9"});
		//
		// this.peer.on("open", function (id) {
		// 	console.log(`My peer ID is: ${id}`);
		// });

	}

	startConnection(peerId) {
		// CÓDIGO DEL PEER
		this.conn = this.peer.connect(peerId);

		this.conn.on("open", function () {
			// Receive messages
			// this.conn.on("data", function(data) {
			// 	console.log("Received", data);
			// });

			// Send messages
			this.conn.send("Conexión Exitosa!");
		});
	}

	viaCmbChange(id) {
		// this.infoRefresh(v);
		this.rutTxt.getList().data.clearAll();
		this.rutTxt.setValue("");
		this.infoDt.clearAll();
		this.itemsLst.clearAll();
		// const via_id = this.viaCmb.getList().getItem(id).value;
		// Se agrega el parámetro via_id para usar en el cliente de sincronización TDS
		// this.itemsLst.define("save", "offline->server/recepcion/save.php?via_id=" + via_id);
		this.itemsLst.load("server/recepcion/desitems.php?dsc_id=" + id, "json")
			.then(res => {
				const L = res.json().est.L;
				const dur = Math.round(L * .97);
				this.startTimer(dur);
				this.infoRefresh();
			});
		// this.itemsLst.define("url", "offline->server/recepcion/viaitems.php?via_id=" + v);
		// this.itemsLst.define("save", "offline->server/recepcion/setrec.php");
	}

	loadItems() {
		const desId = this.getParam("desId", true);
		this.rutTxt.getList().data.clearAll();
		this.rutTxt.setValue("");
		this.infoDt.clearAll();
		this.itemsLst.clearAll();
		// const via_id = this.viaCmb.getList().getItem(id).value;
		// Se agrega el parámetro via_id para usar en el cliente de sincronización TDS
		// this.itemsLst.define("save", "offline->server/recepcion/save.php?via_id=" + via_id);
		this.itemsLst.load("server/recepcion/desitems.php?dsc_id=" + desId, "json")
			.then(
				res => {
					const L = res.json().est.L;
					const dur = Math.round(L * .97);
					this.startTimer(dur);
					this.infoRefresh();
				}
			);
	}

	procesarBtnClick() {
		const escaneo = [];
		const items = this.itemsLst.find(o => o.est_id === 2);
		for (const i in items) {
			escaneo.push({cod: items[i].id, t: items[i].t, rut_esc: items[i].rut_esc, pal: items[i].pal});
		}
		const params = {frm: this.recFrm.getValues(), esc: JSON.stringify(escaneo)};
		webix.ajax().post("server/recepcion/setrec.php", params, function () {
			console.log(arguments);
		});
	}


	infoDtSelectChange() {
		const item = this.infoDt.getSelectedItem(false);
		if (item === undefined) {
			return;
		}
		const col = this.infoDt.getSelectedId(false, false).column;

		// this.itemsLst.filter(null, null);

		this.itemsLst.filter(function (obj) {
			if (col === "l") {
				return obj.des_id === item.id && obj.est_id === 1;
			} else if (col === "r") {
				return obj.des_id === item.id && obj.est_id === 2;
			} else if (col === "des") {
				return obj.des_id === item.id;
			}
		}, "");

		if (!this.$$("col2").isVisible()) {
			this.$$("col1").hide();
			this.$$("col2").show();
		}
		this.generalDt.unselectAll();
	}

	itemsDtSelectChange() {
		this.infoPedido(this.itemsLst.getSelectedId(false));
	}

	/*
	recCodTxtKeyPress(code) {
		if (code !== 13) {
			return;
		}

		this.codTxt.config.value = this.codTxt.getInputNode().nodeValue;
		const cod = this.codTxt.getValue().trim();
		// Ignorar caracteres especiales en ciertas configuraciones de terminaciones en el scanner
		if (cod.length === 0) {
			return;
		}
		this.codTxt.setValue("");

		//Ignorar escaneo repetido
		//TODO: Comprobar en caso de registro de Descentralizados (Código UPC)
		if (this.codAnt === cod) {
			return;
		} else {
			this.codAnt = cod;
		}

		if (cod.substr(0, 1) === ":") {
			// const pal = cod.substr(cod.lastIndexOf("Ñ")+1);
			const pal = cod.substr(5);
			this.palTxt.setValue(pal);
			return;
		}
		// this.itemsLst.getItem(cod).est_id = 10;
		// this.itemsLst.getItem(cod).t = Date.now();
		// this.itemsLst.getItem(cod).pal = this.palTxt.getValue();
		// this.itemsLst.getItem(cod).rut_esc = this.rutTxt.getValue();
		// this.itemsLst.refresh(cod);
		//Detectar si código existe antes de continuar

		/!*if(this.itemsLst.getIndexById(cod) === -1){
			webix.alert("Código No Registrado en Listado. Por favor escanearlo en TDS.");
			return;
		}*!/

		const data = {
			est_id: 2,
			t: Date.now(),
			pal: this.palTxt.getValue(),
			rut_esc: this.rutTxt.getValue()
		};

		this.itemsLst.updateItem(cod, data);
		// recCodTxt.focus();

		this.actualizarDetalleCantidades(cod);

		this.itemsLst.filter(function (obj) {
			return obj.rut_esc && obj.rut_esc !== obj.rut_nor;
		}, "");

		// Reproducir sonido en error
		// if (this.itemsLst.getItem(cod).rut_nor != this.rutTxt.getValue()) {
		// 	this.beep_error.play();
		// }

		// TODO: Ejecutar solo cuando la pestaña Pedido esté activa
		this.infoPedido(cod);

	}
*/

	pedidoDtSelectChange() {
		const item = this.pedidoDt.getSelectedItem(false);
		if (item === undefined) {
			return;
		}
		const col = this.pedidoDt.getSelectedId(false, false).column;

		// this.itemsLst.filter(null, null);

		this.itemsLst.filter(function (obj) {
			if (col === "l") {
				return obj.ped === item.ped && obj.des_id === item.id && obj.est_id === 1;
			} else if (col === "r") {
				return obj.ped === item.ped && obj.des_id === item.id && obj.est_id === 2;
			} else if (col === "des") {
				return obj.ped === item.ped && obj.des_id === item.id;
			}
		}, "");

		if (!this.$$("col2").isVisible()) {
			this.$$("col1").hide();
			this.$$("col2").show();
		}
	}



	actualizarPedidoCantidades(cod) {
		const item = this.itemsLst.getItem(cod);
		const itemPedido = this.pedidoDt.getItem(item.des_id);
		const l = this.itemsLst.find(function (obj) {
			return obj.est_id === 1 && itemPedido.id === obj.des_id;
		});

		const dif = this.pedidoDt.getItem(this.itemsLst.getItem(cod).des_id).l - l.length;

		this.pedidoDt.getItem(item.des_id).l = parseInt(itemPedido.l, 10) - dif;
		this.pedidoDt.getItem(item.des_id).r = parseInt(itemPedido.r, 10) + dif;
		this.pedidoDt.refresh(item.des_id);
		this.pedidoDt.clearSelection();
	}

	generalDtSelectChange() {
		const item = this.generalDt.getSelectedItem(false);
		if (item === undefined) {
			return;
		}
		const col = this.generalDt.getSelectedId(false, false).column;

		// this.itemsLst.filter(null, null);

		this.itemsLst.filter(function (obj) {
			if (col === "l") {
				// return obj.des_id === item.id && obj.est_id === 1;
			} else if (col === "r") {
				return obj.est_id === 2 && (obj.rut_nor !== obj.rut_esc.id);
			} else if (col === "des") {
				// return obj.des_id === item.id;
			}
		}, "");

		if (!this.$$("col2").isVisible()) {
			this.$$("col1").hide();
			this.$$("col2").show();
		}
	}

	descargueSave(values) {
		console.log(values);

		webix.bind(
			webix.ajax().post("server/descargues/save.php?XDEBUG_SESSION_START=12345", values,
				response => {
					const r = JSON.parse(response);
					if (r.status === "error") {
						webix.alert({type: "alert-error", title: "Error al guardar", text: r.msg});
					}
					console.log(response, this.getSubView("descargueForm").setId(r.data.id));
				}
			), this);
		this.$$("col1").getChildViews()[0].show();
	}

	descargueAdd() {
		this.$$("col1").getChildViews()[1].show();
	}

	iniciarEscaneo() {
		this.$$("col1B").show();
		webix.delay(() => {
			this.codTxt.focus();
		}, this, null, 300);
		// webix.storage.local.put("peerId", this.$$("peerIdTxt").getValue());
		// this.startConnection(this.$$("peerIdTxt").getValue());
	}

	descargueItemClick(id) {
		this.setParam("rep_id", id, true);
		this.$$("col1").getChildViews()[1].show();
		// this.viaCmbChange(id);
		// this.iniciarEscaneo();
		// this.startTimer();
	}

	updateTimer(duration, display) {
		var timer = duration, minutes, seconds;
		setInterval(function () {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			// display.textContent = minutes + ":" + seconds;
			//TODO: Por qué se está ejecutando 2 veces
			//Al parecer una llamada sin duración y se produce el NaN
			if (!isNaN(minutes)) {
				display.define("label", "<span style='color:red; font-size:x-large;'>" + minutes + ":" + seconds + "</span>");
				display.refresh();
			}

			timer--;

			// if (--timer < 0) {
			// 	timer = duration;
			// }
		}, 1000);
	}

	startTimer(dur) {
		// const dur = 60 * 1;
		console.log(dur);
		const display = this.$$("countdown");
		this.updateTimer(dur, display);
	}

	recepcionFin() {
		webix.confirm({
			title: "FINALIZAR RECEPCIÓN",
			text: "¿Desea finalizar la Recepción?",
			ok: "Sí",
			cancel: "No",
			type: "confirm-warning"
		}).then(
			response => {
				console.log(response);
			}
		);
	}


	getDataDetalle() {
		return [1, 2, 3];
	}
}