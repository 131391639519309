import {JetView} from "webix-jet";
import {beep} from "../libs/utils";

export default class ErrorWindow extends JetView {
	config() {
		return {
			view: "window",
			modal: true,
			close: true,
			position: "center",
			fullscreen: true,
			head: "Error",
			css: "error_msg",
			body: {
				view: "template", localId: "msgTpl",
				template: "<span class='blinking'>#msg#</span>",
			},
			on: {
				onHide: () => {
					clearInterval(this.beepInterval);
				}
			}
		};
	}

	showWindow(msg_data) {
		window.navigator.vibrate([200, 100, 200]);
		this.beepInterval = setInterval(() => {
			beep(500, 118, 100);
		}, 1000);
		if(msg_data.title) this.getHead().setHTML(msg_data.title);
		this.$$("msgTpl").setValues(msg_data);
		this.getRoot().show();
	}

	updateMessage(msg_data){
		if(msg_data.title) this.getHead().setHTML(msg_data.title);
		this.$$("msgTpl").setValues(msg_data);
	}

	getHead(){
		//Cuando close:false
		//return this.getRoot().getHead()
		//Cuando close:true
		return this.getRoot().getHead().getChildViews()[0];
	}


}

