import {JetView} from "webix-jet";
import {estados, getEstadosByCod} from "../../models/estados";
import {clientes} from "../../models/clientes";
import {sucursales} from "../../models/sucursales";
import DescripcionesData from "../../models/descripciones";
import EtiquetasData from "../../models/etiquetas";

webix.ui.datafilter.summDistinct = webix.extend({
	refresh:function(master, node, value){
		let result = new Set();
		master.mapCells(null, value.columnId, null, 1, function(value){
			result.add(value);
			return value;
		});

		node.innerHTML = result.size;
	}
}, webix.ui.datafilter.summColumn);

export default class Items extends JetView {
	config() {
		return {
			rows: [
				/*{
					cols: [
						{
							view: "query",
							id: "filterQry",
							//width: 340,
							type: "bar",
							fields: [
								{id: "cod", value: "Código", type: "number"},
								{id: "ciu", value: "Ciudad", type: "text"},
								{id: "dir", value: "Dirección", type: "text"},
								{id: "bar", value: "Barrio", type: "text"},
							]
						},
						{
							view: "icon", icon: "mdi mdi-collage",
							click: () => this.show(this.getUrlString().replace("main", "pivot"))
						}
					]
				},*/
				// {
				// 	view: "query",
				// 	localId: "querybuilder",
				// 	//sorting: true,
				// 	fields: [
				// 		// {id: "cod", value: "Código", type: "text"},
				// 		// {id: "ped", value: "Pedido", type: "text"},
				// 		// {id: "est", value: "Estado", type: "text"},
				// 		// {id: "via", value: "Viaje", type: "text"},
				// 		// {id: "suc", value: "Sucursal", type: "text"},
				// 		// {id: "des", value: "Descripción", type: "text"},
				// 		// {id: "tc", value: "Tipo de Caja", type: "text"},
				// 		// {id: "ind", value: "Indicador", type: "text"},
				// 		// {id: "yyyy", value: "Año", type: "text"},
				// 		// {id: "cam", value: "Campaña", type: "text"},
				// 		// {id: "yyyycam", value: "AñoCampaña", type: "text"},
				// 		// {id: "rep", value: "Representante", type: "text"},
				// 		// {id: "rutNor", value: "Ruta Normalizada", type: "text"},
				// 		// {id: "secNor", value: "Secuencia Normalizada", type: "text"},
				// 		// {id: "rut", value: "Ruta Listado", type: "text"},
				// 		// {id: "sec", value: "Secuencia Listado", type: "text"},
				// 		// {id: "ciu", value: "Ciudad", type: "text"},
				// 		// {id: "dir", value: "Dirección", type: "text"},
				// 		// {id: "bar", value: "Barrio", type: "text"},
				// 		// {id: "tel", value: "Teléfono", type: "text"},
				//
				// 	],
				// 	// columnMode:true,
				// 	//maxLevel: 3
				// },
				/*{
					cols: [
						{view: "button", label: "Aplicar Filtro", click: () => this.filtrarClick()}
					]
				},*/
				{
					cols: [
						{
							view: "datatable",
							resizeColumn: true,
							id: "itemsDt",
							gravity: 2,
							select: "row", footer: true,
							scheme: {
								$change: function (item) {
									item.$css = "est" + item.estCod;
									item.$cellCss = {
										estCod: "estado_header est" + item.estCod + "_header"
									};
								}
							},
							columns: [
								{
									id: "sucId",
									header: ["Suc. ID", {content: "serverFilter"}],
									width: 60,
									hidden: true
								},
								{
									id: "empId",
									header: ["Emp. ID", {content: "serverFilter"}],
									width: 50
								},
								{
									id: "estCod",
									header: ["Estado", {content: "richSelectFilter"}],
									width: 65,
									sort: "string",
									/*cssFormat: (value, row) => {
										// return {
										// 	"border-left": "solid " + this.getColor(row) + " !important",
										// 	"font-weight": "bold",
										// 	"color": this.getColor(row)
										// };
										console.log(value, row);
										return "est" + value + "_header";
									}
									*/
								},
								{
									id: "evnCod",
									header: ["Evento", {content: "richSelectFilter"}],
									width: 65,
									sort: "string",
									hidden: true
								},
								{id: "cod", header: ["Código", {content: "serverFilter"}], width: 120, sort: "int"},
								{
									id: "ped",
									header: ["Pedido", {content: "serverFilter"}],
									footer: {content: "summDistinct"},
									width: 120, sort: "string",
									// template: function (obj, common) {
									// 	if (obj.$level === 1)
									// 		return common.treetable(obj, common) + obj.value;
									// 	return obj.ped;
									// }
								},
								{
									id: "cajNum",
									header: ["Caja Nº", {content: "serverFilter"}],
									width: 80,
									sort: "string"
								},
								{
									id: "lisId",
									header: ["Lis. ID", {content: "serverFilter"}],
									width: 70,
									sort: "int",
									//hidden: true
								},
								{
									id: "viaId",
									header: ["Via. ID", {content: "richSelectFilter"}],
									width: 80,
									sort: "int",
									hidden: false
								},
								{id: "via", header: ["Viaje", {content: "richSelectFilter"}], width: 100, sort: "string"},
								// {id: "suc", header: ["Sucursal", {content: "serverFilter"}], width: 100, sort: "int"},
								{
									id: "desId",
									header: ["Des. ID", {content: "multiComboFilter"}],
									width: 250,
									collection: DescripcionesData
								},
								{
									id: "cnt",
									header: ["Cant.", {content: "excelFilter"}],
									footer:{ content:"summColumn" },
									width: 60,
									sort: "int"
								},
								/*{
									id: "des",
									header: ["Descripción", {content: "serverFilter"}],
									width: 250,
									sort: "string"
								},*/
								{
									id: "tc",
									header: ["Tipo Caja", {content: "excelFilter"}],
									width: 90,
									sort: "string"
								},
								{
									id: "ind",
									header: ["Indicador", {content: "richSelectFilter"}],
									width: 80,
									sort: "string"
								},
								{id: "yyyy", header: ["Año", {content: "richSelectFilter"}], width: 60, sort: "string"},
								{
									id: "cam",
									header: ["Campaña", {content: "richSelectFilter"}],
									width: 60,
									sort: "string"
								},
								{
									id: "yyyycam",
									header: ["AñoCampaña", {content: "richSelectFilter"}],
									width: 100,
									sort: "string"
								},
								{
									id: "fecPrm",
									header: ["Fecha Prom. Ent.", {content: "serverFilter"}],
									width: 120,
									sort: "string"
								},
								{
									id: "repId",
									header: ["Rep. ID", {content: "serverFilter"}],
									width: 80,
									sort: "int",
									hidden: false
								},
								{
									id: "repCod",
									header: ["Rep. COD", {content: "serverFilter"}],
									width: 110,
									sort: "int",
									hidden: false
								},
								{
									id: "rep",
									header: ["Representante", {content: "excelFilter"}],
									width: 200,
									sort: "string"
								},
								{
									id: "zon",
									header: ["Zona", {content: "richSelectFilter"}],
									width: 60,
									sort: "string"
								},
								{
									id: "rut",
									header: ["Ruta Listado", {content: "richSelectFilter"}],
									width: 60,
									sort: "string"
								},
								{
									id: "sec",
									header: ["Secuencia Listado", {content: "serverFilter"}],
									width: 60,
									sort: "string"
								},
								{
									id: "rutNor",
									header: ["Ruta Normalizada", {content: "richSelectFilter"}],
									width: 60,
									sort: "string"
								},
								{
									id: "secNor",
									header: ["Secuencia Normalizada", {content: "serverFilter"}],
									width: 60,
									sort: "string"
								},
								{
									id: "ciu",
									header: ["Ciudad", {content: "richSelectFilter"}],
									width: 100,
									sort: "string"
								},
								{
									id: "dir",
									header: ["Dirección", {content: "serverFilter"}],
									width: 150,
									sort: "string"
								},
								{id: "bar", header: ["Barrio", {content: "serverFilter"}], width: 150, sort: "string"},
								{
									id: "tel",
									header: ["Teléfono", {content: "serverFilter"}],
									width: 100,
									sort: "string"
								},
								{
									id: "tagId",
									header: ["Etq.", {content: "serverFilter"}],
									collection: EtiquetasData,
									width: 100,
									sort: "string"
								},
								{header: {content: "headerMenu"}, headermenu: false, width: 32}
							],
							// url: "server/items/get2.php",
							on: {
								onBeforeLoad: function () {
									// this.showOverlay("<div><img src='https://loading.io/spinners/blocks/index.rotating-squares-preloader-gif.svg'></img></div><div style='margin:75px; font-size:20px;'>Cargando ...</div>");
									this.showOverlay("<img src='codebase/style/tdsx_logo_anim.svg' width='48' />");
									// this.showOverlay("<img src='sources/styles/tdsx_logo_anim3.4.svg' width='50px'>");
								},
								onAfterLoad: function () {
									this.hideOverlay();
								},
								onSelectChange: () => this.selectChangeHandler(),

								// onBeforeFilter: (col, value) => {
								// 	console.log("data->onBeforeFilter", col, value);
								// }

								// "data->onBeforeAdd": function (id, obj) {
								// 	console.log(id);
								// 	obj.suc = sucursales.getItem(obj.sucId).suc;
								// }
							}

						},
						{
							localId: "detallesView",
							type: "space",
							minWidth: 350,
							hidden: true,
							gravity: 1,
							rows: [
								{
									view: "toolbar", elements: [
										{
											view: "icon", icon: "mdi mdi-close",
											click: () => {
												this.$$("detallesView").hide();
											}
										},
										{view: "label", label: "Detalles"},
										{
											view: "icon", icon: "mdi mdi-table-search", tooltip: "Ver Items del Pedido",
											click: () => {
												const ped = this.$$("itemsDt").getSelectedItem().ped;
												console.log(ped);
												this.show("items.main?ped=" + ped);
												// this.setParam("ped", ped, true);
											}
										},
										{
											view: "icon",
											icon: "mdi mdi-account-search",
											tooltip: "Ver Items de Representante",
											click: () => {
												const repCod = this.$$("itemsDt").getSelectedItem().repCod;
												this.show("items.main?repCod=" + repCod);
												// this.setParam("ped", ped, true);
											}
										},
										{view: "icon", icon: "mdi mdi-tag-plus", tooltip: "Agregar etiqueta"},
									]
								},
								{
									localId: "itemTpl",
									autoheight: true,
									template: "#info#"
								},
								{
									view: "tabbar", multiview: true,
									tabMinWidth: 60,
									height: 60,
									options: [
										{
											id: "timeline",
											value: "<span class='webix_icon mdi mdi-timeline-text-outline mdi-24px'></span><br><span style='font-size: x-small'>Timeline"
										},
										{
											id: "cajaaveriada",
											value: "<span class='webix_icon mdi mdi-image-broken-variant mdi-24px'></span><br><span style='font-size: x-small'>Caja Averiada</span>"
										},
										{
											id: "mapa",
											value: "<span class='webix_icon mdi mdi-map-clock-outline mdi-24px'></span><br><span style='font-size: x-small'>Mapa</span>"
										},
										{
											id: "boleta",
											value: "<span class='webix_icon mdi mdi-note-text-outline mdi-24px'></span><br><span style='font-size: x-small'>Boleta</span>"
										},
										{
											id: "info",
											value: "<span class='webix_icon mdi mdi-information-outline mdi-24px'></span><br><span style='font-size: x-small'>Info</span>"
										},
									]

								},
								{
									cells: [
										{
											view: "timeline",
											id: "timeline",
											localId: "estadosTml",
											//layout: "x",
											type: {
												height: 100,
												lineColor: this.getColor,
												//type: "alternate"
											},
											templateDate: function (obj) {
												const dateFormat = webix.Date.dateToStr("%Y-%m-%d", false);
												const timeFormat = webix.Date.dateToStr("%H:%i:%s", false);
												// return webix.i18n.dateFormatStr(obj.date) + "<br />"
												// + webix.i18n.timeFormatStr(obj.date);
												return dateFormat(obj.date) + "<br>"
													+ timeFormat(obj.date) + "<br>"
													+ obj.usu + "<br>"
													+ obj.suc;
											},
											scheme: {
												$init: function (obj) {
													// obj.$css = colors[obj.id%colors.length].css;
													obj.$css = "est" + obj.estCod;
												}
											},
										},
										{
											id: "cajaaveriada",
											template: "Caja Averiada"
										},
										{
											id: "mapa",
											template: "Mapa"
										},
										{
											id: "boleta",
											template: "Boleta"
										},
										{
											id: "info",
											template: "Información Detallada de cada Estado<br>Historial Direcciones / Rutas"
										}
									]
								}
							]
						}
					]
				}
			]
		};
	}

	init() {
		// this.$$("itemTpl").define("template", this.itemTplFunction.bind(this));
		getEstadosByCod().then( result => {
			this.estadosByCod = result;
		});

	}

	itemTplFunction(item) {
		return {
			info: "<div class='ruta_sec'><div class='ruta'>" + item.rutNor + "</div>" + item.secNor + "</div>" +
				"<div class='estado est" + item.estCod + "_header'>"
				// "<div class='estado' style='color:white; background-color:#" + estado.col + "'>"
				+ item.estCod
				+ "</div>" +
				"<div class='caj_info'>" +
				"<strong>" + item.rep + "</strong><br>" +
				clientes.getItem(item.cliId).cli.toUpperCase() + " (" + sucursales.getItem(item.sucId).suc + ")<br>" +
				item.via + " " +
				"<a route='" + this.buildFilterLink({ped: item.ped}) + "'>" + item.ped + "</a>" + " " +
				item.cod + " " + item.cajNum + "<br>" +
				item.tam + " " + " " + item.des + "<br>" +
				item.zon + " " + item.ciu + "<br>" +
				item.dir + "<br>" +
				item.bar + "<br>" +
				item.tel + " " + item.cel + "<br>" +
				"</div>"
		};
	}

	buildFilterLink(filters) {
		let result = [];
		for (let filter in filters) {
			result.push(filter + "=" + filters[filter]);
		}
		return "/top/items.main?" + result.join("&");
	}

	getColor(obj) {
		//return "#" + this.estadosByCod[obj.estCod].col;
		//return "est" + obj.estCod ;
		const color = getComputedStyle(document.documentElement)
			.getPropertyValue("--est" + obj.estCod + "_dark");
		console.log("lineColor", color);
		return color;
	}

	filtrarClick() {
		const qb = this.$$("filterQry");
		const dt = this.$$("itemsDt");
		const helper = qb.getFilterHelper();
		dt.filter(helper);
	}

	urlChange(view, url) {
		console.log("item.main urlChange", url);
		const dt = this.$$("itemsDt");
		const filterQry = this.$$("filterQry");
		dt.clearAll();

		dt.eachColumn((id, col) => {
			const isVisible = dt.isColumnVisible(id);
			//Es necesario mostrar la columna porque no se permite filtar columnas no visibles
			if (!isVisible) dt.showColumn(id);
			const filter = dt.getFilter(id);
			if (filter) {
				if (filter.setValue) {
					filter.setValue(this.getParam(id) || "");
				} else {
					filter.value = this.getParam(id) || "";
				}
			}
			if(!isVisible) dt.hideColumn(id);
		}, true);

		dt.getFilter("sucId").value = this.getParam("sucId", true);
		dt.getFilter("empId").value = this.getParam("empId", true) || "";

		// const filters = this.getUrl()[0].params;
		// for (let [k, v] of Object.entries(filters)) {
		// 	dt.getFilter(k).value = v;
		// }

		// dt.filter("via_id", this.getParam("viaId"));
		// dt.hideColumn("via_id");
		// dt.filterByAll();
		// dt.clearAll();
		dt.loadNext(null, null, null, "server/items/get2.php").then(
			data => filterQry.define("data", data)
		);
		// dt.eachColumn((id) => {
		// 	if(id.slice(id.length - 2) === "Id") dt.hideColumn(id);
		// });
	}

	selectChangeHandler() {
		const item = this.$$("itemsDt").getSelectedItem();
		if (!item) return;
		this.$$("detallesView").show();

		const data = this.itemTplFunction(item);
		console.log(data);
		this.$$("itemTpl").define("data", data);
		// this.$$("itemTpl").adjust();
		this.$$("estadosTml").clearAll();
		webix.ajax().get("server/items/estados/get.php", {itmId: item.id})
			.then(result => {
				const data = result.json();
				//Necesario limpiar de nuevo porque si se seleccionan rápido diferentes filas, carga cada uno
				this.$$("estadosTml").clearAll();
				console.log("TIMELINE DATA",data);
				this.$$("estadosTml").parse(data);
			});
	}
}