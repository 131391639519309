import {JetView} from "webix-jet";

export default class Reparto extends JetView{
	config(){
		return {
			rows:[
				{
					$subview: true
				}
			]
		};
	}
}