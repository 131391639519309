import {JetView} from "webix-jet";

export default class List extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Asignación Reparto"},
						{},
						{
							view: "icon", icon: "mdi mdi-plus",
							click: () => {
								this.getParentView().setParam("rprId", 0, true);
								this.getParentView().show("asignacion.form");
							}
						}
					]
				},
				{
					view: "list", localId: "listDt",
					template: "#id# #t_reg#<br><strong>#con# - #pla#</strong><br>Rutas: #rut#<br>Muelle: #mue#<br>",
					type: {
						height: 100,
					},
					on: {
						onItemClick: id => {
							this.getParentView().setParam("rprId", id, true);
							this.getParentView().show("asignacion.form");
						}
					}
				}
			]
		};
	}

	urlChange() {
		console.log(this.getParam("sucId", true));
		this.$$("listDt").clearAll();
		this.$$("listDt").define("url", "server/asignacion/repartos.php?sucId=" + this.getParam("sucId", true));
	}
}