import {JetView, plugins} from "webix-jet";
import PreRuteoForm from "views/preruteo/preruteoForm";
import EtiquetasData, {etiquetasData} from "models/etiquetas";

export default class PreRuteo extends JetView {

	config() {
		return {
			// gravity: 1,
			cols: [
				{
					//gravity: 2,
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "PreRuteo"},
								{},
								// {view: "icon", icon: "wxi-plus", width: 37},
								{
									view: "toggle",
									localId: "selBtn",
									type: "icon",
									icon: "mdi mdi-checkbox-multiple-marked-outline",
									tooltip: "Selección Múltiple",
									width: 40,
									on: {
										onChange: (v) => this.$$("preRutDt2").define("multiselect", v)
									}
								},
								{
									view: "toggle",
									localId: "dragBtn",
									type: "icon",
									icon: "mdi mdi-arrow-up-down-bold",
									tooltip: "Ordenar arrastrando",
									width: 40,
									on: {
										onChange: (v) => this.$$("preRutDt2").define("drag", v ? "order" : false)
									}
								},
								{
									view: "icon",
									localId: "delBtn",
									icon: "mdi mdi-playlist-remove",
									tooltip: "Limpiar tabla",
									width: 40,
									click: () => {
										webix.confirm("Desea eliminar los registros locales de PreRuteo?", "confirm-warning")
											.then(result => {
												this.$$("preRutDt2").clearAll();
												webix.storage.local.remove("preRutDt_data");
												webix.storage.local.remove("preRutDt_state");
											});
									}
								},
								{
									view: "icon", localId: "saveBtn", icon: "mdi mdi-content-save",
									tooltip: "Guardar PreRuteo", width: 40,
									click: () => {
										webix.confirm("Se guardará el PreRuteo en el servidor, desea continuar?", "confirm-warning")
											.then(() => {
												const sucId = this.getParam("sucId", true);
												const codType = this.$$("codType").getValue();
												const preruteos = [];
												this.$$("preRutDt2").data.each(row => {
													preruteos.push({
														id: row.id,
														rut: row.rut_nor,
														sec: row.sec_nor,
														esb: row.tags, //TODO: Reemplazar tags por esb
														pla: row.pla
													});
												});
												const data = {sucId, codType, preruteos};
												console.log(data);
												webix.ajax().post("/server/preruteo/save.php?XDEBUG_SESSION_START=12345", data)
													.then(response => {
														console.log(response);
													});
											});
									}
								},
								{
									view: "menu", openAction: "click", width: 35, /*subMenuPos: "bottom",*/
									autowidth: true,
									data: [{
										icon: "mdi mdi-dots-vertical",
										submenu: [
											{id: "impRutMen", value: "Importar PreRuteo...", icon: "mdi mdi-upload"},
											{id: "expRutMen", value: "Exportar PreRuteo", icon: "mdi mdi-download"},
											{$template: "Separator"},
											{id: "impArchivo", value: "Importar Archivo...", icon: "mdi mdi-upload"},
											{$template: "Separator"},
											{id: "pasteRutSec", value: "Pegar Códigos", icon: "mdi mdi-download"},
											{
												id: "copyRutSec",
												value: "Copiar Ruta Secuencias",
												icon: "mdi mdi-download"
											},
											{
												id: "copyRutSecCorDir",
												value: "Copiar RS CD",
												icon: "mdi mdi-download"
											},
											//{$template: "Separator"},
											// {
											// 	id: "calcularEstibas",
											// 	value: "Calcular Estibas",
											// 	icon: "mdi mdi-flip-to-front"
											// },
											{$template: "Separator"},
											{
												id: "exportarExcel",
												value: "Exportar a Excel",
												icon: "mdi mdi-download"
											},
											{$template: "Separator"},
											{
												id: "crearListaSeleccion",
												value: "crear Lista Selección",
												icon: "mdi mdi-list"
											}
										]
									}],
									on: {
										onMenuItemClick: id => {
											if (id === "impRutMen") {
												this.uploadAPI.fileDialog({
													rowid: id.row
												});
											} else if (id === "copyRutSec") {
												navigator.permissions.query({name: "clipboard-write"})
													.then(result => this.copyRutSec(result));
											} else if (id === "copyRutSecCorDir") {
												navigator.permissions.query({name: "clipboard-write"})
													.then(result => this.copyRutSecCorDir(result));
											} else if (id === "pasteRutSec") {
												navigator.permissions.query({name: "clipboard-read"})
													.then(result => this.pasteRutSec(result));
											} else if (id === "impArchivo") {
												this.importarArchivo();
											} else if (id === "calcularEstibas") {
												this.calcularEstibas();
											} else if (id === "exportarExcel") {
												this.exportarExcel();
											} else if (id === "crearListaSeleccion") {
												this.crearListaSeleccion();
											}
										}
									}
								}
							]
						},
						{
							type: "space",
							rows: [
								{
									view: "segmented",
									label: "Tipo Código",
									localId: "codType",
									options: [
										{id: 3, value: "Representante"},
										{id: 2, value: "Pedido"},
										{id: 1, value: "Ítem"},
									],
									click: () => this.loadRS()
								},
								{

									view: "text",
									label: "Código",
									localId: "codTxt",
									on: {
										onKeyPress: code => this.recCodTxtKeyPress(code)
									}

								},
							]
						},
						{
							view: "datatable",
							localId: "preRutDt2",
							resizeColumn: true,
							autoWidth: true,
							headermenu: true,
							//drag: "order",
							dragColumn: true,
							clipboard: "custom",
							select: "row",
							sort: "multi",
							editable: true,
							columns: [
								{id: "id", header: "Cód. Rep./Ped./Ítm."},
								{id: "rut_act", header: "Ruta", sort: "string", width: 80},
								{id: "sec_act", header: "Secuencia", sort: "int", width: 80},
								{id: "rep_cod", header: "Rep.Cód.", sort: "string", width: 80},
								{id: "zon", header: "Zona", sort: "string", width: 80},
								{id: "scc", header: "Sección", sort: "string", width: 80},
								{id: "dir", header: "Dirección", sort: "string", width: 150},
								{id: "ciu", header: "Ciudad", sort: "string", width: 80},
								{id: "bar", header: "Barrio", sort: "string", width: 80},
								{id: "ref", header: "Referencia", sort: "string", width: 80},
								{id: "es_cd", header: "Es CD?", sort: "string", width: 80},
								{id: "caj_tot", header: "Cajas Tot.", sort: "int", width: 80},
								{id: "rut_nor", header: "Ruta Norm.", sort: "string", width: 80},
								{id: "sec_nor", header: "Sec. Norm.", sort: "int", width: 80},
								{id: "esb", header: "Estiba", sort: "string", width: 80},
								{id: "pla", header: "Placa", sort: "string", width: 80},
								{id: "tags", header: "Etiquetas", collection: EtiquetasData, sort: "string", width: 80},
								//{id: "t_reg", header: "T Reg.", sort: "int"},
								{header: {content: "headerMenu"}, headermenu: false, width: 32}
							],
							// data: [{id: " "}, {id: "  "}, {id: "   "}],
							templateCopy: function (text, row, col) {
								return [row.id, row.rut_act, row.sec_act].join("\t");
							},
							on: {
								onPaste: text => this.onPaste(text),
								onBeforeLoad: function () {
									this.showOverlay("<img src='codebase/style/tdsx_logo_anim.svg' width='48' />");
								},
								onAfterLoad: function () {
									this.hideOverlay();
								},
								onSelectChange: () => {
									const row = this.$$("preRutDt2").getSelectedId();
									if (!row) return;
									const data = this.$$("preRutDt2").getItem(row.id);
									data.codType = this.$$("codType").getValue();
									this.app.callEvent("ruteo:select", [data]);
								},
							},

						}
					]
				},
				{
					view: "scrollview",
					width: 300,
					body: {
						type: "space",
						rows: [
							{
								$subview: PreRuteoForm, name: "preRuteoForm"
							},
							{
								view: "datatable",
								localId: "lisRutPedCajEstDt",
								footer: true,
								minHeight: 300, autoheight: true,
								columns: [
									{id: "rut_nor", header: "Ruta Norm.", sort: "string"},
									{
										id: "ped",
										header: "Pedidos",
										css: {"text-align": "right"},
										sort: "int",
										footer: {
											content: "summColumn",
											css: {"text-align": "right"}
										},
										fillspace: true
									},
									{
										id: "caj",
										header: "Cajas",
										css: {"text-align": "right"},
										sort: "int",
										footer: {content: "summColumn", css: {"text-align": "right"}},
										fillspace: true
									},
									{
										id: "esb",
										header: "Estibas",
										css: {"text-align": "right"},
										sort: "int",
										footer: {content: "summColumn", css: {"text-align": "right"}},
										fillspace: true
									},
								],
								gravity: 3,
								select: true,
								on: {
									onItemClick: id => {
										const item = this.$$("lisRutPedCajEstDt").getItem(id);
										this.$$("preRutDt2").filter(row => row.rut_nor === item.rut_nor);
									}
								}
							},
							{
								view: "datatable",
								localId: "lisPlaPedCajEstDt",
								footer: true,
								minHeight: 300, autoheight: true,
								columns: [
									{id: "pla", header: "Placa", sort: "string"},
									{
										id: "ped",
										header: "Pedidos",
										css: {"text-align": "right"},
										sort: "int",
										footer: {
											content: "summColumn",
											css: {"text-align": "right"}
										},
										fillspace: true
									},
									{
										id: "caj",
										header: "Cajas",
										css: {"text-align": "right"},
										sort: "int",
										footer: {content: "summColumn", css: {"text-align": "right"}},
										fillspace: true
									},
									{
										id: "esb",
										header: "Estibas",
										css: {"text-align": "right"},
										sort: "int",
										footer: {content: "summColumn", css: {"text-align": "right"}},
										fillspace: true
									},
								],
								gravity: 3,
								select: true,
								on: {
									onItemClick: id => {
										console.log(id, this.$$("lisRutPedCajEstDt").getItem(id));
									}
								}
							}

						]
					}

				}
			]
		};
	}

	init(view) {
		this.codTxt = this.$$("codTxt");

		this.uploadAPI = webix.ui({
			view: "uploader",
			localId: "uploadAPI",
			apiOnly: true,
			upload: "server/preruteo/importar.php?XDEBUG_SESSION_START=12345"
		});

		// this.on(this.app, "rutasecuencia:change", (values) => {
		// 	const preRuteoStore = this.preRuteoStore;
		// 	const preRutDt2 = this.$$("preRutDt2");
		// 	const ids = preRutDt2.getSelectedId(true, true);
		// 	let sec = parseInt(values.sec_nor, 10);
		// 	for (const id of ids) {
		// 		console.log("rutasecuencia:change", id, sec);
		// 		//let item = preRutDt2.getItem(id);
		// 		let item = preRuteoStore.getItem(id);
		// 		item.rut_nor = values.rut_nor;
		// 		item.sec_nor = values.sec_nor;
		// 		preRuteoStore.updateItem(id, item);
		// 		sec += values.sec_inc;
		// 	}
		// 	this.rutasTotalesRefresh();
		// });

		this.uploadAPI_onFileUploadError = this.uploadAPI.attachEvent("onFileUploadError", function (file, response) {
			webix.alert({
				type: "alert-error",
				title: "Error",
				text: response.msg
			});
		});

		this.uploadAPI_onUploadComplete = this.uploadAPI.attachEvent("onUploadComplete", function (response) {
			webix.message("<strong>Ruta Secuencias actualizadas</strong><br>" +
				"Representantes: " + response.info.rutSecRepUpd + "<br>" +
				"Items: " + response.info.rutSecItmUpd
			);
		});

		//UPLOADER IMPORTAR ARCHIVO LOCAL
		this.uploaderLocal = webix.ui({
			view: "uploader",
			localId: "uploaderLocal",
			apiOnly: true,
			autosend: false, //Solo se carga localmente
			on: {
				onBeforeFileAdd: (upload) => {
					var reader = new FileReader();
					reader.readAsArrayBuffer(upload.file);

					reader.onload = (e) => {
						var data = new Uint8Array(reader.result);
						var wb = XLSX.read(data, {type: "array"});
						//var htmlstr = XLSX.write(wb,{sheet:"Hoja de evaluación", type:"binary",bookType:"json"});
						//console.log(htmlstr);

						const idColumns = ["CODIGO", "N° PEDIDO", "CEDULA"];
						const codType = this.$$("codType").getValue();
						console.log("ID", codType - 1, idColumns[codType + 1]);

						wb.SheetNames.forEach((sheetName) => {
							if (sheetName === "PEDIDOS DESPACHADOS") {
								var XL_row_object = XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
								//TODO: Mapear columnas archivo con DataStore
								const columnsMap = {
									"id": idColumns[codType - 1],
									"rep_cod": "CEDULA",
									"rut_act": "RUTA",
									"sec_act": "SECUENCIA",
									"rut_nor": "RUTA",
									"sec_nor": "SECUENCIA",
									"zon": "ZONA",
									"scc": "SECCION",
									"dir": "DIRECCION",
									"bar": "BARRIO",
									"ciu": "CIUDAD",
									"caj_tot": "TOTAL CAJAS",
								};
								const data = XL_row_object.map(obj => {
									let row = {};
									for (const m of Object.keys(columnsMap)) {
										row[m] = obj[columnsMap[m]];
									}
									return row;
								});

								const json_data = JSON.stringify(data);
								this.$$("preRutDt2").parse(json_data);
								this.rutasTotalesRefresh();
								this.placasTotalesRefresh();
							} else {
								console.log("IDENTIFICAR ENCABEZADO");
								//SheetJS BUG? A1:E1 No funciona (Si la fila inicial y final es la misma devuelve array vacío)
								//Funciona cuando se pasa el parámetro header:1
								const file_header = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
									range: "A1:F1",
									header: 1
								})[0];

								const header = ["EMPRESA ID", "PEDIDO", "RUTA", "SECUENCIA", "PLACA", "ETIQUETAS"];
								if (Array.isArray(file_header) &&
									Array.isArray(header) &&
									file_header.length === header.length &&
									file_header.every((val, index) => val === header[index])) {
									let data = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
									//Detectar duplicados
									const finalRow = XLSX.utils.decode_range(wb.Sheets[sheetName]["!ref"]).e.r;
									const codigos = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
										range: "B2:B" + finalRow,
										header: 1
									}).map(o => o[0]);
									const duplicados = this.findDuplicates(codigos);
									if (duplicados.length > 0) {
										webix.alert({
											title: "Códigos duplicados",
											text: duplicados.join("<br>"),
											type: "alert-error"
										});
										return;
									}

									const columnsMap = {
										"id": "PEDIDO",
										"rut_nor": "RUTA",
										"sec_nor": "SECUENCIA",
										"pla": "PLACA",
										"tags": "ETIQUETAS"
									};
									data = data.map(obj => {
										let row = {};
										for (const m of Object.keys(columnsMap)) {
											row[m] = obj[columnsMap[m]];
										}
										return row;
									});
									const json_data = JSON.stringify(data);
									this.$$("preRutDt2").parse(json_data);
									this.rutasTotalesRefresh();
									this.placasTotalesRefresh();
								}
							}
						});
					};
				}
			}

		});

		this.use(plugins.UnloadGuard, () => {
			webix.message("Guardando PreRuteo local");
			const state = this.$$("preRutDt2").getState();
			webix.storage.local.put("preRutDt_state", state);
			webix.storage.local.put("preRutDt_data", this.$$("preRutDt2").serialize(true));
		});
	}

	_asignarRutaSecuencia(values) {
		const dt = this.$$("preRutDt2");
		let ids = [];
		ids.push(dt.getFirstId());
		let rut = dt.getItem(dt.getFirstId()).rut_nor;

		dt.data.each(row => {
			if (rut === row.rut_nor) {
				ids.push(row.id);
			} else {
				console.log(rut, ids);
				console.log("Procesar ids");
				ids = [row.id];
				rut = row.rut_nor;
			}
		});
		//Procesar ids finales
		console.log(rut, ids);
		console.log("Procesar ids");
	}

	asignarRutaSecuencia(values) {
		let ids = this.$$("preRutDt2").getSelectedId(true, true);
		let sec_nor = parseInt(values.sec_nor, 10);
		console.time("AsignarRutaSecuencia");
		this.$$("preRutDt2").data.blockEvent(); //Evita que la lista sea actualizada por cada actualización de un Item
		//Se invierte el arreglo para asignar las Estibas sin calcular primero el total de Cajas evitando doble ciclo
		ids = ids.reverse();
		let c = 0;
		sec_nor += values.sec_inc * (ids.length - 1);
		for (let id of ids) {
			const item = this.$$("preRutDt2").getItem(id);
			const esb = String.fromCharCode(65 + (c / values.caj_x_estiba));
			c += parseInt(item.caj_tot, 10);
			console.timeLog("AsignarRutaSecuencia", id, sec_nor, esb);
			this.$$("preRutDt2").updateItem(id, {rut_nor: values.rut_nor, sec_nor, c, esb});
			sec_nor -= values.sec_inc;
		}
		this.$$("preRutDt2").data.unblockEvent();
		this.$$("preRutDt2").refresh();
		console.timeEnd("AsignarRutaSecuencia");
		this.rutasTotalesRefresh();
		this.placasTotalesRefresh();
	}

	urlChange(_$view, _$url) {
		const lisId = this.getParam("lisId");
		if (lisId) {
			this.$$("preRutDt2").clearAll();
			this.$$("codType").setValue(2);
			this.$$("preRutDt2").load(`server/preruteo/getRutSecByLis.php?lisId=${lisId}`)
				.then(() => {
					this.rutasTotalesRefresh();
					this.placasTotalesRefresh();
				});
		} else {
			//TODO: Revisar al parecer falla cuando hay columnas ocultas
			//const state = webix.storage.local.get("preRutDt_state");
			//console.log("state", state);
			//if (state) this.$$("preRutDt2").setState(state);

			const data = webix.storage.local.get("preRutDt_data");
			console.log("data", data);
			if (data) this.$$("preRutDt2").parse(data);

			this.rutasTotalesRefresh();
			this.placasTotalesRefresh();
		}
	}

	findDuplicates(codigos) {
		let duplicados = codigos.filter((el, index) => codigos.indexOf(el) !== index);
		//return [...new Set(duplicados)] TODO: Revisar porque no sirve el operador spread (...)
		duplicados = new Set(duplicados);
		return Array.from(duplicados);
	}

	recCodTxtKeyPress(code) {
		if (code !== 13) {
			return;
		}

		this.codTxt.config.value = this.codTxt.getInputNode().nodeValue;
		code = this.codTxt.getValue().trim();
		// Ignorar caracteres especiales en ciertas configuraciones de terminaciones en el scanner
		if (code.length === 0) {
			return;
		}
		this.codTxt.setValue("");
		this.onPaste(code);
	}

	onPaste(text, clear = false) {
		const codigos = text.replaceAll(/\s/g, ","); // \s incluye espacios en blanco, tabs, nuevas líneas y retornos
		// const codigos = text.replaceAll("\n", ",");
		//const data = codigos.split(",").map(v => Object({id: parseInt(v), t_reg: new Date().getTime()}));
		const data = codigos.split(",");
		const duplicados = this.findDuplicates(data);
		if (duplicados.length > 0) {
			webix.alert({
				title: "Códigos duplicados",
				text: duplicados.join("<br>"),
				type: "alert-warning"
			});
		}
		if (clear) this.$$("preRutDt2").clearAll();
		if (data.length > 1) {
			this.$$("preRutDt2").parse(data);
		} else {
			this.$$("preRutDt2").add({id: data}, 0);
		}
		//this.$$("preRutDt2").sort("t_reg", "desc");
		this.loadRS(codigos);
	}

	getCodsCsv() {
		let cods = "";
		this.$$("preRutDt2").data.each(row => cods += row.id + ",");
		return cods.slice(0, -1);
	}

	loadRS(cods) {
		if (!cods) cods = this.getCodsCsv();
		//this.$$("preRutDt2").showOverlay("<img src='codebase/style/tdsx_logo_anim.svg' width='48' />");
		const codType = this.$$("codType").getValue();
		// this.$$("preRutDt2").load(`server/preruteo/getRutSecByCod.php?codType=${codType}&cods=${cods}`)
		// 	.then(() => this.rutasTotalesRefresh());
		webix.ajax().post("server/preruteo/getRutSecByCod.php", {codType, cods})
			.then((response) => {
				//this.$$("preRutDt2").parse(response.json());
				response = response.json();
				//this.$$("preRutDt2").parse(response);
				this.$$("preRutDt2").parse(response);
				this.rutasTotalesRefresh();
				this.placasTotalesRefresh();
			});
		//this.$$("preRutDt2").load(() => webix.ajax().post("server/preruteo/getRutSecByCod.php", {codType, cods}));
	}

	copyRutSec(result) {
		if (result.state === "granted" || result.state === "prompt") {
			let cb = "CÓDIGO\tRUTA\tSECUENCIA\n";
			this.$$("preRutDt2").data
				.each(row => cb += [row.id, row.rut_act, row.sec_act].join("\t") + "\n");
			navigator.clipboard.writeText(cb);
		}
	}

	copyRutSecCorDir(result) {
		if (result.state === "granted" || result.state === "prompt") {
			let cb = "CÓDIGO\tRUTA\tSECUENCIA\tZONA\tSECCIÓN\tDIRECCIÓN\tCIUDAD\tBARRIO\tTELÉFONO\tREFERENCIA\n";
			this.$$("preRutDt2").data
				.each(row => cb += [row.id, row.rut_act, row.sec_act,
					row.cd_zon, row.cd_scc, row.cd_dir, row.cd_ciu, row.cd_bar, row.cd_tel, row.cd_ref].join("\t") + "\n");
			navigator.clipboard.writeText(cb);
		}
	}

	pasteRutSec(result) {
		if (result.state == "granted" || result.state == "prompt") {
			navigator.clipboard.readText()
				.then(text => {
					this.onPaste(text);
				})
				.catch(err => {
					console.log("Something went wrong", err);
				});
		}
	}

	rutasTotalesRefresh() {
		const totxrut = new Map();
		this.$$("preRutDt2").data.each(function (o) {
			if (!totxrut.has(o.rut_nor)) totxrut.set(o.rut_nor, {ped: 0, caj: 0});
			totxrut.get(o.rut_nor).ped++;
			totxrut.get(o.rut_nor).caj += parseInt(o.caj_tot);
		}, this, true);
		const rutasData = [];
		for (const [k, v] of totxrut) {
			const esb = v.caj / 25;
			rutasData.push({rut_nor: k, ped: v.ped, caj: v.caj, esb});
		}
		// const lisRutPedCajEstDt = (this.getRoot().queryView({localId:"lisRutPedCajEstDt"}) as webix.ui.list);
		this.$$("lisRutPedCajEstDt").clearAll();
		this.$$("lisRutPedCajEstDt").parse(JSON.stringify(rutasData), "json");
		this.$$("lisRutPedCajEstDt").sort("rut_nor", "asc");
	}

	placasTotalesRefresh() {
		const totxpla = new Map();
		this.$$("preRutDt2").data.each(function (o) {
			if (!totxpla.has(o.pla)) totxpla.set(o.pla, {ped: 0, caj: 0});
			totxpla.get(o.pla).ped++;
			totxpla.get(o.pla).caj += parseInt(o.caj_tot);
		}, this, true);
		const rutasData = [];
		for (const [k, v] of totxpla) {
			const esb = v.caj / 25;
			rutasData.push({pla: k, ped: v.ped, caj: v.caj, esb});
		}
		this.$$("lisPlaPedCajEstDt").clearAll();
		this.$$("lisPlaPedCajEstDt").parse(JSON.stringify(rutasData), "json");
		this.$$("lisPlaPedCajEstDt").sort("pla", "asc");
	}

	destroy() {
		super.destroy();
		this.uploadAPI.detachEvent(this.uploadAPI_onUploadComplete);
		this.uploadAPI.detachEvent(this.uploadAPI_onFileUploadError);
	}

	importarArchivo() {
		this.uploaderLocal.fileDialog();
	}

	calcularEstibas() {

	}

	exportarExcel() {
		webix.toExcel(this.$$("preRutDt2"));
	}

	crearListaSeleccion() {
		const codType = this.$$("codType").getValue();
		const cods = this.getCodsCsv();
		webix.prompt({
			title: "Lista Personalizada",
			text: "Escriba el nombre que desea asignar a la Lista",
			//ok: "Submit",
			//cancel: "Cancel",
			input: {
				required: true,
				//placeholder:"Siamese, Maine Coon, Sphynx...",
			},
			width: 350,
		}).then(function (nom) {
			webix.ajax().post("server/listasseleccion/crear.php?XDEBUG_SESSION_START=12345", {codType, cods, nom})
				.then((response) => {
					response = response.json();
					console.log(response);
				});
		});

	}
}