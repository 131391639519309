import {JetView} from "webix-jet";

export default class ListadosRutas extends JetView {
	// lisPedLis: webix.ui.list;
	// rutFrm: webix.ui.form;
	// rutAplicarBtn: webix.ui.button;
	// lisRutSugLis: webix.ui.list;
	// lisRutPedDt: webix.ui.list;
	// private filterTxt: webix.ui.text;

	config() {
		return {
			view: "headerlayout",
			cols: [
				{
					rows: [
						{
							view: "toolbar",
							elements: [
								{
									view: "icon",
									localId: "backBtn",
									icon: "mdi mdi-arrow-left"
								},
								{
									view: "label", label: "Listado"
								},
								{},
								// {
								// 	view: "icon", icon: "mdi mdi-file-upload",
								// 	// css: "app_button",
								// 	tooltip: "Marcar Boletas (PDF)",
								// 	click() {
								// 		$$("lisUpl").fileDialog();
								// 	}
								// },
								{
									view: "toggle",
									localId: "selBtn",
									type: "icon",
									icon: "mdi mdi-checkbox-multiple-marked-outline",
									tooltip: "Selección Múltiple",
									width: 40
								},
								{
									view: "menu", openAction: "click", width: 40, /*subMenuPos: "bottom",*/
									autowidth: true,
									data: [{
										icon: "mdi mdi-dots-vertical",
										submenu: [
											{id: "ordRuta", value: "Ordenar x Ruta", icon: "mdi mdi-upload"},
											{
												id: "ordCiuBar",
												value: "Ordenar x Ciudad / Barrio",
												icon: "mdi mdi-download"
											},
											// {$template:"Separator"}
										]
									}],
									on: {
										onMenuItemClick: id => {
											if (id === "ordRuta") {
												this.lisPedLis.sort("#rut_nor#");
											} else if (id === "ordCiuBar") {
												this.lisPedLis.sort(function (a, b) {
													if (a.ciu === b.ciu)
														return (a.bar > b.bar) ? 1 : -1;
													else
														return (a.ciu > b.ciu) ? 1 : -1;
												});
											}
										}
									}
								}
							]
						},
						{view: "search", localId: "filterTxt"},
						{
							view: "dataview",
							localId: "lisPedLis",
							template: "<div class='ruta_sec'><div class='ruta'>#rut_nor#</div>#sec#</div>" +
								"<div class='ped_info'>#id# - #ciu# - #zon#<br>#bar#<br><span style='font-size: smaller'>#dir#</span></div>",
							item: {
								width: 220, height: 80
							},
							autowidth: true,
							// sizeToContent: true,
							select: true,
							multiselect: true, // "touch",
							// datafetch:60,
							// loadahead:120,
							// pager: "pagerA",
							// scrollSpeed:"100ms",
							click: id => this.lisPedLisClick(id)
						},
						// {
						// 	view:"pager", id:"pagerA",
						// 	size:20,
						// 	group:5,
						// 	animate:{
						// 		// type:"slide",
						// 		// subtype: "together"
						// 		direction: "bottom"
						// 	}
						// }
					]
				},
				{view: "resizer"},
				{
					width: 250,
					rows: [
						{
							view: "toolbar", elements: [
								// {
								// 	view: "button",
								// 	label: "Agregar",
								// 	type: "iconButton",
								// 	icon: "wxi-plus",
								// 	// click: () => this.agregarClick()
								// },
								{},
								{
									view: "icon",
									tooltip: "Auto Asignar Rutas",
									localId: "autoBtn",
									// type: "icon",
									autowidth: true,
									icon: "mdi mdi-arrow-decision-auto",
									click: () => this.autoBtnClick()
								},
								// {
								// 	view: "button",
								// 	label: "Registrar Barrio",
								// 	type: "iconButton",
								// 	autowidth: true,
								// 	icon: "plus-circle",
								// 	click: () => this.registrarBarrio()
								// }
							]
						},
						{
							view: "form",
							localId: "rutFrm",
							// width: 250,
							elementsConfig: {
								labelPosition: "top"
							},
							elements: [
								{
									cols: [
										{view: "text", name: "rut_nor", label: "Ruta", width: 50},
										{view: "text", name: "bar", label: "Barrio"}
									]
								},
								{
									view: "button", localId: "rutAplicarBtn", label: "Aplicar",
									click: id => this.rutAplicarBtnClick(id)
								}
							]
						},
						{
							view: "list",
							localId: "lisRutSugLis",
							template: "#rut# - #bar#",
							height: 180,
							select: true,
							click: id => this.lisRutSugLisClick(id)
						},
						{
							view: "datatable",
							localId: "lisRutPedDt",
							columns: [
								{id: "rut_nor", header: "Ruta Norm.", sort: "string"},
								{id: "c", header: "Total Pedidos", sort: "int", fillspace: true}
							],
							gravity: 3,
							select: true,
							on: {
								onItemClick: id => this.lisRutPedDtClick(id)
							}
						}
					]
				}]
		};
	}

	init(view) {
		this.lisPedLis = view.queryView({localId: "lisPedLis"});
		this.rutFrm = view.queryView({localId: "rutFrm"});
		// this.rutAplicarBtn = view.queryView({localId: "rutAplicarBtn"});
		this.lisRutSugLis = view.queryView({localId: "lisRutSugLis"});
		this.lisRutPedDt = view.queryView({localId: "lisRutPedDt"});

		this.filterTxt = view.queryView({localId: "filterTxt"}); // as webix.ui.text;
		this.filterTxt.attachEvent("onTimedKeyPress", () => this.filterLisPedLst());
		const backBtn = view.queryView({localId: "backBtn"}); //as webix.ui.button;

		backBtn.attachEvent("onItemClick", () => {
			view.getParentView().back();
		});

		this.filterTxt = view.queryView({localId: "filterTxt"}); // as webix.ui.text;

		const selBtn = view.queryView({localId: "selBtn"}); // as webix.ui.toggle;
		selBtn.attachEvent("onItemClick", () => {
			this.lisPedLis.define("multiselect", parseInt(selBtn.getValue(), 10) === 1 ? "touch" : true);
		});

		// this.lisRutPedDt.attachEvent("onBlur", pview => {
		// 	this.lisRutPedDt.unselectAll();
		// 	this.filterTxt.setValue("");
		// 	this.lisPedLis.filter(null, null);
		// });

		// const autoBtn = view.queryView({localId: "autoBtn"}) as webix.ui.button;
		// autoBtn.attachEvent("onItemClick", () => this.autoBtnClick());
	}

	autoBtnClick() {
		const lisId = this.getRoot().getParentView().queryView({localId: "lisLis_dt"}).getSelectedItem(false).id;
		webix.ajax().get("server/Listados/setRutasAuto.php", {lisId}, res => {
			webix.message("Se asignaron rutas a " + res + " pedidos");
			this.showListado(lisId);
		});
	}

	lisPedLisClick(id) {
		this.lisRutSugLis.clearAll();
		this.lisRutSugLis.load("server/Listados/barxped.php?ped_id=" + id + "&XDEBUG_SESSION_START=12345");
		this.rutFrm.setValues(this.lisPedLis.getItem(id));

		// const ped = this.lisPedLis.getItem(id);
		// webix.ajax().get("server/Listados/barxped.php",
		// 	{empId: ped.emp_id, zon: ped.zon, bar: ped.bar},
		// 	res => {
		// 		console.log(res);
		// 	}
		// );
	}

	rutAplicarBtnClick(id) {
		const rut_nor = this.rutFrm.elements.rut_nor.getValue();
		this.lisPedLis.getSelectedItem(false).rut_nor = rut_nor;
		this.lisPedLis.refresh(this.lisPedLis.getSelectedItem(false).id);
		// this.rutasRefresh();
		this.setRuta(rut_nor);
	}

	lisRutSugLisClick(id) {
		const rut_nor = this.lisRutSugLis.getItem(id).rut;
		this.rutFrm.setValues({rut_nor});
		this.setRuta(rut_nor);
	}

	showListado(id) {
		this.lisPedLis.clearAll();
		this.lisRutPedDt.clearAll();
		this.lisPedLis.load("server/Listados/ruteo.php?lis_id=" + id, "json", function () {
			this.$scope.rutasRefresh();
			this.$scope.getRoot().show();
		});
	}

	setRuta(rut_nor) {
		const items = this.lisPedLis.getSelectedItem(true);
		if (items.length === 0) {
			return;
		}

		const pedIds = [];
		for (const item of items) {
			pedIds.push([item.via_id, item.id]);
			item.rut_nor = rut_nor;
			this.lisPedLis.refresh(item.id);
			this.rutasRefresh();
		}
		webix.ajax().post("server/Listados/setRuta.php", {rut_nor, ped_id: JSON.stringify(pedIds)},
			function (txt, res) {
				const r = res.json();
				if (r.status === "success") {
					const pedStr = (r.c === 1) ? " pedido." : " pedidos.";
					webix.message("Se asignó la ruta " + r.rut_nor + " a " + r.c + pedStr);
				} else {
					webix.alert(r.error);
				}
			}
		);
	}

	rutasRefresh() {
		// const lisPedLis = this.getRoot().queryView({localId:"lisPedLis"}) as webix.ui.list;
		const pedxrut = [];
		this.lisPedLis.data.each(function (o) {
			if (pedxrut[o.rut_nor] === undefined) {
				pedxrut[o.rut_nor] = 0;
			}
			pedxrut[o.rut_nor]++;
		});
		const rutasData = [];
		for (const r in pedxrut) {
			rutasData.push({rut_nor: r, c: pedxrut[r]});
		}
		// const lisRutPedDt = (this.getRoot().queryView({localId:"lisRutPedDt"}) as webix.ui.list);
		this.lisRutPedDt.clearAll();
		this.lisRutPedDt.parse(JSON.stringify(rutasData), "json");
		this.lisRutPedDt.sort("rut_nor", "asc");
	}

	filterLisPedLst() {
		const txt = this.filterTxt.getValue().toUpperCase();
		if (txt.length === 0) {
			this.rutFrm.clear();
			this.lisRutSugLis.clearAll();
			this.lisRutPedDt.unselectAll();
			this.lisPedLis.filter(null, null);
			this.rutasRefresh();
			return;
		}
		this.lisPedLis.filter(obj => {
			if (txt.length <= 2) {
				return obj.rut_nor == txt;
			} else {
				return obj.bar.indexOf(txt) !== -1
					|| obj.ciu.indexOf(txt) !== -1
					|| obj.zon == txt;
			}
		}, null);
	}

	lisRutPedDtClick(id) {
		const rut_nor = this.lisRutPedDt.getItem(id).rut_nor;
		this.filterTxt.setValue(rut_nor);
		this.filterLisPedLst();
	}

	registrarBarrio() {
		//TODO: Implementar
	}
}