import Box from "jet-views/obt/Box";

export default class AreaEstiba extends Box {

	constructor(params = {}) {
		super(params);
		this._params.id = params.id || ""; //TODO: No puede ser vacío
		this._params.fill = params.fill || "#fff";
		this._params.stroke = params.fill || "#ffcc00";
		this._params.strokeWidth = params.strokeWidth || 4;
		this._params.text = params.text || "";
	}

	build(){
		super.build();
		this._svg.setAttributeNS(null,"data-id", this._params.id);
		let label = document.createElementNS(this._ns, "text");
		label.setAttribute("pointer-events", "none");
		label.setAttributeNS(null,"x", this._params.width / 2);
		label.setAttributeNS(null,"y", this._params.height / 2);
		label.setAttributeNS(null,"text-anchor","middle");
		label.setAttributeNS(null,"fill","#ffcc00");
		label.setAttributeNS(null,"font-size","20px");
		label.setAttributeNS(null,"font-weight","bold");
		let textNode = document.createTextNode(this._params.text);
		label.appendChild(textNode);
		this._svg.appendChild(label);
		return this._svg;
	}
}