import {JetView} from "webix-jet";
import {filtersToQueryString} from "libs/utils";
import {tablasdinamicas} from "../../models/tablasdinamicas";

export default class ItemsPivot extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Tabla Dinámica"},
						{},
						{
							view: "icon", icon: "mdi mdi-export",
							click: () => {
								webix.toExcel(this.$$("itemsPvt"), {
									flatTree: {
										id: "value",
										fill: true
									}
								});
							}
						},
						{
							view: "select",
							localId: "structuresSel",
							options: "server/pivot/tablasDinamicas.php",
							on: {
								onChange: (id) => {
									const data = JSON.parse(this.$$("structuresSel").data.options.getItem(id).def);
									this.$$("itemsPvt").setStructure(data);
								}
							}
						}
					]
				},
				{
					view: "pivot",
					localId: "itemsPvt",
					id: "itemsPvt",
					fieldMap: {
						rutNor: "Ruta Norm.",
						tam: "Tamaño",
						fecPrm: "Fecha Promesa",
						cod: "Ítem Cód.",
						ped: "Pedido",
						rep: "Representante",
						repId: "Representante Cód.",
						ciu: "Ciudad",
						bar: "Barrio",
						dir: "Dirección",
						cajNum: "Caja Nº",
						cam: "Campaña",
						yyyy: "Año",
						yyyycam: "AñoCampaña",
						cel: "Celular",
						des: "Ítem Descripción",
						via: "Viaje",
						viaId: "Viaje ID",
						zon: "Zona",
						ind: "Indicador",
						tel: "Teléfono",
						desId: "Ítem Descripción ID",
						estCod: "Estado Cód.",
						estId: "Estado ID",
						lisId: "Listado ID",
					},
					// popup: {
					// 	on: {
					// 		onViewInit: (name, config) => {
					// 			if (name == "fieldsLayout")
					// 				config.rows.splice(1, 0, {
					// 					id: "fieldsFilter",
					// 					css: "fields_search",
					// 					view: "search",
					// 					on: {
					// 						onTimedKeyPress: function () {
					// 							var value = this.getValue().toLowerCase();
					// 							$$("itemsPvt").getConfigWindow().$$("fields").filter("name", value);
					// 						}
					// 					}
					// 				});
					// 		}
					// 	}
					// }
				}
			]
		};
	}

	init() {
		let filters = {};
		const urls = this.getParentView().getUrl();
		for (let url of urls) {
			filters = Object.assign(filters, url.params);
		}
		this.$$("itemsPvt").define("url", "server/items/get2.php?" + filtersToQueryString(filters));

		this.$$("itemsPvt").$$("data").attachEvent("onItemClick", function () {
			var id = arguments[0];
			//console.log("ID:" + id, p2, p3);
			console.log(arguments);
			var item = this.getItem(id);
			var pivot = this.getTopParentView();

			var source = item.$source;
			if (source) {
				var raw = pivot.data.getItem(source[0]);
				webix.message("Based on " + source.length + " raw records. Base country: " + raw.id);
			} else {
				//group row, collect all sources
				var source = [];
				this.data.eachSubItem(id, function (obj) {
					if (obj.$source) source.push.apply(source, obj.$source);
				});
			}

			var data = [].concat(source).map(id => pivot.data.getItem(id));
			console.log(data);
		});
	}

}