import {JetView} from "webix-jet";
import ToolView from "views/toolbar";
import MenuView from "views/menu";
import {io} from "socket.io-client";
import {getItemId} from "../models/items";
import ChangePasswordWindow from "jet-views/ChangePasswordWindow";

export default class TopView extends JetView {

	config() {

		// const screen = this.app.config.size;
		// const onloadstr = `if (document.getElementsByClassName('sidebar').length > 0) {
		// 	document.getElementsByClassName('sidebar')[0].style.transform = 'translate3d(0px, 0px, 0px)';
		// 	document.getElementsByClassName('sidebar')[0].style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 0px 15px 1px';
		// 	document.getElementsByClassName('sidebar-wrap')[0].style.width = '100%';
		// 	document.getElementsByClassName('sidebar-wrap')[0].style.backgroundColor = 'rgb(0,0,0)';
		// }`;

		const small = {
			rows: [
				ToolView,
				{
					cols: [
						{
							rows: [
								{id: "app-logo", css: "app-logo", height: 50},
								MenuView,
								{css: "tdsx-logo", height: 50}
							]
						},
						{
							// type: "space",
							// width: 320,
							cols: [
								{$subview: true}
							]
						}
					]
				}
			]
		};


		const wide = {
			rows: [
				ToolView,
				{
					cols: [
						{
							rows: [
								{id: "app-logo", css: "app-logo", height: 50},
								MenuView,
								{css: "tdsx-logo", height: 50}
							]
						},
						{
							// type: "space",
							// width: 320,
							cols: [
								{$subview: true}
							]
						},
						{
							view: "resizer"
						},
						{
							id: "chatView",
							// view: "iframe", src: "https://chat.tdsx.app",
							// 					var t = $$(this.parentNode.getAttribute("view_id")); if (t) t.callEvent("onAfterLoad",[]);

							// template: `<iframe id='chatFrame' src='https://chat.tdsx.app' onload="${onloadstr}" allow='microphone; camera; fullscreen' style='width:100%; height:100%' frameborder='0'></iframe>`,
							template: `<iframe id='chatFrame' src='https://chat.tdsx.app' allow='microphone; camera; fullscreen' style='width:100%; height:100%' frameborder='0'></iframe>`,
							type: "clean",
							width: 60
						}
					]
				}
			]
		};

		return this.app.config.withChat ? wide : small;
	}

	init() {
		// const lang = this.app.getService("locale").getLang();
		// if (lang !== "en") {
		// 	const langs = getLangsList();
		// 	const country = langs.find(l => l.id === lang).code;
		// 	webix.i18n.setLocale(lang + "-" + country);
		// }

		if (this.app.config.withChat) {
			window.addEventListener("message", e => this.chatEventHandler(e));
		}

		this.user = this.app.getService("user").getUser();

		this.socket = io(this.user.TDSX_SOCKET_CLIENT_URL);
		this.socket.on("connect", () => {
			console.log("ON CONNECT - socket.id:" + this.socket.id);
		});
		this.socket.on("item-change", data => {
			//console.log("ITEM-CHANGE", data);
			this.app.callEvent("item:change", [data]);
		});

		this.on(this.app, "escaneo:codigo", (data) => this.emitEvent(data));

		this.changePasswordWin = this.ui(ChangePasswordWindow);
	}

	emitEvent(data) {
		console.log("TOP SOCKET EMIT", data);
		data.itmId = getItemId(data.cod);
		data._c = document.cookie;
		this.socket.emit("escaneo", data);
	}

	chatEventHandler(e) {
		if (e.origin !== "https://chat.tdsx.app") return;

		if (e.data.eventName === "unread-changed") {
			console.log("chat:unreadchanged");
			this.app.callEvent("chat:unreadchanged", [e.data.data]);
		}
	}

	urlChange() {
		console.log("TOP urlChange");
	}

	ready() {
		if(this.user.chn_pas) this.changePasswordWin.showWindow({hideCloseBtn: true});
	}

}
