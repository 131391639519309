import {JetView} from "webix-jet";

export default class Items extends JetView {
	config() {
		return {
			id: "col2",
			minWidth: 250,
			rows: [
				{
					view: "toolbar", elements: [
						{
							view: "icon", icon: "mdi mdi-arrow-left",
							click: () => {
								if (!this.$$("col1").isVisible()) {
									this.$$("col2").hide();
									this.$$("col1").show();
								}
								this.codTxt.focus();
							}
						},
						{view: "label", label: "Detalle"},
						{
							view: "icon",
							icon: "mdi mdi-arrow-right-bold-box",
							// icon:"mdi mdi-transfer",
							// icon:"mdi mdi-cube-send",
							tooltip: "Listado -> Recepción"
						}
					]
				},
				{
					view: "search", localId: "filterTxt",
					on: {
						onTimedKeyPress: () => this.filterItemsLst()
					}
				},
				{
					view: "list",
					localId: "itemsLst",
					gravity: 1,
					template(obj) {
						const warning = (obj.est_id === 2 && (obj.rut_nor !== obj.rut_esc)) ? "rojo" : "";
						obj.rut_esc = obj.rut_esc || "";
						return "<div class='ruta_sec " + warning + "'><div class='ruta'>" + obj.rut_nor + "</div>" + obj.rut_esc + "</div>" +
							"<div class='caj_info'>" +
							"<strong>" + obj.rep + "</strong><br>" +
							obj.id + "<br>" +
							obj.caj_num + " " + obj.est_id + "<br>" +
							obj.tam + " " + obj.est_id + " " + obj.des +
							"</div>";
					},
					type: {
						height: 80
					},
					select: true,
					on: {
						onSelectChange: () => this.itemsDtSelectChange()
					},
					save: "server/recepcion/save.php"
				}
				// {
				// 	view: "datatable",
				// 	localId: "itemsLst",
				// 	gravity: 1,
				// 	columns: [
				// 		{
				// 			template(obj) {
				// 				const warning = (obj.est_id === 2 && (obj.rut_nor !== obj.rut_esc)) ? "rojo" : "";
				// 				obj.rut_esc = obj.rut_esc || "";
				// 				return "<div class='ruta_sec " + warning + "'><div class='ruta'>" + obj.rut_nor + "</div>" + obj.rut_esc + "</div>" +
				// 					"<div class='caj_info'>" +
				// 					"<strong>" + obj.rep + "</strong><br>" +
				// 					obj.id + "<br>" +
				// 					obj.caj_num + " " + obj.est_id + "<br>" +
				// 					obj.tam + " " + obj.est_id + " " + obj.des +
				// 					"</div>";
				// 			},
				// 			fillspace: true
				// 		}
				// 	],
				// 	// type: {height: 60},
				// 	rowHeight: 80,
				// 	// rowLineHeight: 20,
				// 	header: false,
				// 	select: true,
				// 	scrollX: false,
				// }
			]
		};

	}

	init() {
		this.on(this.app, "escaneo:codigo", (data) => this.procesarCodigo(data));
		this.on(this.app, "items:filter", filters => this.applyFilters(filters));
	}

	urlChange(view) {
		const dscId = this.getParam("dscId", true);
		console.log("URLCHANGE ITEMS", this.getParam("dscId"));
		this.itemsLst = this.$$("itemsLst");
		this.itemsLst.load("server/recepcion/dscitems.php?dsc_id=" + dscId, "json",
			response => {
				this.getInfoData(true);
			}
		);
	}

	filterItemsLst() {
		const txt = this.$$("filterTxt").getValue().toUpperCase();
		if (txt.length === 0) {
			this.itemsLst.filter(null, null);
			return;
		}

		this.itemsLst.filter(obj => {
			if (txt.length <= 2) {
				return obj.rut_nor === txt;
			} else {
				return obj.rep.toString().indexOf(txt) !== -1
					|| obj.id.toString().indexOf(txt) !== -1
					|| obj.ciu.toString().indexOf(txt) !== -1
					|| obj.bar.toString().indexOf(txt) !== -1
					|| obj.zon.toString() === txt;
			}
		}, null);
	}

	procesarCodigo(data) {
		console.log("PROCESAR CODIGO");
		const item = this.itemsLst.data.getItem(data.cod);
		if (item) {
			this.itemsLst.updateItem(data.cod, data);
			this.app.callEvent("items:pedido:data", [item, data]);
			this.getInfoData();
		} else {
			webix.message("Código no registrado " + data.cod);
		}
		// this.actualizarDetalleCantidades(cod);
		// this.mostrarErrores();
		this.infoPedido(data.cod);
	}


	mostrarErrores() {
		this.itemsLst.filter(function (obj) {
			return obj.rut_esc && obj.rut_esc !== obj.rut_nor;
		}, "");
	}

	actualizarDetalleCantidades(cod) {
		const item = this.itemsLst.getItem(cod);
		const itemDetalle = this.infoDt.getItem(item.des_id);
		const l = this.itemsLst.find(function (obj) {
			return obj.est_id === 1 && itemDetalle.id === obj.des_id;
		});

		const dif = this.infoDt.getItem(this.itemsLst.getItem(cod).des_id).l - l.length;

		this.infoDt.getItem(item.des_id).l = parseInt(itemDetalle.l, 10) - dif;
		this.infoDt.getItem(item.des_id).r = parseInt(itemDetalle.r, 10) + dif;
		this.infoDt.refresh(item.des_id);
		this.infoDt.clearSelection();
	}

	itemsDtSelectChange() {
		this.infoPedido(this.itemsLst.getSelectedId(false));
	}

	infoPedido(cod) {
		const item = this.itemsLst.getItem(cod);
		if (item === undefined) {
			return;
		}

		const items = new Map();
		this.itemsLst.data.each(obj => {
			if (obj.ped === item.ped) {
				if (!items.has(obj.des_id)) {
					items.set(obj.des_id, {id: obj.des_id, des: obj.des, ped: obj.ped, l: 0, r: 0});
				}
				if (obj.est_id === 1) {
					items.get(obj.des_id).l++;
				}
				if (obj.est_id === 2) {
					items.get(obj.des_id).r++;
				}
			}
		}, this, true);

		console.log("CALLEVENT items:pedido:data");
		this.app.callEvent("items:pedido:data", [item, Array.from(items.values())]);
	}

	applyFilters(filters) {
		this.itemsLst.filter(
			itm => {
				let r = true;
				for (const f of filters) {
					r = r && (itm[f["k"]] === f["v"]);
				}
				return r;
			}
		);
	}

}