const EtiquetasData = new webix.DataCollection({
	url: "server/etiquetas/list.php"
});

export default EtiquetasData;

export const buildTags = function (tags, fontSize="xx-small") {
	if(tags === undefined || tags === null || tags === "" || tags === []) return "";
	//const ts = tags.split(",").map(itm => parseInt(itm));
	console.log(tags);
	return tags.reduce((p,c) => {
		const etq =EtiquetasData.getItem(c);
		return p += "<span style='color:white; padding: 2px; margin-right: 2px; font-size:" + fontSize + "; font-weight: bold; background-color: #" + etq.color + "'>" + etq.value + "</span>";
	}, "");
};
