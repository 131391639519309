import {JetView} from "webix-jet";
// import OBTDiagram from "views/obt-diagram";

export default class Obt extends JetView {
	config() {
		return {
			cols: [
				{
					gravity: 1,
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "OBT"},
								{},
								{view: "datepicker", format: "%Y-%m-%d", width: 120}
							]
						},
						{
							view: "datatable",
							localId: "pedidosTt",
							// autowidth: true,
							// autoheight: true,
							drag: true,
							select: "row",
							multiselect: "touch",
							columns: [
								// {id:"fecPrm", header:{text:"FEC", height: 20}, width: 35},
								{id: "emp", header: {text: "EMP", height: 20}, fillscpace: true},
								{id: "zon", header: {text: "ZON", height: 20}, fillspace: true},
								{id: "rutNor", header: {text: "RUT", height: 20}, fillspace: true},
								{
									id: "p", header: [
										{text: "PED", height: 20},
										{localId: "pedTotSel", text: "", height: 20}
									],
									fillspace: true
								},
								{
									id: "v", header: [
										{
											// text: "m<sup>3</sup>",
											text: "m3",
										},
										{localId: "volTotSel", text: "", height: 20}
									],
									format: webix.i18n.numberFormat,
									fillspace: true
								},
							],
							url: "server/programacion/repartoRutaPedidos.php",
							on: {
								onSelectChange: () => this.pedidosTtSelectChange()
							}
						},
						{
							view: "datatable",
							localId: "pedidosDt",
							select: true,
							drag: true,
							columns: [
								{id: "emp", header: "EMP", fillspace: true},
								{id: "zon", header: "ZON", fillspace: true},
								{id: "rutNor", header: "RUT", fillspace: true},
								{id: "p", header: "PED", fillspace: true},
								{id: "v", header: "m3", fillspace: true, format: webix.i18n.numberFormat}
							],
							on: {
								onAfterDrop: () => this.pedidosDtAfterDrop()
							}
						}
					]
				},
				{
					view: "resizer"
				},
				{
					gravity: 2,
					type: "wide",
					rows: [
						{
							view: "toolbar",
							elements: [
								{
									view: "button", type: "iconButton", icon: "wxi-plus", width: 35,
									click: () => {
										this.initDrawVars();
										this.canvas.add(new this.Estiba({width: 50, height: 50, text: "??"}));
									}
								}
							]
						},
						// {
						// 	view: "iframe",
						// 	src: "http://192.168.1.50/tds3_ts/draw2d/test.html"
						// }
						// OBTDiagram
						{
							view: "abslayout", localId: "targetLayout",
							on: {
								onBeforeDrop() {
									console.log("ONBEFOREDROP");
								}
							}
						}
					]
				}
			]
		};
	}

	init(view) {
		//webix.Touch.disable();
		// this.initDrawVars();
		this.pedidosTt = this.$$("pedidosTt");
		this.obtLayout = this.$$("targetLayout");

		webix.DragControl.addDrop(this.obtLayout.getNode(), {
			$drop: function (source, target, event) {
				let context = webix.DragControl.getContext();
				let s = $$(source),
					t = $$(target);
				// make sure drop source if different from the target abslayout
				if (s.config.id !== t.config.id) {
					let item = $$(source).getItem(context.start);
					const id = $$(target).addView({
						template: "<div>#title#</div>",
						data: item,
						top: event.offsetY,
						left: event.offsetX,
						width: 150,
						height: 50
					});
				}
			}
		});

		webix.DragControl.addDrag(this.obtLayout.$view, {
			$dragDestroy: function () {
				// preserve view instead of destroying DnD object
				return false;
			},
			$dragPos: (pos, ev) => {
				var context = webix.DragControl.getContext();
				// let control = webix.$$(context.source[0]);
				let control = webix.$$(context.source);

				control.config.left = pos.x + context.x_offset;
				pos.x = control.config.left - webix.DragControl.left;
				control.config.top = pos.y + context.y_offset;
				pos.y = control.config.top - webix.DragControl.top;
			},
			$dragCreate: function (source, ev) {
				const el = webix.$$(ev);
				if (el && el !== this.obtLayout) {
					var pos = webix.html.pos(ev);
					var context = webix.DragControl.getContext();

					context.source = [el.config.id];
					context.from = this.obtLayout;
					context.y_offset = el.config.top - pos.y;
					context.x_offset = el.config.left - pos.x;

					// create DnD object from a view
					return el.$view;
				} else {
					// if DnD starts on empty area, start drawing a custom rectangle in abslayout
					drawStart(this.obtLayout.$view);
					return false;
				}
			}
		});

		function drawStart(node, ev) {
			// create a selection area
			const block = document.createElement("div");
			block.className = "custom_selection";
			node.appendChild(block);

			var start = webix.html.pos(ev);
			var offset = webix.html.offset(node);

			// change sizes of area while dragging
			const ev1 = webix.event(document.body, "mousemove", (ev) => {
				renderRect(block, start, webix.html.pos(ev), offset);
			});
			// remove area on mouseup
			const ev2 = webix.event(document.body, "mouseup", () => {
				webix.html.remove(block);
				webix.eventRemove(ev1);
				webix.eventRemove(ev2);
			});
		}

		function renderRect(node, start, end, offset) {
			var x = [start.x - offset.x, end.x - offset.x].sort((a, b) => a > b ? 1 : -1);
			var y = [start.y - offset.y, end.y - offset.y].sort((a, b) => a > b ? 1 : -1);

			node.style.left = (x[0]) + "px";
			node.style.top = (y[0]) + "px";
			node.style.width = (x[1] - x[0]) + "px";
			node.style.height = (y[1] - y[0]) + "px";

			// here's can be logic for setting tracking selection
		}

		// global select event
		webix.attachEvent("onClick", function (e) {
			let view = $$(e);
			if (view && view.getParentView()) {
				if (view.getParentView().config.id === "targetLayout") {
					let c = view.config;
					let res = "<ul>";
					for (let prop in c) {
						if (typeof c[prop] != "function" && typeof c[prop] != "object")
							res += "<li>" + prop + ": " + view.config[prop] + "</li>";
					}
					res += "</ul>";
					$$("preview").setHTML(res);
				}
			}
		});
	}

	initDrawVars() {
		this.canvas = document.getElementsByTagName("iframe")[0].contentWindow.canvas;
		this.Estiba = document.getElementsByTagName("iframe")[0].contentWindow.Estiba;
	}

	pedidosTtSelectChange() {
		let pedTot = 0;
		let volTot = 0.0;

		for (const item of this.pedidosTt.getSelectedItem(true)) {
			pedTot += item.p;
			volTot += parseFloat(item.v);
		}

		this.pedidosTt.config.columns[3].header = [
			{
				text: "PED",
				height: 20
			},
			{
				text: pedTot,
				height: 20
			}];
		this.pedidosTt.config.columns[4].header = [
			{
				text: "m3",
				height: 20
			},
			{
				text: webix.i18n.numberFormat(volTot),
				height: 20
			}];
		this.pedidosTt.refreshColumns();
	}

	pedidosDtAfterDrop() {
		const pedidosDt = this.$$("pedidosDt");
		pedidosDt.hideOverlay();

		let pedTot = 0;
		let volTot = 0.0;

		pedidosDt.data.each(item => {
			pedTot += item.p;
			volTot += parseFloat(item.v);
		});

		pedidosDt.config.columns[3].header = [
			{text: "PED", height: 20},
			{text: pedTot, height: 20}
		];
		pedidosDt.config.columns[4].header = [
			{text: "m3", height: 20},
			{text: webix.i18n.numberFormat(volTot), height: 20}
		];

		pedidosDt.refreshColumns();
	}


	destroy() {
		webix.Touch.enable();
	}

}