import {JetView} from "webix-jet";

export default class ChangePasswordWindow extends JetView {
	config() {
		return {
			view: "window",
			zIndex:9999, //Para evitar que el mapa se muestre encima
			modal: true,
			//close: true,
			position: "center",
			//fullscreen: true,
			//width: 400,
			//height: "auto",
			head: {
				view: "toolbar", elements: [
					{template: "<div class='webix_header'>Nueva Contraseña</div>"},
					{
						view: "icon", localId: "closeBtn", icon: "wxi-close",
						click: () => {
							this.$$("formView").elements["pasA"].setValue("");
							this.$$("formView").elements["pasB"].setValue("");
							//this.getTopParentView().close();
							this.getRoot().hide();
						}
					}
				]
			},
			body: {
				view: "form", localId: "formView",
				elements: [
					{
						view: "text", name: "pasA", type: "password", placeholder: "Digitar contraseña",
						on: {onTimedKeyPress: () => this.validatePassword()}
					},
					{
						view: "text", name: "pasB", type: "password", placeholder: "Repetir contraseña",
						on: {onTimedKeyPress: () => this.validatePassword()}
					},
					{
						view: "template", localId: "vldTpl",
						autoheight: true
					},
					{
						view: "button", label: "Cambiar", localId: "okBtn", disabled: true,
						click: () => {
							const pss = this.$$("formView").elements["pasA"].getValue();
							webix.ajax().post("server/personal/setPassword.php", {pss})
								.then(response => {
									response = response.json();
									webix.message(response.msg);
									this.getRoot().hide();
								});
						}
					},
				],
				rules: {
					pasA: webix.rules.isNotEmpty,
					pasB: webix.rules.isNotEmpty
				}
			}
		};
	}

	showWindow(config = {}) {
		this.getRoot().$scope.$$("closeBtn").define("hidden", config.hideCloseBtn || false);
		this.getRoot().show();
	}

	validatePassword() {
		const pasA = this.$$("formView").elements["pasA"].getValue();
		const pasB = this.$$("formView").elements["pasB"].getValue();
		const vld = {
			pasCoinciden: (pasA === pasB),
			pasMinLength: pasA.length >= 8,
			pasUpperCase: /[A-Z]/.test(pasA),
			pasLowerCase: /[a-z]/.test(pasA),
			pasNumber: /[0-9]/.test(pasA),
			pasSpecialChar: /\W|_/.test(pasA),
		};

		const str = "<ul class='reglas'>" +
			(pasA.length > 0 ? this.buildLi("Contraseñas coinciden", vld.pasCoinciden) : "") +
			this.buildLi("Mínimo 8 caracteres", vld.pasMinLength) +
			this.buildLi("Incluye mayúscula(s)", vld.pasUpperCase) +
			this.buildLi("Incluye minúscula(s)", vld.pasLowerCase) +
			this.buildLi("Incluye número(s)", vld.pasNumber) +
			this.buildLi("Incluye símbolo(s)", vld.pasSpecialChar) +
			"</ul>";

		this.$$("vldTpl").setHTML(str);

		const isValidPassword = Object.values(vld).reduce((r, v) => r && v);
		if (isValidPassword) {
			this.$$("okBtn").enable();
		} else {
			this.$$("okBtn").disable();
		}
	}

	buildLi(msg, isValid) {
		return "<li><span class='webix_icon mdi mdi-check-circle " + (isValid ? "check_ok" : "check_failed") + "'></span>" + "" +
			"<span class='regla'>" + msg + "</span></li>";
	}

	destroy() {
		console.log("destroy");
		super.destroy();
	}
}
