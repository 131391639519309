import {JetView} from "webix-jet";
// import {parse} from "webix-jet/dist/helpers";

export default class Permisos extends JetView {

	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{
							view: "button",
							type: "icon",
							icon: "mdi mdi-arrow-left",
							width: 40,
							click: () => this.cancelarClick()
						},
						{
							view: "label", label: "Permisos"
						},
						{},
						{
							view: "button",
							label: "Guardar",
							type: "iconButton",
							icon: "save",
							click: () => this.guardarClick()
						}
					]
				},
				{
					view: "form", localId: "permisoFrm",
					elements: [
						{
							view: "multiselect",
							label: "Permiso",
							name: "perId",
							options: "/server/permisos/options.php"
						},
						{
							view: "combo", label: "Sucursal", name: "sucId", options: "/server/sucursales/options.php"
						},
						{
							view: "combo", label: "Empresa", name: "empId", options: "/server/empresas/options.php"
						},
						{
							cols: [
								{view: "button", label: "Permitir", click: () => this.guardarPermiso("1")},
								{view: "button", label: "Denegar", click: () => this.guardarPermiso("0")},
								{view: "button", label: "Eliminar", click: () => this.eliminarPermiso()},
							]
						}
					]
				},
				{
					view: "datatable", localId: "permisosDt", select: true,
					columns: [
						{id: "perId", header: "Permiso", fillspace: true, options: "/server/permisos/options.php"},
						{id: "sucId", header: "Sucursal", options: "/server/sucursales/options.php"},
						{id: "empId", header: "Empresa", options: "/server/empresas/options.php"},
						{id: "val", header: "Valor", options: [{id: "0", value: "Denegar"}, {id: "1", value: "Permitir"}]},
					],
					on: {
						onSelectChange: () => this.permisosDtOnSelectChange()
					}
				}
			]
		};
	}

	init(view) {

	}

	guardarPermiso(val) {
		let values = this.$$("permisoFrm").getValues();
		const perIds = values.perId.split(",");
		for (const perId of perIds) {
			console.log(perId);
			values.id = perId + "-" + values.sucId + "-" + values.empId;
			if (this.$$("permisosDt").exists(values.id)) {
				const per = this.$$("permisosDt").getItem(values.id);
				per.val = val;
				this.$$("permisosDt").updateItem(values.id, per);
			} else {
				const per = webix.copy(values);
				per.perId = perId;
				per.val = val;
				this.$$("permisosDt").add(per);
			}
		}
	}

	guardarClick() {

	}

	eliminarPermiso() {
		webix.confirm("¿Desea eliminar el permiso seleccionado?")
			.then(() => this.$$("permisosDt").remove(this.$$("permisosDt").getSelectedId()));
	}

	permisosDtOnSelectChange() {
		const id = this.$$("permisosDt").getSelectedId();
		const per = this.$$("permisosDt").getItem(id);
		this.$$("permisoFrm").setValues(per);
	}
}