export default {
	"Age":"Возраст",
	"Total money spent, $":"Всего денег вложено, $",
	"Clients":"Клиенты",
	"Income":"Доход",
	"First name":"Имя",
	"Last name":"Фамилия",
	"Position":"Должность",
	"Click to select":"Выберите",
	"Notifications":"Уведомления",
	"Yes":"Да",
	"No":"Нет",
	"City, country":"Город, страна",
	"Address":"Адрес",
	"Birthday":"День рождения",
	"Click to add tags":"Добавьте теги",
	"Notes":"Заметки",
	"Reset":"Сбросить",
	"Save":"Сохранить",
	"Top clients":"Самые активные клиенты",
	"Money spent, $":"Денег вложено, $",
	"-- Not selected --":"-- Не выбрано --",
	"Sales manager":"Менеджер по продаж.",
	"Customer service":"Работа с клиентами",
	"General manager":"Генеральный директор",
	"Awarded Badges":"Присвоенные теги",
	"New":"Новичок",
	"Customer":"Клиент",
	"Supplier":"Поставщик",
	"Discount":"Скидка",
	"Old Buddy":"Дружище",
	"Avid Supporter":"Ярый поклонник",
	"Date":"Дата",
	"Payment":"Платеж",
	"Purchase":"Покупка",
	"Sum":"Сумма",
	"Left":"Остаток",
	"Nothing on this day":"В этот день платежей не было",
	"Information":"Информация",
	"Statistics":"Статистика",
	"Transactions":"Транзакции",
	"Customers":"Клиенты",
	"Charts":"Диаграммы",
	"See all notifications":"Все уведомления",
	"Deposits News":"Новости по вкладам",
	"Dear client, we inform you about the following changes: beginning from September 24, 2018 all transactions (incoming and out...":"Уважаемый клиент, сообщаем Вам о следующих изменениях: начиная с 24 сентября 2018 года все транзакции (входящие и...",
	"Search Improved":"Улучшен поиск",
	"Dear client! Following the latest updates of the SeekMeEverywhere engines, your search has become more reliable and convenient! No...":"Уважаемый клиент! Вслед за обновлениями движков SeekMeEverywhere, поиск по приложению стал более...",
	"Transaction Reports":"Отчеты по транзакциям",
	"Dear client, we inform you about a change in the date of monthly transaction reports. Beginning from September 20, 2018 reports...":"Уважаемый клиент, сообщаем вам об изменении даты ежемесячных отчетов по транзакциям. Начиная с 20...",
	"Warning":"Внимание",
	"Dear client, we know where you store your passwords. We highly recommend you to memorize them and destroy those memos of yours.":"Уважаемый клиент, мы знаем, где вы храните свои пароли. Настоятельно рекомендуем вам запомнить их уже наконец.",
	"Editing customers data":"Редактирование данных",
	"Dear client, if you want to see the details on every customer and edit them, double-click the customer in the list on the right.":"Чтобы посмотреть и редактировать информацию о клиентах, дважды кликните на нужном клиенте в списке справа.",
	"We miss you":"Мы скучаем по вам",
	"Dear client, it has been a while since you talked to us. We are waiting for you in the same place at the same time.":"Уважаемый клиент, что-то мы давно с вами не разговаривали. Ждем вас в том же месте и в то же время.",
	"Have a nice day":"Хорошего вам дня",
	"Dear client, whenever you are reading this, we wish you a merry day. May the luck and success attend you.":"Уважаемый клиент, если вы читаете это, значит мы желаем вам веселого дня. Да пребудут с вами удача и успех.",
	"Localization":"Локализация",
	"Dear client, you can pick one of the six languages we provided for this demo app. Click the icon in the top right corner.":"Уважаемый клиент, вы можете выбрать один из 6 языков. Кликните по иконке в правом верхнем углу.",
	"We love you":"Мы любим вас",
	"Dear client, we love you very much. If our feelings are mutual, contact us and download Webix. Then we will love you eternally.":"Уважаемый клиент, мы вас очень-очень любим. Пишите нам и качайте Webix. Тогда мы будем любить вас вечно.",
	"Persons":"Клиенты",
	"Click twice to see more goodies":"Кликните дважды, чтобы открыть дополнительную информацию",
	"Saved":"Сохранено",
	"Settings":"Настройки",
	"Regional settings":"Региональные настройки",
	"Language":"Языки",
	"Date format":"Формат даты",
	"Money format":"Денежный формат",
	"Environment settings":"Настройки среды",
	"Theme":"Тема",
	"Light":"Светлая",
	"Dark":"Темная",
	"Max list length":"Максимальная длина списка",
	"Default settings":"Настройки по умолчанию",
	"Open latest notifications":"Открыть последние уведомления",
	"Go to settings":"Перейти к настройкам",
	"All":"Все",
	"Payments":"Платежи",
	"Incoming":"Входящие",
	"Email":"Email",
	"Widgets":"Виджеты",
	"Demos":"Демо",
	"Prices":"Цены",
	"Tutorials":"Уроки",
	"Tirane, Albania":"Тирана, Албания",
	"Buenos Aires, Argentina":"Буэнос Айрес, Аргентина",
	"Canberra, Australia":"Канберра, Австралия",
	"Vienna, Austria":"Венна, Австрия",
	"Minsk, Belarus":"Минск, Беларусь",
	"Brussels, Belgium":"Брюссель, Бельгия",
	"Brasilia, Brazil":"Бразилиа, Бразилия",
	"Ottawa, Canada":"Оттава, Канада",
	"Beijing, China":"Пекин, Китай",
	"Prague, Czech Republic":"Прага, Чехия",
	"Copenhagen, Denmark":"Копенгаген, Дания",
	"Cairo, Egypt":"Каир, Египет",
	"Helsinki, Finland":"Хельсинки, Финляндия",
	"Paris, France":"Париж, Франция",
	"Tbilisi, Georgia":"Тбилиси, Грузия",
	"Berlin, Germany":"Берлин, Германия",
	"New Delhi, India":"Нью Дели, Индия",
	"Dublin, Ireland":"Дублин, Ирландия",
	"Rome, Italy":"Рим, Италия",
	"Tokyo, Japan":"Токио, Япония",
	"Wellington, New Zealand":"Веллингтон, Новая Зеландия",
	"Seoul, Republic of Korea":"Сеул, Южная Корея",
	"Madrid, Spain":"Мадрид, Испания",
	"Stockholm, Sweden":"Стокгольм, Швеция",
	"Washington, United States":"Вашингтон, США",
	"Payment History":"История платежей",
	"Origin of clients":"Откуда клиенты",
	"Income from various age groups":"Доход от клиентов разного возраста",
	"Clients by occupation":"Должности клиентов",
	"No new notifications":"Нет новых уведомлений"
};