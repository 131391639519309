import ViajesListado from "views/viajes/listado";
import {JetView} from "webix-jet";
import ViajesDetalle from "views/viajes/detalle";

export default class Viajes extends JetView {
	config() {
		return {
			view: "multiview",
			fitBiggest:true,
			cells: [
				ViajesListado,
				ViajesDetalle
			]
		};
	}

	init(view) {
		// this.lc = this.ui(ViajesCarga);
		// (ViajesListado as any).load(sucId, empId);
		// console.log(this);
		// debugger;
		// this.show("./top/viajes?sucId=1&empId=1,2");
	}

}