export default {
	"Age":"Años",
	"Total money spent, $":"Total de dinero gastado, $",
	"Clients":"Clientela",
	"Income":"Ingresos",
	"First name":"Nombre de pila",
	"Last name":"Apellido",
	"Position":"El oficio",
	"Click to select":"Clic para seleccionar",
	"Notifications":"Notificaciones",
	"Yes":"Sí",
	"No":"No",
	"City, country":"Ciudad, País",
	"Address":"Dirección",
	"Birthday":"Cumpleaños",
	"Click to add tags":"Haga clic para agregar etiquetas",
	"Notes":"Notas",
	"Reset":"Reiniciar",
	"Save":"Salvar",
	"Top clients":"Principales clientes",
	"Money spent, $":"Dinero gastado, $",
	"-- Not selected --":"-- No seleccionado --",
	"Sales manager":"Gerente de ventas",
	"Customer service":"Servicio al cliente",
	"General manager":"Gerente general",
	"Awarded Badges":"Insignias otorgadas",
	"New":"Nuevo",
	"Customer":"Cliente",
	"Supplier":"Proveedor",
	"Discount":"Descuento",
	"Old Buddy":"Viejo Amigo",
	"Avid Supporter":"Ansioso Seguidor",
	"Date":"Fecha",
	"Payment":"Pago",
	"Purchase":"Compra",
	"Sum":"Suma",
	"Left":"Izquierda",
	"Nothing on this day":"Nada en este día",
	"Information":"Información",
	"Statistics":"Estadística",
	"Transactions":"Actas",
	"Customers":"Clientes",
	"Charts":"Gráficos",
	"See all notifications":"Todas las notificaciones",
	"Deposits News":"Depósitos Noticias",
	"Dear client, we inform you about the following changes: beginning from September 24, 2018 all transactions (incoming and out...":"Estimado cliente, le informamos sobre los siguientes cambios: a partir del 24 de septiembre de 2018 todas las transacciones (entrantes y salientes...",
	"Search Improved":"Búsqueda mejorada",
	"Dear client! Following the latest updates of the SeekMeEverywhere engines, your search has become more reliable and convenient! No...":"¡Estimado cliente! Después de las últimas actualizaciones de los motores SeekMeEverywhere, ¡su búsqueda se ha vuelto más confiable y conveniente! Ahora...",
	"Transaction Reports":"Informes de transacciones",
	"Dear client, we inform you about a change in the date of monthly transaction reports. Beginning from September 20, 2018 reports...":"Estimado cliente, le informamos sobre un cambio en la fecha de los informes de transacciones mensuales. A partir del 20 de septiembre de 2018 informa...",
	"Warning":"Advertencia",
	"Dear client, we know where you store your passwords. We highly recommend you to memorize them and destroy those memos of yours.":"Estimado cliente, sabemos dónde almacena sus contraseñas. Le recomendamos encarecidamente que los memorice y destruya esos memorandos suyos.",
	"Editing customers data":"Editando datos de clientes",
	"Dear client, if you want to see the details on every customer and edit them, double-click the customer in the list on the right.":"Estimado cliente, si desea ver los detalles de cada cliente y editarlos, haga doble clic en el cliente en la lista a la derecha.",
	"We miss you":"Te extrañamos",
	"Dear client, it has been a while since you talked to us. We are waiting for you in the same place at the same time.":"Estimado cliente, ha pasado un tiempo desde que habló con nosotros. Te estamos esperando en el mismo lugar al mismo tiempo.",
	"Have a nice day":"Que tengas un buen día",
	"Dear client, whenever you are reading this, we wish you a merry day. May the luck and success attend you.":"Estimado cliente, cada vez que esté leyendo esto, le deseamos un feliz día. Que la suerte y el éxito te atiendan.",
	"Localization":"Localización",
	"Dear client, you can pick one of the six languages we provided for this demo app. Click the icon in the top right corner.":"Estimado cliente, puede elegir uno de los seis idiomas que proporcionamos para esta aplicación de demostración. Haga clic en el icono en la esquina superior derecha.",
	"We love you":"Te amamos",
	"Dear client, we love you very much. If our feelings are mutual, contact us and download Webix. Then we will love you eternally.":"Estimado cliente, lo queremos mucho. Si nuestros sentimientos son mutuos, contáctenos y descargue Webix. Entonces te amaremos eternamente.",
	"Persons":"Personas",
	"Click twice to see more goodies":"Haga clic dos veces para ver más regalos",
	"Saved":"Salvado",
	"Settings":"Configuraciones",
	"Regional settings":"Ajustes regionales",
	"Language":"Idioma",
	"Date format":"Formato de fecha",
	"Money format":"Formato de dinero",
	"Environment settings":"Configuraciones del entorno",
	"Theme":"Tema",
	"Light":"Ligero",
	"Dark":"Oscuro",
	"Max list length":"Longitud máxima de la lista",
	"Default settings":"Configuración por defecto",
	"Open latest notifications":"Abrir las últimas notificaciones",
	"Go to settings":"Ir a la configuración",
	"All":"Todas",
	"Payments":"Pagos",
	"Incoming":"Entrante",
	"Email":"Email",
	"Widgets":"Widgets",
	"Demos":"Población",
	"Prices":"Precios",
	"Tutorials":"Tutoriales",
	"Tirane, Albania":"Tirana, Albania",
	"Buenos Aires, Argentina":"Buenos Aires, Argentina",
	"Canberra, Australia":"Canberra, Australia",
	"Vienna, Austria":"Viena, Austria",
	"Minsk, Belarus":"Minsk, Bielorrusia",
	"Brussels, Belgium":"Bruselas, Belgica",
	"Brasilia, Brazil":"Brasilia, Brasil",
	"Ottawa, Canada":"Ottawa, Canadá",
	"Beijing, China":"Beijing, China",
	"Prague, Czech Republic":"Praga, República Checa",
	"Copenhagen, Denmark":"Copenhague, Dinamarca",
	"Cairo, Egypt":"El Cairo, Egipto",
	"Helsinki, Finland":"Helsinki, Finlandia",
	"Paris, France":"París, Francia",
	"Tbilisi, Georgia":"Tbilisi, Georgia",
	"Berlin, Germany":"Berlín, Alemania",
	"New Delhi, India":"Nueva Delhi, India",
	"Dublin, Ireland":"Dublín, Irlanda",
	"Rome, Italy":"Roma, Italia",
	"Tokyo, Japan":"Tokio, Japón",
	"Wellington, New Zealand":"Wellington, Nueva Zelanda",
	"Seoul, Republic of Korea":"Seúl, República de Corea",
	"Madrid, Spain":"Madrid, España",
	"Stockholm, Sweden":"Estocolmo, Suiza",
	"Washington, United States":"Washington, Estados Unidos",
	"Payment History":"Historial de pagos",
	"Origin of clients":"Origen de los clientes",
	"Income from various age groups":"Ingresos de varios grupos de edad",
	"Clients by occupation":"Clientes por ocupación",
	"No new notifications":"No hay nuevas notificaciones"
};