import {JetView} from "webix-jet";
import {filtersToQueryString} from "../../libs/utils";
import {estados} from "../../models/estados";

export default class ListadosListado extends JetView {

	config() {

		let screen = this.app.config.size;

		if (screen === "small") {
			return {
				view: "datatable",
				localId: "lisLisDt",
				select: "row", header: false, css: "rows",
				columns: [
					{
						id: "inf", fillspace: true,
						template: function (row) {
							const itot = "ÍTEMS:" + row.itot;
							const iL = row.iL ? "L:" + row.iL : "";
							const iR = row.iR ? "R:" + row.iR : "";
							const iAS = row.iAS ? "AS:" + row.iAS : "";
							const iDI = row.iDI ? "DI:" + row.iDI : "";
							const iRP = row.iRP ? "RP:" + row.iRP : "";
							const iN = row.iN ? "N:" + row.iN : "";
							const iE = row.iE ? "E:" + row.iE : "";
							const iLG = row.iLG ? "LG:" + row.iLG : "";
							const iDS = row.iDS ? "DS:" + row.iDS : "";
							const iNE = row.iNE ? "NE:" + row.iNE : "";
							const iEF = row.iEF ? "EF:" + row.iEF : "";
							return `<strong>${row.id} - ${row.emp} - ${row.suc}</strong><br>${row.via} - ${row.pla}<br>${row.yyyycam} - ${row.zon}<br>${itot} ${iL} ${iR} ${iAS} ${iDI} ${iRP} ${iN} ${iE} ${iLG} ${iDS} ${iNE} ${iEF}`;
						}
					}
				],
				rowHeight: 80,
				rowLineHeight: 18,
				on: {
					onSelectChange: () => this.onSelectChangetHandler()
				},
				datafetch: 50,
				loadahead: 100,
				//url: "server/Listados/list.php"
			};
		} else if (screen === "mid") {
			return {
				view: "datatable",
				localId: "lisLisDt",
				select: "row", css: "rows", leftSplit: 2,
				columns: [
					{
						id: "", width: 180,
						template: "#id# - #emp# - #suc# - #yyyycam#<br>#via# #pla#<br>#zon#"
					},
					{id: "itot", header: "ÍTEMS", width: 60, cssFormat: () => this.setCellCss("tot")},
					{
						id: "iL",
						header: {text: "L", css: "estado_header estL_header"},
						width: 60,
						css: "estado_cell estL"
					}, // cssFormat: () => this.setCellCss("L")},
					{
						id: "iR",
						header: {text: "R", css: "estado_header estR_header"},
						width: 60,
						css: "estado_cell estR"
					}, // cssFormat: () => this.setCellCss("R")},
					{
						id: "iAS",
						header: {text: "AS", css: "estado_header estAS_header"},
						width: 60,
						css: "estado_cell estAS"
					}, // cssFormat: () => this.setCellCss("AS")},
					{
						id: "iDI",
						header: {text: "DI", css: "estado_header estDI_header"},
						width: 60,
						css: "estado_cell estDI"
					}, // cssFormat: () => this.setCellCss("DI")},
					{
						id: "iRP",
						header: {text: "RP", css: "estado_header estRP_header"},
						width: 60,
						css: "estado_cell estRP"
					}, // cssFormat: () => this.setCellCss("RP")},
					{
						id: "iN",
						header: {text: "N", css: "estado_header estN_header"},
						width: 60,
						css: "estado_cell estN"
					}, // cssFormat: () => this.setCellCss("N")},
					{
						id: "iE",
						header: {text: "E", css: "estado_header estE_header"},
						width: 60,
						css: "estado_cell estE"
					}, // cssFormat: () => this.setCellCss("E")},
					{
						id: "iLG",
						header: {text: "LG", css: "estado_header estLG_header"},
						width: 60,
						css: "estado_cell estLG"
					},
					{
						id: "iDS",
						header: {text: "DS", css: "estado_header estDS_header"},
						width: 60,
						css: "estado_cell estDS"
					}, // cssFormat: () => this.setCellCss("DS")},
					{
						id: "iDV",
						header: {text: "DV", css: "estado_header estDV_header"},
						width: 60,
						css: "estado_cell estDV"
					}, // cssFormat: () => this.setCellCss("DV"), hidden: true},
					{
						id: "iNE",
						header: {text: "NE", css: "estado_header estNE_header"},
						width: 60,
						css: "estado_cell estNE"
					}, // cssFormat: () => this.setCellCss("NE"), hidden: true},
					{
						id: "iEF",
						header: {text: "EF", css: "estado_header estEF_header"},
						width: 60,
						css: "estado_cell estEF"
					}, // cssFormat: () => this.setCellCss("NF"), hidden: true},
				],
				rowHeight: 60,
				rowLineHeight: 18,
				on: {
					onSelectChange: () => this.onSelectChangetHandler()
				},
				datafetch: 50,
				loadahead: 100,
				//url: "server/Listados/list.php"
			};
		} else {
			return {
				view: "datatable",
				localId: "lisLisDt",
				// gravity: this.config.gravity,
				select: "row", css: "rows",
				columns: [
					{id: "id", header: ["ID", {content: "serverFilter"}], width: 60, sort: "server"},
					{id: "emp", header: ["Empresa", ""], width: 90, sort: "server"},
					{id: "suc", header: ["Sucursal", ""], width: 50, sort: "server"},
					{id: "yyyycam", header: ["AñoCam.", {content: "serverFilter"}], width: 70, sort: "server"},
					{id: "via", header: ["Viaje", {content: "serverFilter"}], width: 80, sort: "server"},
					{id: "pla", header: ["Placa", {content: "serverFilter"}], width: 80, sort: "server"},
					{id: "zon", header: ["Zona", {content: "serverFilter"}], minWidth: 80, sort: "server"},
					{id: "itot", header: "ÍTEMS", width: 60, cssFormat: () => this.setCellCss("tot")},
					{
						id: "iL",
						header: {text: "L", css: "estado_header estL_header"},
						width: 60,
						css: "estado_cell estL"
					}, // cssFormat: () => this.setCellCss("L")},
					{
						id: "iR",
						header: {text: "R", css: "estado_header estR_header"},
						width: 60,
						css: "estado_cell estR"
					}, // cssFormat: () => this.setCellCss("R")},
					{
						id: "iAS",
						header: {text: "AS", css: "estado_header estAS_header"},
						width: 60,
						css: "estado_cell estAS"
					}, // cssFormat: () => this.setCellCss("AS")},
					{
						id: "iDI",
						header: {text: "DI", css: "estado_header estDI_header"},
						width: 60,
						css: "estado_cell estDI"
					}, // cssFormat: () => this.setCellCss("DI")},
					{
						id: "iRP",
						header: {text: "RP", css: "estado_header estRP_header"},
						width: 60,
						css: "estado_cell estRP"
					}, // cssFormat: () => this.setCellCss("RP")},
					{
						id: "iN",
						header: {text: "N", css: "estado_header estN_header"},
						width: 60,
						css: "estado_cell estN"
					}, // cssFormat: () => this.setCellCss("N")},
					{
						id: "iE",
						header: {text: "E", css: "estado_header estE_header"},
						width: 60,
						css: "estado_cell estE"
					}, // cssFormat: () => this.setCellCss("E")},
					{
						id: "iLG",
						header: {text: "LG", css: "estado_header estLG_header"},
						width: 60,
						css: "estado_cell estLG"
					},
					{
						id: "iDS",
						header: {text: "DS", css: "estado_header estDS_header"},
						width: 60,
						css: "estado_cell estDS"
					}, // cssFormat: () => this.setCellCss("DS")},
					{
						id: "iDV",
						header: {text: "DV", css: "estado_header estDV_header"},
						width: 60,
						css: "estado_cell estDV"
					}, // cssFormat: () => this.setCellCss("DV"), hidden: true},
					{
						id: "iNE",
						header: {text: "NE", css: "estado_header estNE_header"},
						width: 60,
						css: "estado_cell estNE"
					}, // cssFormat: () => this.setCellCss("NE"), hidden: true},
					{
						id: "iEF",
						header: {text: "EF", css: "estado_header estEF_header"},
						width: 60,
						css: "estado_cell estEF"
					}, // cssFormat: () => this.setCellCss("NF"), hidden: true},
				],
				on: {
					onSelectChange: () => this.onSelectChangetHandler()
				},
				datafetch: 50,
				loadahead: 100,
				//url: "server/Listados/list.php"
			};

		}
	}

	init() {
		/*this.on(this.app, "listados:update", filters => {
			//this.$$("lisLisDt").clearAll();
			this.$$("lisLisDt").load("server/Listados/list.php?" + filtersToQueryString(filters));
		});

		this.on(this.app, "detallesxdescripcion:close", () => {
			this.$$("lisLisDt").clearSelection();
		});

		 */
		this.sucId = this.getParam("sucId", true);
		this.$$("lisLisDt").load("server/Listados/list.php?filter[sucId]=" + this.sucId);
		this.on(this.app, "item:change", data => {
			if (this.$$("lisLisDt").exists(data.lisId))
				this.$$("lisLisDt").load("server/Listados/list.php?filter[lisId]=" + data.lisId);
		});
	}

	setMultipleSelection(value) {
		this.$$("lisLisDt").define("multiselect", value ? "touch" : false);
	}

	updateCache(){
		this.$$("lisLisDt").load("server/Listados/updateCache.php?lisId=" + this.$$("lisLisDt").getSelectedId());
	}

	setCellCss(estCod) {
		if (estCod === "tot") return {"background-color": "#fafafa", "font-weight": "bold", "text-align": "right"};
		const color = "#" + estados.getItem(estCod).col;
		// return {"color": color, "text-align": "right", "font-weight": "bold"};
		//return {"background-color": "#FF0000", "color": color, "font-weight": "bold", "text-align": "right"};
		//return {"background-color": "#DAECFF", "color": "#0080FF", "font-weight": "bold", "text-align": "right"};
		return "est" + estCod;
	}

	onSelectChangetHandler() {
		this.getParentView().setParam("lisId",this.$$("lisLisDt").getSelectedId(true).join(), true);
		console.log("selectChange", this.$$("lisLisDt").getSelectedId(true).join());
		//this.show("common.info?lisId=" + this.$$("lisLisDt").getSelectedId(true).join());
		this.show("./common.info");
	}

	urlChange() {
		const sucId = this.getParam("sucId", true);
		if (sucId !== this.sucId) {
			this.sucId = sucId;
			this.$$("lisLisDt").clearAll();
			this.$$("lisLisDt").load("server/Listados/list.php?filter[sucId]=" + this.sucId);
		}

		this.lisId = this.getParam("lisId", true);
		console.log("lisId", this.lisId);
		//this.$$("lisLisDt").select(this.lisId, true);

		// const empId = this.getParam("empId", true);
		//
		// if (this.sucId === undefined
		// 	|| this.empId === undefined
		// 	|| sucId !== this.sucId
		// 	|| empId !== this.empId) {
		// 	this.sucId = sucId;
		// 	this.empId = empId;
		// 	this.$$("lisLisDt").clearAll();
		// 	this.$$("lisLisDt").load("server/Listados/list.php?filter[sucId]=" + this.sucId);
		// 	console.log("LISTADO SUCID", this.sucId);
		// }
	}
}