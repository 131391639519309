import Box from "jet-views/obt/Box";

export default class Estiba extends Box {

	constructor(params = {}) {
		super(params);
		this._params.fill = params.fill || "#ddc7a3";
		this._params.stroke = params.fill || "#ccad8e";
	}

	build(){
		super.build();
		let label = document.createElementNS(this._ns, "text");
		label.setAttributeNS(null,"x", this._params.width / 2);
		label.setAttributeNS(null,"y", this._params.height / 2);
		label.setAttributeNS(null,"text-anchor","middle");
		label.setAttributeNS(null,"fill","#7c6956");
		let textNode = document.createTextNode("A-B-C");
		label.appendChild(textNode);
		this._svg.appendChild(label);
		return this._svg;
	}
}