import {JetView} from "webix-jet";

export default class Form extends JetView {
	config() {

		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{
							view: "icon", icon: "mdi mdi-arrow-left",
							click: () => {
								this.show("distribucion.list");
							}
						},
						{view: "label", label: "Reparto"},
						{
							view: "icon",
							localId: "rprFormSaveBtn",
							icon: "mdi mdi-content-save",
							click: () => this.saveForm()
						}
					]
				},
				{
					view: "form",
					localId: "rprForm",
					elementsConfig: {
						labelPosition: "top"
					},
					scroll: "auto",
					elements: [
						{
							view: "combo",
							localId: "conIdCmb",
							name: "con_id",
							label: "Conductor",
							// options:["One", "Two", "Three"]
							options: "server/recepcion/per.php"
						},
						{
							view: "combo",
							localId: "vehIdCmb",
							name: "veh_id",
							label: "Vehículo",
							// options:["One", "Two", "Three"]
							options: {
								body:{
									template: "#pla#",
									url: "server/vehiculos/list.php"
								}
							}
						},
						{
							view: "combo",
							localId: "disIdCmb",
							name: "dis_id",
							label: "Dispositivo",
							// options: "server/dispositivos/list.php"
							options: {
								body:{
									template: "#rid# - #num# - #dis#",
									url: "server/dispositivos/list.php"
								}
							}
						},
						{
							view: "segmented",
							name: "mue_id",
							label: "Muelle",
							value: 1,
							options: [
								{"id": "1", "value": "1.1"},
								{"id": "2", "value": "1.2"},
								{"id": "3", "value": "2.1"},
								{"id": "4", "value": "2.2"},
								{"id": "5", "value": "3"}
							]
						},
						// {
						// 	view: "multicombo", localId: "auxCmb", name: "aux", label: "Auxiliares",
						// 	options: "server/recepcion/per.php"
						// },
						// {
						// 	view: "toolbar",
						// 	elements: [
						// 		{
						// 			view: "label",
						// 			label: "Auxiliares",
						// 		},
						// 		{
						// 			view: "icon",
						// 			icon: "wxi-plus",
						// 			click: () => this.auxiliarAdd()
						// 		}
						// 	]
						// },
						// {template: "Auxiliares", type: "section"},
						{
							view: "datatable",
							localId: "auxDt",
							editable: true,
							columns: [
								{
									id: "per_id",
									header: "Auxiliar",
									editor: "combo",
									options: "server/recepcion/per.php",
									minWidth: 120,
									fillspace: true
								},
								{
									id: "t_ini",
									header: "Inicio",
									editor: "date",
									timepicker: true,
									width: 55,
									format: webix.Date.dateToStr("%H:%i")
								},
								{
									id: "t_fin",
									header: "Fin",
									editor: "date",
									width: 55,
									format: webix.Date.dateToStr("%H:%i")
								},
								{
									id: "btn",
									header: "<span class='webix_icon wxi-plus-circle'></span>",
									width: 40,
									template: "<span class='webix_icon wxi-minus-circle'></span>"
								}
							],
							onClick: {
								"wxi-minus-circle": (event, id, node) => this.auxiliarDel(event, id, node)
							},
							on: {
								onHeaderClick: (event, id, node) => this.auxiliarAdd(event, id, node)
							}
						},
						{ template:"TDS", type:"section"},
						{
							cols: [
								{
									view: "richselect",
									localId: "traIdCmb",
									name: "tra_id_d",
									label: "Transportador",
									options: "server/Personal/getTransportadoresTDS.php",
								},
								{
									view: "counter",
									localId: "crg_d",
									name: "crg_d",
									width: 110,
									label: "Carga"
								}
							]
						},
						{}
					]
				}
			]
		};
	}

	init(view) {
		this.rprForm = this.$$("rprForm");
		this.rprForm.setValues({"id": 0});
	}

	urlChange(view){
		var id = this.getParam("rprId", true);
		if(id === undefined) return;
		console.log("repId="+id);
		this.rprForm.load("server/repartos/read.php?id=" + id);
	}

	ready(view) {
		// this.$$("conIdCmb").setValue(this.app.getService("user").getUser().id);
	}

	saveForm() {
		let values = this.$$("rprForm").getValues();
		values.aux = this.$$("auxDt").data.pull;
		//this.app.callEvent("descargue:save", [values, this]);
		webix.ajax().post("server/repartos/save.php?XDEBUG_SESSION_START=12345", values,
			response => {
				const r = JSON.parse(response);
				if(r.status === "success"){
					this.show("distribucion.escaneo?rprId=" + r.id);
				}
				else if (r.status === "error") {
					webix.alert({type: "alert-error", title: "Error al guardar", text: r.msg});
				}
				// console.log(response, this.getSubView("descargueForm").setId(r.data.id));
			}
		);
	}

	setId(id) {
		this.rprForm.setValues({"id": id});
		console.log("ID:", id);
	}

	auxiliarAdd() {
		console.log("AuxiliaresAdd");
		this.$$("auxDt").editStop();
		const id = this.$$("auxDt").add({}, 0);
		this.$$("auxDt").editRow(id);

	}

	auxiliarDel(event, id, node) {
		webix.confirm({
			type: "confirm-warning",
			title: "Eliminar Auxiliar",
			text: "¿Desea eliminar el Auxiliar de este Reparto?",
			cancel: "No",
			ok: "Sí"
		}).then(
			btn => {
				if (btn) {
					this.$$("auxDt").remove(id);
					webix.message("Delete row" + id);
				}
			}
		);
		return false;
	}

}