export function getTags(){
	return tags;
}

const tags = [
	{ id:"1", value:"New", color:"#8664C6" },
	{ id:"2", value:"Customer", color:"#BCAAE0" },
	{ id:"3", value:"Supplier", color:"#1CA1C1" },
	{ id:"4", value:"Discount", color:"#5ABBD2" },
	{ id:"5", value:"Old Buddy", color:"#FDBF4C" },
	{ id:"6", value:"Avid Supporter", color:"#F8643F" }
];
