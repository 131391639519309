import {JetView, plugins} from "webix-jet";

export default class MenuView extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const theme = this.app.config.theme;

		return {
			view: "sidebar",
			id: "sidebar",
			css: theme,
			collapsed: (screen !== "wide"),
			// collapsedWidth: 1,
			template: "{common.coloredIcon()} <span>#value#</span>",
			item: {
				coloredIcon: (obj, common) => {
					return "<span style='color: #" + obj.color + "' class='webix_icon webix_sidebar_icon " + obj.icon + "'></span>";
				}
			},
			scroll: true,
			width: 200,
			tooltip: true
		};
	}

	init(view) {
		const sidebar = this.$$("sidebar");
		this.use(plugins.Menu, {
			id: sidebar,
			urls: {
				"customers": "customers?user=1/information"
			}
		});

		const user = this.app.getService("user").getUser();
		console.log(user.men);
		sidebar.define("data", user.men);

		// this.on(this.app, "menu:toggle",
		// 		// 	() => {
		// 		// 		sidebar.toggle();
		// 		// 		view.define("width", sidebar.$width);
		// 		// 		view.resize();
		// 		// 	});
		// 		// sidebar.getPopup().attachEvent("onBeforeShow", () => false);

		this.on(this.app, "menu:toggle",
			() => {
				$$("app-logo").define("height", sidebar.$width === 44 ? 80 : 50);
				sidebar.toggle();
			}
		);
		// this.on(this.app, "menu:toggle", () => sidebar.toggle());
		sidebar.getPopup().attachEvent("onBeforeShow", () => false);

		// console.log("ParentView", this.getParentView());

	}

	urlChange(view, url) {
		console.log("MENU urlChange", view, url);
		// if (!ui.find(opts => url[1].page === opts.id).length)
		// 	ui.unselect();
	}
}
