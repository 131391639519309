import {JetView} from "webix-jet";
import infoGeneral from "views/escaneo/infogeneral";
import infoPedido from "views/escaneo/infopedido";
import Detalles from "views/detalles";
import ItemsList from "views/common/items";


export default class escaneo extends JetView {
	config() {

		const toolbar = {
			view: "toolbar", elements: [
				{
					view: "icon", icon: "mdi mdi-arrow-left",
					click: () => {
						this.show("entregado.list");
					}
				},
				{view: "label", label: "Escaneo"},
				{
					view: "label", label: "00:00 +00:00",
					localId: "countdown",
					// css: "tiempoEscaneo"
					css: {"color": "red"}
				},
				{
					view: "icon", icon: "mdi mdi-stop", tooltip: "Finalizar Entregado"
				}
			]
		};

		const form = {
			view: "form",
			elementsConfig: {
				labelPosition: "top"
			},
			elements: [
				{
					cols: [
						{
							view: "text",
							localId: "codTxt",
							label: "Código de Barras",
							placeholder: "Código de Boleta",
							labelWidth: 50,
							gravity: 2,
							// labelPosition: "top",
							// labelAlign: "center",
							on: {
								onKeyPress: code => this.codTxtKeyPress(code)
							}
						}
					]
				}
			]
		};

		const tab_s = {
			view: "tabview",
			fitBiggest: true,
			cells: [
				{header: "General", body: [{$subview: infoGeneral}]},
				{
					header: "Detalles",
					rows: [new Detalles(this.app, "infoDetalleView", {toolbarVisible: false, estado: "E"})]
				},
				{header: "Pedido", rows: [{$subview: infoPedido}]},
				{header: "Items", rows: [{$subview: ItemsList}]},
			],
			tabbar: {
				popupWidth: 50,
				tabMinWidth: 60
			}
		};

		const tab_m = {
			view: "tabview",
			fitBiggest: true,
			cells: [
				{header: "General", rows: [{$subview: infoGeneral}]},
				{
					header: "Detalle",
					rows: [new Detalles(this.app, "infoDetalleView", {toolbarVisible: false, estado: "E"})]
				},
				{header: "Pedido", rows: [{$subview: infoPedido}]},
				{header: "Items", rows: [{$subview: ItemsList}]},
			]
		};

		const tab_w = {
			view: "tabview",
			fitBiggest: true,
			cells: [
				{header: "General", rows: [{$subview: infoGeneral}]},
				{
					header: "Detalle",
					rows: [new Detalles(this.app, "infoDetalleView", {toolbarVisible: false, estado: "E"})]
				},
				{header: "Pedido", rows: [{$subview: infoPedido}]},
			]
		};

		const layout_w = {
			cols: [
				{rows: [toolbar, form, tab_w], gravity: 2},
				{rows: [ItemsList], gravity: 1}
			]
		};

		const screen = this.app.config.size;
		switch (screen) {
			case "small":
				return {rows: [toolbar, form, tab_s]};
			case "mid":
				return {rows: [toolbar, form, tab_m]};
			case "wide":
				return layout_w;
		}
	}

	init() {
		this.codTxt = this.$$("codTxt");

		this.rprId = this.getParam("rprId", true);
		this.filters = {
			rprId: this.rprId
		};

	}

	codTxtKeyPress(code) {
		if (code !== 13) {
			return;
		}

		this.codTxt.config.value = this.codTxt.getInputNode().nodeValue;
		const cod = this.codTxt.getValue().trim();
		// Ignorar caracteres especiales en ciertas configuraciones de terminaciones en el scanner
		if (cod.length === 0) {
			return;
		}
		this.codTxt.setValue("");

		$$("itemsLst").select(cod);

		//Ignorar escaneo repetido
		//TODO: Comprobar en caso de registro de Descentralizados (Código UPC)
		if (this.codAnt === cod) {
			return;
		} else {
			this.codAnt = cod;
		}

		if (cod.substr(0, 1) === ":") {
			// const pal = cod.substr(cod.lastIndexOf("Ñ")+1);
			const pal = cod.substr(5);
			this.palTxt.setValue(pal);
			return;
		}

		//Detectar si código existe antes de continuar

		/*if(this.itemsLst.getIndexById(cod) === -1){
			webix.alert("Código No Registrado en Listado. Por favor escanearlo en TDS.");
			return;
		}*/


		const data = {
			cod: cod,
			estCod: "E",
			t: Date.now(),
			rprId: this.rprId
		};

		this.app.callEvent("escaneo:codigo", [data]);

		//registrarEscaneoItem(this.app, data);

		// this.itemsLst.updateItem(cod, data);
		//
		// this.actualizarDetalleCantidades(cod);
		//
		// this.itemsLst.filter(function (obj) {
		// 	return obj.rut_esc && obj.rut_esc !== obj.rut_nor;
		// }, "");

		// Reproducir sonido en error
		// if (this.itemsLst.getItem(cod).rut_nor != this.rutTxt.getValue()) {
		// 	this.beep_error.play();
		// }

		// TODO: Ejecutar solo cuando la pestaña Pedido esté activa
		// this.infoPedido(cod);

	}

	urlChange(view, url) {
		console.log("ENTREGADO/ESCANEO/URLCHANGE");
		const rprId = this.getParam("rprId");
		//this.getSubView("itemsView").setData(getItems({dscId}));
		this.app.callEvent("items:load", [{rprId}]);
	}

}