import {JetView} from "webix-jet";

export default class form extends JetView {
	config() {

		webix.editors.$popup = {
			date: {
				view: "popup",
				// body:{ view:"calendar", timepicker:true, icons:true}
				body: {view: "calendar", type: "time", icons: true}
			}
		};

		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{
							view: "icon", icon: "mdi mdi-arrow-left",
							click: () => {
								this.show("recepcion.list");
							}
						},
						{view: "label", label: "Descargue"},
						{
							view: "icon",
							localId: "desFormSaveBtn",
							icon: "mdi mdi-content-save",
							click: () => this.saveForm()
						}
					]
				},
				{
					view: "form",
					localId: "desForm",
					elementsConfig: {
						labelPosition: "top"
					},
					elements: [
						{
							view: "richselect",
							localId: "lisCmb",
							name: "lis_id",
							label: "Listado",
							options: {
								view: "suggest",
								//template: "#emp# - #value# - #pla#",
								body: {
									view: "list",
									//url: "server/recepcion/listadosDescargue.php?sucId=1",
									template: "<div style='font-size: large'><span class='mdi mdi-truck'></span> #emp# - #value# - #pla#</div>Pedidos:#p# Cajas:#c#",
									item: {height: 50}
								}
							},
							// on: {
							// 	onChange: id => this.app.callEvent("viaje:change", [id])
							// }
						},
						{
							view: "segmented",
							name: "mue_id",
							label: "Muelle",
							value: 1,
							options: [
								{"id": "1", "value": "1.1"},
								{"id": "2", "value": "1.2"},
								{"id": "3", "value": "2.1"},
								{"id": "4", "value": "2.2"},
								{"id": "5", "value": "3"}
							]
						},
						{
							view: "combo",
							localId: "perIdCmb",
							name: "per_id",
							label: "Responsable",
							// options:["One", "Two", "Three"]
							options: "server/recepcion/per.php"
						},
						{
							view: "datatable",
							localId: "auxDt",
							editable: true,
							columns: [
								{
									id: "per_id",
									header: "Auxiliar",
									editor: "combo",
									options: "server/recepcion/per.php",
									minWidth: 120,
									fillspace: true
								},
								{
									id: "t_ini",
									header: "Inicio",
									editor: "date",
									timepicker: true,
									width: 55,
									format: webix.Date.dateToStr("%H:%i")
								},
								{
									id: "t_fin",
									header: "Fin",
									editor: "date",
									width: 55,
									format: webix.Date.dateToStr("%H:%i")
								},
								{
									id: "btn",
									header: "<span class='webix_icon wxi-plus-circle'></span>",
									width: 40,
									template: "<span class='webix_icon wxi-minus-circle'></span>"
								}
							],
							onClick: {
								"wxi-minus-circle": (event, id, node) => this.auxuliarDel(event, id, node)
							},
							on: {
								onHeaderClick: (event, id, node) => this.auxiliarAdd(event, id, node)
							}
						},
						{}
					]
				}
			]
		};
	}

	init(_$view) {
		this.desForm = this.$$("desForm");
		this.lisCmb = this.$$("lisCmb");
	}

	ready(view) {
		this.$$("perIdCmb").setValue(this.app.getService("user").getUser().id);
	}

	urlChange(view) {
		const sucId = this.getParam("sucId", true);
		this.$$("lisCmb").getList().clearAll();
		this.$$("lisCmb").getList().load("server/recepcion/listadosDescargue.php?sucId=" + sucId );
		const id = this.getParam("dscId", true);
		console.log("dscId desde recepcion/form=" + id);
		this.desForm.setValues({id});
		if (id === undefined || id == 0) return;
		this.desForm.load("server/descargues/read.php?id=" + id).then(
			data => this.$$("auxDt").define("data", data.json().data.aux)
		);
	}

	saveForm() {
		let values = this.$$("desForm").getValues();
		values.aux = [];
		this.$$("auxDt").data.each(data => values.aux.push(data));
		//this.app.callEvent("descargue:save", [values, this]);
		webix.ajax().post("server/descargues/save.php", values,
			response => {
				const r = JSON.parse(response);
				if (r.status === "success") {
					this.setParam("dscId", r.id, true);
					this.getParentView().show("recepcion.escaneo?dscId=" + r.id);
					// this.getParentView().show("recepcion.escaneo",{dscId: r.id});
				} else if (r.status === "error") {
					webix.alert({type: "alert-error", title: "Error al guardar", text: r.msg});
				}
				// console.log(response, this.getSubView("descargueForm").setId(r.data.id));
			}
		);
	}

	setId(id) {
		this.desForm.setValues({"id": id});
		console.log("ID:", id);
	}

	auxiliarAdd() {
		console.log("AuxiliaresAdd");
		this.$$("auxDt").editStop();
		const id = this.$$("auxDt").add({}, 0);
		this.$$("auxDt").editRow(id);

	}

	auxuliarDel(event, id, node) {
		webix.confirm({
			type: "confirm-warning",
			title: "Eliminar Auxiliar",
			text: "¿Desea eliminar el Auxiliar de este Viaje?",
			cancel: "No",
			ok: "Sí"
		}).then(
			btn => {
				if (btn) {
					this.$$("auxDt").remove(id);
					webix.message("Delete row" + id);
				}
			}
		);
		return false;
	}
}