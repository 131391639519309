import {JetView} from "webix-jet";

export default class list extends JetView {
	config() {
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Descargues"},
						{},
						{
							view: "icon", icon: "mdi mdi-plus",
							click: () => {
								this.getParentView().show("recepcion.form?dscId=0");
							}
						}
					]
				},
				{
					view: "dataview",
					id: "descarguesLis",
					select:true,
					template: "<div class='placa'>#pla#<br><span class='placa_emp'>#emp#</span></div>" +
						"<div><strong>#via#</strong> Muelle: #mue#" +
						"<br>#t_ini# - #t_fin#" +
						"<br>Responsable: #res#</div>",
					type: {
						width: "auto",
						height:"auto",
						type: "tiles",
					},
					on: {
						onItemClick: id => {
							//this.getParentView().setParam("dscId", id, true);
							//this.setParam("dscId", id, true);
							this.show("recepcion.form?dscId=" + id);
						},
						onBeforeLoad: function () {
							this.showOverlay("<img src='codebase/style/tdsx_logo_anim.svg' width='48' />");
						},
						onAfterLoad: function () {
							this.hideOverlay();
						},
					}
				}
			]
		};
	}

	init(view) {
		this.descarguesLis = this.$$("descarguesLis");
		webix.extend(this.descarguesLis, webix.OverlayBox);
	}

	urlChange(view) {
		const sucId = this.getParam("sucId", true);
		this.descarguesLis.clearAll();
		console.log("sucId desde recepcion/list=" + sucId);
		if (sucId === undefined || sucId === 0) return;
		this.descarguesLis.load("server/recepcion/descargues.php?sucId=" + sucId);
	}
}