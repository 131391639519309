import {JetView} from "webix-jet";
// import imageConversion from "libs/conversion";

export default class Legalizacion extends JetView {

	config() {
		return {
			cols: [
				{
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Legalización"},
								{
									view: "icon", icon: "mdi mdi-content-save", label: "Enviar",
									on: {
										onItemClick: () => this.$$("boletasUpl").send()
									}
								}
							]
						},
						{
							view: "uploader", localId: "boletasUpl", value: "Seleccionar Boletas", name: "files",
							// directory: true,
							autosend: false,
							link: "boletasLst", upload: "server/legalizacion/upload.php",
							on: {
								onBeforeFileAdd: (item) => this.beforeFileAddHandler(item)
							}
						},
						{
							view: "list",
							id: "boletasLst",
							localId: "boletasLst",
							type: "uploader",
							//autoheight:true,
							borderless: true,
							select: true,
							template: "#name# #size# #cliId# <strong>#cod#</strong>",
							on: {
								onSelectChange: (id) => this.boletasLstChange(id)
							}
						},
						{
							view: "label", text: "Barra de Estado"
						}
					]
				},
				{
					rows: [
						{
							view: "toolbar",
							elements: [
								{view: "label", label: "Boleta"},
								{},
								{view: "icon", tooltip: "Correcto",  icon: "mdi mdi-check-outline", width:50},
								{view: "icon", tooltip:"Incorrecto", icon: "mdi mdi-close-outline", width: 50},
							]
						},
						{
							view: "form",
							elements: [
								{
									cols: [
										{view: "text", label: "Código", localId: "codResultTxt"}
									]
								}
							]
						},
						{
							view: "scrollview",
							scroll: "xy",
							body: {
								// width: 1280,
								// height: 2000,
								template: "<canvas style='transform-origin: top left; transform: scale(0.5, 0.5)' id='boletaCnv' width='1280' height='2000'>"
							}
						}
					]
				}
			]
		};

	}

	beforeFileAddHandler(item) {
		// //item.file = await imageConversion.compress(item.file, '0.5');
		// const config = {size: 200, accuracy: 0.95, scale: 0.5};
		// const imgFile = null; //await imageConversion.compressAccurately(item.file, config);
		// console.log(item.file.size, imgFile.size);
		// item.file = imgFile;


		// let config = this.getQuaggaConfig();
		// config.src = URL.createObjectURL(item.file);
		// let codResult = "";
		// Quagga.decodeSingle(config, (result) => {
		// 	if (result.codeResult && result.codeResult.code) {
		// 		codResult = result.codeResult.code;
		// 	}
		// });

		const reader = new FileReader();
		reader.readAsDataURL(item.file);
		reader.onload = (evt) => {
			const img = new Image();
			img.src = evt.target.result;
			img.onload = () => {
				const cw = 1000;
				const ch = cw / img.width * img.height;
				console.log(cw, ch);
				let can = document.createElement("canvas");
				can.width = cw;
				can.height = ch;
				const ctx = can.getContext("2d");
				ctx.drawImage(img, 0, 0, cw, ch);

				const threshold = 120;
				const d = ctx.getImageData(0, 0, cw, ch);  // Get image Data from Canvas context

				//Threshold Filter
				//Otro método interesante con webworkers y peso para cada canal:
				//https://codepen.io/bwilln/pen/RMKwMX?editors=1111
				for (let i = 0; i < d.data.length; i += 4) { // 4 is for RGBA channels
					// R=G=B=R>T?255:0
					d.data[i] = d.data[i + 1] = d.data[i + 2] = d.data[i + 1] > threshold ? 255 : 0;
				}
				ctx.putImageData(d, 0, 0);

				can.toBlob(blob => {
					console.log("CANVAS TO BLOB");
					item.file = blob;
				}, "image/gif", 0.8);
			};
		};

		item.formData = {
			cliId: 2,
			// sizeA: item.file.size,
			// sizeB: imgFile.size
		};

	}

	boletasLstChange(id) {
		const item = this.$$("boletasLst").getSelectedItem();
		console.log(item);

		const reader = new FileReader();
		reader.readAsDataURL(item.file);
		reader.onload = (evt) => {
			const img = new Image();
			img.src = evt.target.result;
			img.onload = () => {
				var cw = 1000;
				var ch = cw / img.width * img.height;
				let can = document.getElementById("boletaCnv");
				can.width = cw;
				can.height = ch;
				const ctx = can.getContext("2d");
				ctx.drawImage(img, 0, 0, cw, ch);
				let config = this.getQuaggaConfig();
				config.src = URL.createObjectURL(item.file);
				Quagga.decodeSingle(config, (result) => this.decodeResult(result));
			};
		};
	}

	decodeResult(result) {
		console.log(result);
		this.$$("codResultTxt").setValue("");
		const box = result.box;
		const can = document.getElementById("boletaCnv");
		const ctx = can.getContext("2d");

		if (result.box) {
			Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, ctx, {color: "green", lineWidth: 2});
		}

		if (result.codeResult && result.codeResult.code) {
			this.$$("codResultTxt").setValue(result.codeResult.code);
			Quagga.ImageDebug.drawPath(result.line, {x: "x", y: "y"}, ctx, {color: "red", lineWidth: 3});
		}
	}

	drawPath(path, def, ctx, style) {
		ctx.strokeStyle = style.color;
		ctx.fillStyle = style.color;
		ctx.lineWidth = style.lineWidth;
		ctx.beginPath();
		ctx.moveTo(path[0][def.x], path[0][def.y]);
		for (var j = 1; j < path.length; j++) {
			ctx.lineTo(path[j][def.x], path[j][def.y]);
		}
		ctx.closePath();
		ctx.stroke();
	}

	getQuaggaConfig() {
		return {
			inputStream: {
				size: 1280,
				singleChannel: false
			},
			locator: {
				patchSize: "small",
				halfSample: false
			},
			decoder: {
				readers: [
					{
						format: "code_128_reader",
						config: {}
					},
					{
						format: "code_39_reader",
						config: {}
					},
				]
			},
			locate: true,
			src: null
		};
	}
}