import {JetView} from "webix-jet";
import Box from "views/obt/Box";
import Estiba from "jet-views/obt/Estiba";
import AreaEstiba from "jet-views/obt/AreaEstiba";

export default class obt extends JetView {
	config() {
		return {
			rows: [
				{
					css: "webix_dark",
					view: "toolbar", height: 50,
					cols: [
						{view: "label", label: "OBT"},
						{label: "Fecha", value: new Date(), view: "datepicker", width: 250}
					]
				},
				{
					cols: [
						{
							rows: [
								{
									// css: "webix_dark",
									view: "toolbar",
									cols: [
										{view: "label", label: "Pedidos"},
										{view: "button", label: "Click me", autowidth: true}
									]
								},
								{
									view: "datatable", select: true, drag: true,
									columns: [
										{id: "zon", header: "Zona", fillspace: true, sort: "string"},
										{id: "rut", header: "Ruta", width: 40, sort: "string"},
										{id: "ped", header: "Pedidos", width: 50, sort: "number"},
										{id: "caj", header: "Cajas", width: 50, sort: "number"},
										{id: "m3", header: "m3", width: 50, sort: "number"}
									],
									// "url": "demo->5db986c06390e7001964a03d",
									data: [
										{zon:"1000", rut:"A", ped:10, caj:16, m3:10},
										{zon:"2000", rut:"B", ped:20, caj:26, m3:20},
										{zon:"3000", rut:"C", ped:30, caj:36, m3:30},
										{zon:"4000", rut:"D", ped:40, caj:46, m3:40}
									]
								},
								{
									// css: "webix_dark",
									view: "toolbar",
									cols: [
										{view: "label", label: "Estiba"},
										{view: "button", label: "Click me", autowidth: true}
									]
								},
								{
									rows: [
										{label: "Ubicación", options: [], view: "select", height: 38},
										{
											view: "tabbar", multiview: true,
											options: [
												{value: "Pedidos", id: "pedidosTab"},
												{value: "Propiedades", id: "propiedadesTab"}
											]
										},
										{
											fitBiggest: true,
											cells: [
												{
													id: "pedidosTab",
													view: "datatable", drag: true,
													columns: [
														{id: "zon", header: "Zona", fillspace: true, sort: "string"},
														{id: "rut", header: "Ruta", width: 40, sort: "string"},
														{id: "ped", header: "Pedidos", width: 50, sort: "number"},
														{id: "caj", header: "Cajas", width: 50, sort: "number"},
														{id: "m3", header: "m3", width: 50, sort: "number"}
													]
												},
												{
													id: "propiedadesTab",
													view: "form",
													elementsConfig: {
														labelWidth: 120
													},
													rows: [
														{view: "counter", label: "Capacidad (m3)", name: "capm3"},
														{view: "counter", label: "Capacidad (cajas)", name: "capcaj"},
														{view: "button", css: "webix_primary", label: "Save"}
													]
												}
											]

										}
									]
								}
							]
						},
						{view: "resizer"},
						{
							gravity: 2,
							rows: [
								{
									// css: "webix_dark",
									view: "toolbar",
									cols: [
										{view: "button", label: "+ Área Estiba", autowidth: true, click: () => this.addAreaEstiba()}
									]
								},
								{template: "<div id='obtContainer' style='width: 100%; height: 100%' ></div>"}
							]
						}
					]
				}
			]

		};
	}

	ready() {
		let ns = "http://www.w3.org/2000/svg";
		this.svg = document.createElementNS(ns, "svg");
		// this.svg.addEventListener("click", evt => this.svgClick(evt));
		const obtContainer = document.getElementById("obtContainer");
		// obtContainer.addEventListener("mousemove", evt => console.log(evt));
		let vbp = {x: 0, y: 0, width: 0, height: 0};
		vbp.width = obtContainer.clientWidth;
		vbp.height = obtContainer.clientHeight;
		this.svg.setAttribute("viewBox", Object.values(vbp).join(" "));
		this.templateLayer = document.createElementNS(ns, "g");
		this.templateLayer.setAttributeNS(null,"id", "templateLayer");
		this.estibasLayer = document.createElementNS(ns, "g");
		this.estibasLayer.setAttributeNS(null, "id", "estibasLayer");
		this.svg.appendChild(this.templateLayer);
		this.svg.appendChild(this.estibasLayer);
		obtContainer.appendChild(this.svg);

		for (let i = 0; i < 5; i++) {
			let estiba = new Estiba({id: "estiba-" + webix.uid(), x: 60 * i}).build();
			estiba.classList.add("draggable");
			estiba.addEventListener("click", evt => this.svgClick(evt));
			this.estibasLayer.appendChild(estiba);
		}

		var dre = document.querySelectorAll(".draggable");
		for (var i = 0; i < dre.length; i++) {
			var o = new this.Draggable(dre[i], this.handler);
			// dre[i].addEventListener("click", evt => this.svgClick(evt));
		}

		let p; //punto inicial
		let d; // distancia inicial entre los dos puntos
		let viewBoxParams;
		let viewBoxParams2;

		this.svg.addEventListener("touchstart", evt => {
			console.log("SVG raíz", evt.type);
			evt.preventDefault();
			evt.stopPropagation();
			if (evt.touches.length === 2) {
				let p1 = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
				let p2 = {x: evt.touches[1].clientX, y: evt.touches[1].clientY};
				d = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
			} else {
				p = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
				viewBoxParams = this.svg.getAttribute("viewBox").split(" ");
				viewBoxParams2 = viewBoxParams.slice();
			}
		});

		//TODO: mover viewBox con mouse
		//TODO: Zoom viewBox con rueda del mouse

		this.svg.addEventListener("touchmove", evt => {
			// TODO: verificar si el target tiene la clase .draggable
			// Para poder mover el viewBox aunque se esté sobre un elemento no movible
			console.log(evt.target.classList);
			if (evt.target !== this.svg) return;
			// console.log("SVG raíz", evt.type, evt);
			evt.preventDefault();
			evt.stopPropagation();
			if (evt.touches.length === 2) {
				let p1 = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
				let p2 = {x: evt.touches[1].clientX, y: evt.touches[1].clientY};
				let d2 = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
				viewBoxParams2[2] = parseInt(viewBoxParams[2]) - d2 + d;
				viewBoxParams2[3] = parseInt(viewBoxParams[3]) - d2 + d;
			} else {
				let p2 = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
				viewBoxParams2[0] = parseInt(viewBoxParams[0]) + p.x - p2.x;
				viewBoxParams2[1] = parseInt(viewBoxParams[1]) + p.y - p2.y;
			}
			this.svg.setAttribute("viewBox", viewBoxParams2.join(" "));
		});
	}

	handler(evt) {
		if (evt.type === "mousedown" || evt.type === "touchstart") {
			let values = {};
			const target = evt.target;
			values.width = target.getAttribute("width");
			values.height = target.getAttribute("height");
			// target.classList.add("obt_selected");
			console.log(target.parentNode.dataset);
		}
	}

	Draggable(elem, handler) {
		this.target = elem;
		this.clickPoint = this.target.ownerSVGElement.createSVGPoint();
		this.lastMove = this.target.ownerSVGElement.createSVGPoint();
		this.currentMove = this.target.ownerSVGElement.createSVGPoint();
		this.target.addEventListener("mousedown", this);
		this.target.addEventListener("touchstart", this);
		this.target.addEventListener("click", this);
		this.handleEvent = function (evt) {
			handler(evt);
			console.log("SVG child", evt.type);
			// evt.stopPropagation();
			evt.preventDefault();
			evt = evt.type === "touchstart" ? evt.touches[0] : evt;
			this.clickPoint = globalToLocalCoords(evt.clientX, evt.clientY);
			this.target.classList.add("dragged");
			this.target.setAttribute("pointer-events", "none");
			this.target.ownerSVGElement.addEventListener("click", this.clicked);
			this.target.ownerSVGElement.addEventListener("mousemove", this.move);
			this.target.ownerSVGElement.addEventListener("touchmove", this.move);
			this.target.ownerSVGElement.addEventListener("mouseup", this.endMove);
			this.target.ownerSVGElement.addEventListener("touchend", this.endMove);
		};

		this.clicked = function (evt) {
			console.log("click inside Draggable", evt);
		};

		this.move = function (evt) {
			// console.log("SVG child", evt.type, evt);
			// evt.stopPropagation();
			// console.log(evt); //mousemove touchmove
			evt = evt.type === "touchmove" ? evt.touches[0] : evt;
			var p = globalToLocalCoords(evt.clientX, evt.clientY);
			// console.log(p);
			this.currentMove.x = this.lastMove.x + (p.x - this.clickPoint.x);
			this.currentMove.y = this.lastMove.y + (p.y - this.clickPoint.y);
			//Snap
			const step = 12;
			this.currentMove.x = Math.trunc(this.currentMove.x / step) * step;
			this.currentMove.y = Math.trunc(this.currentMove.y / step) * step;
			this.target.setAttribute("transform", "translate(" + this.currentMove.x + "," + this.currentMove.y + ")");
		}.bind(this);

		this.endMove = function (evt) {
			this.lastMove.x = this.currentMove.x;
			this.lastMove.y = this.currentMove.y;
			this.target.classList.remove("dragged");
			this.target.setAttribute("pointer-events", "all");
			this.target.ownerSVGElement.removeEventListener("click", this.clicked);
			this.target.ownerSVGElement.removeEventListener("mousemove", this.move);
			this.target.ownerSVGElement.removeEventListener("touchmove", this.move);
			this.target.ownerSVGElement.removeEventListener("mouseup", this.endMove);
			this.target.ownerSVGElement.removeEventListener("touchend", this.endMove);
		}.bind(this);

		function globalToLocalCoords(x, y) {
			var p = elem.ownerSVGElement.createSVGPoint();
			var m = elem.parentNode.getScreenCTM();
			p.x = x;
			p.y = y;
			return p.matrixTransform(m.inverse());
		}
	}

	svgClick(evt) {
		console.log(evt.target, evt.currentTarget);
	}

	addAreaEstiba() {
		const id = document.getElementById("templateLayer").childElementCount + 1;
		const ae = new AreaEstiba({id: id, x:10, y: 10, text: id}).build();
		this.templateLayer.appendChild(ae);
		new this.Draggable(ae, this.handler);
	}

	fillUbicaciones(){

	}
}