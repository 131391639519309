import {JetView} from "webix-jet";

export default class form extends JetView {
	config() {

		webix.editors.$popup = {
			date: {
				view: "popup",
				// body:{ view:"calendar", timepicker:true, icons:true}
				body: {view: "calendar", type: "time", icons: true}
			}
		};

		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{
							view: "icon", icon: "mdi mdi-arrow-left",
							click: () => {
								this.show("entregado.list");
							}
						},
						{view: "label", label: "Reparto"},
						{
							view: "icon",
							localId: "desFormSaveBtn",
							icon: "mdi mdi-content-save",
							click: () => this.saveForm()
						}
					]
				},
				{
					view: "form",
					localId: "desForm",
					elementsConfig: {
						labelPosition: "top"
					},
					elements: [
						{
							view: "richselect",
							localId: "lisCmb",
							name: "lis_id",
							label: "Reparto",
							options: {
								view: "suggest",
								//template: "#emp# - #value# - #pla#",
								body: {
									view: "list",
									//url: "server/recepcion/listadosDescargue.php?sucId=1",
									template: "<div style='font-size: large'><span class='mdi mdi-truck'></span> #emp# - #value# - #pla#</div>Pedidos:#p# Cajas:#c#",
									item: {height: 50}
								}
							},
							// on: {
							// 	onChange: id => this.app.callEvent("viaje:change", [id])
							// }
						},
						{}
					]
				}
			]
		};
	}

	init(_$view) {
		this.desForm = this.$$("desForm");
		this.lisCmb = this.$$("lisCmb");
	}

	ready(view) {
		this.$$("perIdCmb").setValue(this.app.getService("user").getUser().id);
	}

	urlChange(view) {
		const sucId = this.getParam("sucId", true);
		this.$$("lisCmb").getList().clearAll();
		this.$$("lisCmb").getList().load("server/repartos/list.php?sucId=" + sucId );
	}

	saveForm() {
		let values = this.$$("desForm").getValues();
		values.aux = [];
		this.$$("auxDt").data.each(data => values.aux.push(data));
		//this.app.callEvent("descargue:save", [values, this]);
		webix.ajax().post("server/entregado/save.php", values,
			response => {
				const r = JSON.parse(response);
				if (r.status === "success") {
					this.setParam("rprId", r.id, true);
					this.getParentView().show("entregado.escaneo?rprId=" + r.id);
					// this.getParentView().show("recepcion.escaneo",{dscId: r.id});
				} else if (r.status === "error") {
					webix.alert({type: "alert-error", title: "Error al guardar", text: r.msg});
				}
				// console.log(response, this.getSubView("descargueForm").setId(r.data.id));
			}
		);
	}

	setId(id) {
		this.desForm.setValues({"id": id});
		console.log("ID:", id);
	}
}