import {JetView} from "webix-jet";

export default class Sucursales extends JetView{
	config(){
		return {
			rows: [
				{
					view: "toolbar",
					elements: [
						{view: "label", label: "Sucursales"},
						{},
						{
							view: "button", type: "icon", icon: "wxi-plus", width: 37, css: "app_button",
							click() {
								const dt = this.$scope.$$("sucursales_dt");
								dt.editRow(dt.add({suc: "Sucursal", cod:"---"}));
							}
						}
					]
				},
				{
					view:"datatable",
					id:"sucursales_dt",
					columns: [
						{id:"id", header:"ID", width:50},
						{id:"suc", header:"Nombre", editor:"text", fillspace:true},
						{id:"cod", header:"Código", editor:"text"}
					],
					url: "server/sucursales/list.php",
					save: "server/sucursales/save.php",
					editable: true,
					updateFromResponse: true
				}
			]
		};
	}
}